import { sqcConfig } from "../ApiConfig"

export async function getAllJobs(state) {
    //state {("": allJobs), "?state=active", ?state=rework, ?state=complete}
    return fetch(sqcConfig.url + '/api/jobs' + state, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        }
    }).then(res => res.json())
        .then(data => {
            return data.result
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function getJobById(id) {
    return fetch(sqcConfig.url + '/api/jobs/' + id, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        }
    }).then(res => res.json())
        .then(data => {
            return data.result
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function getJobUnitsById(id) {
    return fetch(sqcConfig.url + '/api/jobs/' + id + '/units', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        }
    }).then(res => res.json())
        .then(data => {
            return data.result
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function addJob(jobProps) {
    return fetch(sqcConfig.url + '/api/jobs', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: JSON.stringify(jobProps)
    }).then(res => res.json())
        .then(data => {
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}


export async function updateJob(jobId, jobProps) {
    return fetch(sqcConfig.url + '/api/jobs/' + jobId, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: JSON.stringify(jobProps)
    }).then(res => res.json())
        .then(data => {
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}


export async function deleteJob(jobId) {
    return fetch(sqcConfig.url + '/api/jobs/' + jobId, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
    }).then(res => res.json())
        .then(data => {
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function sendMail(jobId, email, subject, body) {
    const mailBody = {
        "recipient": email,
        subject,
        body
    }
    return fetch(sqcConfig.url + '/api/jobs/' + jobId + "/sendMail", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: JSON.stringify(mailBody)

    }).then(res => res.json())
        .then(data => {
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}


export async function addressIssue(selectedJobId, unitId, issueId, issueProps) {
    return fetch(sqcConfig.url + '/api/jobs/' + selectedJobId + "/units/" + unitId + "/" + issueId + "/progress", {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: JSON.stringify(issueProps)
    }).then(res => res.json())
        .then(data => {
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function finishRework(selectedJobId) {
    return fetch(sqcConfig.url + '/api/jobs/' + selectedJobId + "/finishRework", {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
    }).then(res => res.json())
        .then(data => {
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}


export async function getJobMetaData() {
    return fetch(sqcConfig.url + '/api/jobs/meta', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        }
    }).then(res => res.json())
        .then(data => {
            return data.result
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function getJobCounts() {
    return fetch(sqcConfig.url + '/api/jobs/count', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        }
    }).then(res => res.json())
        .then(data => {
            return data.result
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function updateJobMetaData(metaData) {
    return fetch(sqcConfig.url + '/api/jobs/meta', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: JSON.stringify(metaData)
    }).then(res => res.json())
        .then(data => {
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function uploadJobSpecSheet(jobId,specsheet) {
    return fetch(sqcConfig.url + '/api/jobs/' + jobId + "/specSheet/upload", {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: specsheet
    }).then(res => res.json())
        .then(data => {
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}


