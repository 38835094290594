import { sqcConfig } from "../ApiConfig"

export async function getAllResources() {
    return fetch(sqcConfig.url + '/api/resources', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        }
    }).then(res => res.json())
        .then(data => {
            return data.result
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function getResourceById(resId) {
    return fetch(sqcConfig.url + '/api/resources/' + resId, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        }
    }).then(res => res.json())
        .then(data => {
            return data.result
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function addResource(resProps) {
    return fetch(sqcConfig.url + '/api/resources', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: JSON.stringify(resProps)
    }).then(res => res.json())
        .then(data => {
            // if (data.success) {
            //     openAlertModal(data.message, 2)
            // }
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function updateResource(resId, resProps) {
    return fetch(sqcConfig.url + '/api/resources/' + resId, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: JSON.stringify(resProps)
    }).then(res => res.json())
        .then(data => {
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function deleteResource(resId) {
    return fetch(sqcConfig.url + '/api/resources/' + resId, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
    }).then(res => res.json())
        .then(data => {
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}