import { sqcConfig } from "../ApiConfig"

export async function getAllCustomers() {
    return fetch(sqcConfig.url + '/api/customers', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        }
    }).then(res => res.json())
        .then(data => {
            return data.result
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function getCustomerById(customerId) {
    return fetch(sqcConfig.url + '/api/customers/' + customerId, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        }
    }).then(res => res.json())
        .then(data => {
            return data.result
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function addCustomer(customerProps) {
    return fetch(sqcConfig.url + '/api/customers', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: JSON.stringify(customerProps)
    }).then(res => res.json())
        .then(data => {
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}


export async function updateCustomer(customerId, customerProps) {
    return fetch(sqcConfig.url + '/api/customers/' + customerId, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: JSON.stringify(customerProps)
    }).then(res => res.json())
        .then(data => {
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function deleteCustomer(customerId) {
    return fetch(sqcConfig.url + '/api/customers/' + customerId, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
    }).then(res => res.json())
        .then(data => {
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}