import React, { useEffect, useState } from 'react'
import './styling/CustomAlert.css'

import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

export default function CustomAlert({ show, props, toggle }) {

    const [alertID, setalertID] = useState("") //animation purposes

    useEffect(() => {
        //start timer for close
        if (!show) return
        setalertID("alert-in")
        setTimeout(() => {
            setalertID("alert-out")
            toggle(false)
        }, 2000);
    }, [show])

    function getAlertType(field) {
        const alertColors = ['#cf2b44', '#003ea1', '#008000'] //[red,blue,green] dceefa
        const alertIcons = [<ReportProblemOutlinedIcon />, <InfoOutlinedIcon />, <CheckOutlinedIcon />]
        switch (field) {
            case 'color':
                return alertColors[props.alertType]
                break;
            case 'icon':
                return alertIcons[props.alertType]
                break;
            default:
                break;
        }

    }

    return (
        <div className='alert-modal-container' id={alertID} >
            <div className='alert-modal-body' style={{ backgroundColor: getAlertType('color') }}>
                {getAlertType('icon')}
                <h5 className='alert-message-text'>{props.message}</h5>
            </div>
        </div>
    )
}

