import React from 'react'
import './styling/GridListContainer.css'
import GridBox from './GridBox'
import GridBoxWithHeader from './GridBoxWithHeader'
import { ThreeDots } from 'react-loader-spinner'

function GridListContainer({ activeListType, pageDataLoading, setSelectListItem, listData, searchTerm, currentMenuOption, toggleModal, selectedFilterButton, editButtonClicked }) {
    return (
        <div className='grid-scroll-body'>
            {pageDataLoading ?
                <div className='data-loader-container'>
                    <ThreeDots
                        visible={true}
                        height="80"
                        width="80"
                        color="#284160"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
                :
                <>
                    {listData.filter((box) => { //filter for searchbar input
                        if ((searchTerm === "")
                            || (box.name.toLowerCase().includes(searchTerm.toLowerCase()))) {

                            if (currentMenuOption === 4) {
                                if (selectedFilterButton === -1) {
                                    return box
                                } else if (selectedFilterButton === box.type) {
                                    return box
                                } else {
                                    return null
                                }
                            } else {
                                return box;
                            }

                        } else {
                            return null;
                        }

                    }).map((listElement, index) => {
                        return (
                            (activeListType.includes("header")) ? //Check Sheets || Customers
                                <GridBoxWithHeader
                                    key={index}
                                    setSelectListItem={setSelectListItem}
                                    indexID={index}
                                    data={listElement}
                                    toggleModal={toggleModal}
                                    editButtonClicked={editButtonClicked} /> :
                                <GridBox
                                    key={index}
                                    setSelectListItem={setSelectListItem}
                                    indexID={index}
                                    data={listElement}
                                    currentMenuOption={currentMenuOption}
                                    toggleModal={toggleModal}
                                    editButtonClicked={editButtonClicked} />
                        )
                    })}</>}
        </div>
    )
}

export default GridListContainer