import { useState, React } from 'react'
import './styling/DevNotesModal.css'


const DevNotesModal = ({ setShowDevNotes }) => {
    const [title, setTitle] = useState('')
    const [subject, setSubject] = useState('')
    const [notes, setNotes] = useState('')

    const saveNotes = () => {
        const newNote = {
            title: title,
            subject: subject,
            notes: notes
        }
        console.log(newNote)
    }
    return (
        <div className='dev-notes-backdrop'>
            <div className="modal">
                <div className="content">
                    <div className="header">
                        <h2>Developer Notes</h2>
                    </div>
                    <div className="body">
                        <input type="text" placeholder="Title" onChange={(e) => setTitle(e.target.value)} />
                        <input type="text" placeholder="Subject" onChange={(e) => setSubject(e.target.value)} />
                        <textarea placeholder="Notes" onChange={(e) => setNotes(e.target.value)}></textarea>
                    </div>
                    <div className="footer">
                        <button onClick={() => setShowDevNotes(false)}>Close</button>
                        <button onClick={() => { saveNotes() }}>Save</button>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default DevNotesModal