import { fqcConfig } from "../ApiConfig"

export async function getAllChecksheets() {
    return fetch(fqcConfig.url + '/checksheets', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        }
    }).then(res => res.json())
        .then(data => {
            // console.log("Data: " + JSON.stringify(data))
            return data.data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function getChecksheetsById(id) {
    return fetch(fqcConfig.url + '/checksheets/' + id, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        }
    }).then(res => res.json())
        .then(data => {
            // console.log("Data: " + JSON.stringify(data))
            return data.data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function getChecksheetMetaDataById(id) {
    return fetch(fqcConfig.url + '/checksheets/' + id+'/metadata', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        }
    }).then(res => res.json())
        .then(data => {
            // console.log("Data: " + JSON.stringify(data))
            return data.data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function addChecksheet(checksheetProps) {
    return fetch(fqcConfig.url + '/checksheets', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: JSON.stringify(checksheetProps)
    }).then(res => res.json())
        .then(data => {
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}


export async function updateChecksheet(checksheetId, checksheetProps) {
    return fetch(fqcConfig.url + '/checksheets/' + checksheetId, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: JSON.stringify(checksheetProps)
    }).then(res => res.json())
        .then(data => {
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function deleteChecksheet(checksheetId) {
    return fetch(fqcConfig.url + '/checksheets/' + checksheetId, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
    }).then(res => res.json())
        .then(data => {
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}