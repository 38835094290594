import { sqcConfig } from "../ApiConfig"


export async function downloadFileFrom(url) {
    return fetch(sqcConfig.url + url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        }
    })
        .then((response) => response.json())
        .then((data) => {
            // console.log(JSON.stringify(data))
            window.open(data.url, '_self')
            return data
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
        });
}

export async function uploadCSVFile(selectedFile) {
    const formData = new FormData();
    formData.append('csv', selectedFile);
    return fetch(sqcConfig.url + '/api/checksheets/upload', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: formData,
    })
        .then((response) => response.json())
        .then((data) => {
            return data
        })
        .catch((error) => console.error(error));
}

export async function getIssueMedia(selectedJobId, unitId, issueId) {
    return fetch(sqcConfig.url + '/api/jobs/' + selectedJobId + "/units/" + unitId + "/" + issueId + "/image", {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        }
    }) .then((response) => response.json())
    .then((data) => {
        return data
    }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}