import React from 'react'
import { Search } from '@mui/icons-material';

export default function SearchBar({ searchTerm, setsearchTerm }) {
    return (
        <div className="searchbar-container">
            <input className="searchbar" type="text" placeholder={"Search..."}
                onChange={e => setsearchTerm(e.target.value)} value={searchTerm} />
            {/* <div className='search-button'  >
                <Search />
            </div> */}
        </div>)
}
