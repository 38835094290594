import React, { useState, useEffect } from 'react'
import './styling/modals.css'
import CloseIcon from '@mui/icons-material/Close';
import { getJobUnitsById } from '../api/sqc/JobsAPI';
import { Oval } from 'react-loader-spinner';


function JobAddEditSubModal({ show, showParent, modalInEditMode, toggleModal, modalOnSubmit, checksheets, selectedListItemData, toggleAlert, checksheetsLoading }) {

    const [checkSheetArray, setcheckSheetArray] = useState([{ serialNumber: '', checksheetTemplate: '' }])
    const [checkSheetOptions, setcheckSheetOptions] = useState([])
    const [unitsLoading, setunitsLoading] = useState(false);

    useEffect(() => {
        if (!show) return
        setcheckSheetOptions(checksheets)
        if (modalInEditMode) {
            populateInputFields()
        }
    }, [show])

    useEffect(() => {
        if (!showParent) { //clear data when parent is closed=(process as a whole cancelled or submitted)
            clearInputFields()
        }
    }, [showParent])

    async function populateInputFields() { //on edit
        setunitsLoading(true)
        const unitsData = await getJobUnitsById(selectedListItemData._id)
        setcheckSheetArray(unitsData)
        setunitsLoading(false)
    }

    function clearInputFields() {
        setcheckSheetArray([{ serialNumber: '', checksheetTemplate: '' }])
    }

    function handleClose() {
        //close method
        toggleModal(false, 1)
    }

    function onChange(event, index) {
        const copyChecksArray = JSON.parse(JSON.stringify(checkSheetArray))
        switch (event.target.name) {
            case "serialNumber":
                copyChecksArray[index].serialNumber = event.target.value
                break;
            case "checksheetTemplate":
                copyChecksArray[index].checksheetTemplate = event.target.value
                break;
            default:
                break;
        }
        setcheckSheetArray(copyChecksArray)
    }
    function addCheckSheet() {
        setcheckSheetArray(arr => [...arr, { serialNumber: '', checksheetTemplate: '' }])
    }
    function removeCheckSheet(checkIndex) {
        setcheckSheetArray(arr => arr.filter((check, index) => index !== checkIndex))
    }

    async function onSubmit() {
        if (!validateInput()) return //if input not valid, return
        const localData = {
            units: checkSheetArray
        }
        const submissionResponse = await modalOnSubmit(localData, true, 2) //true to execute api call
        if (submissionResponse?.success !== false) {
            clearInputFields() //clear on submit, keep on back press (sub-modal)
            handleClose()
            toggleModal(false, 0)//close parent modal
        }
    }

    function validateInput() {
        if (checkSheetArray.length < 1) {
            toggleAlert(true, 1, "Job requires at least one Unit")
            return false
        } else {
            for (let index = 0; index < checkSheetArray.length; index++) {
                const checkSheet = checkSheetArray[index];
                if ((checkSheet.serialNumber === "")) {
                    toggleAlert(true, 1, "Please provide a Serial Number for each Unit")
                    return false
                }
                if ((checkSheet.checksheetTemplate === "")) {
                    toggleAlert(true, 1, "Please assign a Checksheet to each Unit")
                    return false
                }
            }
        }
        return true
    }



    return (
        <>
            {show ? (
                <div className='backdrop' onClick={handleClose}>
                    <div className='modal-body ' style={{ height: '384px' }} onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header" >
                            <h3 className='modal-title' >{(modalInEditMode ? "Edit" : "Add") + " Checksheets"}</h3>
                        </div>
                        {/* body container */}
                        <div className='for-aligning-buttons-at-bottom' style={{ flex: 1 }}>
                            {(unitsLoading || checksheetsLoading) ?
                                <div className='data-loader-container'>
                                    <Oval
                                        visible={true}
                                        height="30"
                                        width="30"
                                        color="var(--primary-color)"
                                        secondaryColor="var(--secondary-color)"
                                        ariaLabel="oval-loading"
                                        wrapperStyle={{ marginTop: '3%' }}
                                        wrapperClass=""
                                    />
                                </div> :
                                <>
                                    <form className='input-form'   >
                                        <div className='checks-header'>
                                            <h4 style={{ flex: 3, borderRight: '1px solid black', paddingRight: '9px', marginBottom: '1px' }}>Serial Number</h4>
                                            <h4 style={{ flex: 5 }}>Check Sheet</h4>
                                        </div>
                                        <div className='checks-scroll-container' style={{ maxHeight: '200px' }}>
                                            {checkSheetArray.map((checkSheet, index) => {
                                                return (
                                                    <div className='check-item-sub-modal' key={index}>
                                                        <input autoComplete="off" value={checkSheet.serialNumber} style={{ flex: 3 }} name='serialNumber' onChange={(e) => onChange(e, index)} />
                                                        <div className="form-group-vertical" style={{ flex: 5, margin: '1px' }}>
                                                            <select value={checkSheet.checksheetTemplate} className="modal-input" name="checksheetTemplate" onChange={(e) => onChange(e, index)} >
                                                                <option value={""} disabled={true}></option>
                                                                {checkSheetOptions.map((option) => {
                                                                    return (<option key={option._id} value={option._id}>{option.name}</option>)
                                                                })}
                                                            </select>
                                                        </div>
                                                        <div className='remove-check-button' onClick={() => removeCheckSheet(index)}>
                                                            <CloseIcon sx={{ color: '#009c9e', fontSize: '18px' }} />
                                                        </div>
                                                    </div>)
                                            })}
                                        </div>
                                    </form>
                                    <button className='add-button' onClick={() => addCheckSheet()}>ADD SHEET</button>
                                </>
                            }
                        </div>
                        <div className='submit-container'>
                            <button className='submit-btn back-btn' onClick={() => { handleClose() }}>{"Back"}</button>
                            <button className='submit-btn' onClick={() => onSubmit()} >{"Submit"}</button>
                        </div>
                    </div>
                </div >)
                : <></>}
        </>)
}

export default JobAddEditSubModal