import React, { useState, useEffect } from 'react'
import { sendMail } from '../api/sqc/JobsAPI'

export default function SendEmailModal({ show, toggleModal, rowData, toggleAlert, settopSpinnerIsLoading }) {

    const [email, setemail] = useState("")
    const [subject, setsubject] = useState("")
    const [body, setbody] = useState("")

    function onChange(event) {
        switch (event.target.name) {
            case "subject":
                setsubject(event.target.value)
                break;
            case "email":
                setemail(event.target.value)
                break;
            case "body":
                setbody(event.target.value)
                break;
            default:
                break;
        }
    }

    async function onSubmit() {
        if ((email === "") || (!validateEmail())) {
            toggleAlert(true, 1, "Please provide a valid email address")
            return false
        }
        settopSpinnerIsLoading(true)
        const apiData = await sendMail(rowData._id, email, subject, body)
        if (apiData) {
            toggleAlert(true, 2, "Email sent")
        }else{
            toggleAlert(true, 0, "An error occurred")

        }
        settopSpinnerIsLoading(false)
        handleClose()
    }


    function validateEmail() {
        var reg = /^\S+@\S+\.\S+$/;
        return reg.test(email);
    }

    function clearInputFields() {
        setsubject("")
        setemail("")
        setbody("")
    }

    function handleClose() {
        //clear local data
        clearInputFields()
        //close method
        toggleModal(false, 8)
    }

    return (
        <>
            {show ? (
                <div className='backdrop' onClick={handleClose}>
                    <div className='modal-body ' style={{ width: '510px' }} onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header" >
                            <h3 className='modal-title' >{"Send Email"}</h3>
                        </div>

                        {/* body container */}
                        <form className='input-form'   >
                            <div className="form-group-vertical">
                                <label htmlFor="email" className="col-form-label">Recipient Email</label>
                                <input value={email} type="text" autoComplete='off' placeholder='' className="modal-input" name="email" onChange={onChange} ></input>
                            </div>
                            <div className="form-group-vertical">
                                <label htmlFor="subject" className="col-form-label">Subject</label>
                                <input value={subject} type="text" autoComplete='off' placeholder='' className="modal-input" name="subject" onChange={onChange} ></input>
                            </div>
                            <div className="form-group-vertical">
                                <label htmlFor="body" className="col-form-label">Body</label>
                                {/* <input value={body} type="text" autoComplete='off' placeholder='' className="modal-input" name="body" onChange={onChange} ></input> */}

                                <textarea value={body} name='body' style={{ resize: 'none', backgroundColor: 'white', border: '1px solid black', height: '100px' }} onChange={onChange} />


                            </div>
                        </form>

                        <div className='submit-container'>
                            <button className='submit-btn back-btn' onClick={() => handleClose()}   >{"Cancel"}</button>
                            <button className='submit-btn' onClick={() => onSubmit()} >{"Submit"}</button>
                        </div>
                    </div>

                </div >)
                : <></>}
        </>)
}
