import React from 'react'
import './styling/Unauthorized.css'

export default function Unauthorized({logout}) {
    return (
        <div className='unauth-body'>
            <h1>Unauthorized Access</h1>
            <p>Sorry, you are not authorized to access this website.</p>

            <button className='unauth-button' onClick={()=>logout()} >BACK TO LOGIN</button>

        </div>)
}
