import React, { useState, useEffect } from 'react'
import './styling/modals.css'

export default function ResourceAddEditModal({ forFQC, show, modalInEditMode, toggleModal, modalOnSubmit, selectedListItemData, toggleAlert }) {

    const [name, setname] = useState("")
    const [contactNumber, setcontactNumber] = useState("")
    const [email, setemail] = useState("")
    const [type, settype] = useState("")
    const [permissions, setpermissions] = useState({ supervisor: false, qc: false, rework: false });

    useEffect(() => {
        if (!show) return
        if (forFQC) {
            settype(1)
        }
        if (modalInEditMode) {
            populateInputFields()
        }
    }, [show])

    function populateInputFields() { //on edit
        selectedListItemData?.name && setname(selectedListItemData.name)
        selectedListItemData?.contactNumber && setcontactNumber(selectedListItemData.contactNumber)
        selectedListItemData?.email && setemail(selectedListItemData.email)
        selectedListItemData?.type && settype(selectedListItemData.type)
        selectedListItemData?.permissions && setpermissions({ ...selectedListItemData.permissions })
    }

    function clearInputFields() {
        setname("")
        setcontactNumber("")
        setemail("")
        settype("")
        setpermissions({ supervisor: false, qc: false, rework: false })
    }

    function handleClose() {
        //clear local data
        clearInputFields()
        //close method
        toggleModal(false, 4)
    }

    function onChange(event) {
        switch (event.target.name) {
            case "name":
                setname(event.target.value)
                break;
            case "contactNumber":
                setcontactNumber(event.target.value)
                break;
            case "email":
                setemail(event.target.value)
                break;
            case "type":
                settype(event.target.value)
                break;
            case "supervisor":
                setpermissions({ ...permissions, supervisor: event.target.checked })
                break;
            case "qc":
                setpermissions({ ...permissions, qc: event.target.checked })
                break;
            case "rework":
                setpermissions({ ...permissions, rework: event.target.checked })
                break;
            default:
                break;
        }
    }

    function onSubmit() {
        if (!validateInput()) return //if input not valid, return
        const localData = {
            name,
            contactNumber,
            email,
            type,
            permissions
        }
        modalOnSubmit(localData, true) //true to execute api call
        handleClose()
    }

    function validateInput() {
        if ((name === "") || (email === "") || (type === "")) {
            toggleAlert(true, 1, "Please fill in all required fields")
            return false
        }
        if ((email !== "") && (!validateEmail())) {
            toggleAlert(true, 1, "Please provide a valid email address")
            return false
        }
        return true
    }

    function validateEmail() {
        var reg = /^\S+@\S+\.\S+$/;
        return reg.test(email);
    }

    const typeOptions = [{ id: 0, title: 'Sales Rep' }, { id: 1, title: 'Technician' }]

    return (
        <>
            {show ? (
                <div className='backdrop' onClick={handleClose}>
                    <div className='modal-body ' onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header" >
                            <h3 className='modal-title' >{(modalInEditMode ? "Edit" : "Add") + " Resource"}</h3>
                        </div>
                        {/* body container */}
                        <form className='input-form'  >
                            <div className="form-group-vertical">
                                <label htmlFor="name" className="col-form-label">Resource Name</label>
                                <input value={name} type="text" autoComplete='off' id='name' placeholder='' className="modal-input" name="name" onChange={onChange} ></input>
                            </div>
                            <div className="form-group-vertical">
                                <label htmlFor="email" className="col-form-label">Email Address</label>
                                <input value={email} type="text" autoComplete='off' id='email' placeholder='' className="modal-input" name="email" onChange={onChange} disabled={modalInEditMode} ></input>
                            </div>
                            <div className="form-group-vertical">
                                <label htmlFor="contactNumber" className="col-form-label">Contact Number<span className='optional-label'>(optional)</span></label>
                                <input value={contactNumber} type="text" autoComplete='off' id='contactNumber' placeholder='' className="modal-input" name="contactNumber" onChange={onChange} ></input>
                            </div>
                            {forFQC ?
                                <>
                                    <label htmlFor="email" className="col-form-label" style={{ fontSize: '12px' }}>Permissions</label>
                                    <div className="form-group-horizontal">
                                        <div className='permission-check-container' >
                                            <label>Supervisor</label>
                                            <input checked={permissions.supervisor} type="checkbox" name='supervisor' onChange={onChange} />
                                        </div>
                                        <div className='permission-check-container' >
                                            <label>QC</label>
                                            <input checked={permissions.qc} type="checkbox" name='qc' onChange={onChange} />
                                        </div>
                                        <div className='permission-check-container' >
                                            <label>Rework</label>
                                            <input checked={permissions.rework} type="checkbox" name='rework' onChange={onChange} />
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    {!modalInEditMode &&
                                        <div className="form-group-vertical">
                                            <label htmlFor="type" className="col-form-label">Type</label>
                                            <select value={type} className="modal-input" name="type" id='type' onChange={onChange} style={{ height: '26px' }} >
                                                <option value="" disabled={true}></option>
                                                {typeOptions.map((option) => {
                                                    return (
                                                        <option key={option.id} value={option.id}>{option.title}</option>
                                                    )
                                                })}</select>
                                        </div>
                                    }
                                </>}
                        </form>
                        <div className='submit-container'>
                            <button className='submit-btn back-btn' onClick={() => { handleClose() }}   >{"Cancel"}</button>
                            <button className='submit-btn' onClick={() => { onSubmit() }} >{"Submit"}</button>
                        </div>
                    </div>

                </div >)
                : <></>}
        </>)
}
