import React, { useState, useEffect } from 'react'
import '../styling/CheckSheetBuilderModal.css'
import '../styling/modals.css'
import CheckSheetBuilderModal1 from './CheckSheetBuilderModal1';
import CheckSheetBuilderModal2 from './CheckSheetBuilderModal2';
import CheckSheetBuilderModal3 from './CheckSheetBuilderModal3';
import EastIcon from '@mui/icons-material/East';
import CloseIcon from '@mui/icons-material/Close';
import { getChecksheetsById, getChecksheetMetaDataById } from '../../api/fqc/ChecksheetAPI';
import { Oval } from 'react-loader-spinner';

export default function ChecksheetBuilderMain({ show, modalInEditMode, modalOnSubmit, selectedListItemData, toggleModal, toggleAlert }) {

    const [step, setstep] = useState(0);
    const stepHeaders = ["Checks", "Kitting List", "Dependencies"]
    const [nextColorIndex, setnextColorIndex] = useState(0);

    const [name, setname] = useState("")
    const [sections, setsections] = useState([]);
    const [kittinglist, setkittinglist] = useState([]);

    const [checkElements, setcheckElements] = useState([]);//dependencies
    const [elementConnectors, setelementConnectors] = useState([]);//dependencies
    const [checksheetMetaData, setchecksheetMetaData] = useState({});

    const [dataLoading, setdataLoading] = useState(false);

    const [signedOff, setSignedOff] = useState(false);

    useEffect(() => {
        if (!show) return
        if (modalInEditMode) {
            setname(selectedListItemData.name)
            populateBuilder()
        }
    }, [show])

    async function populateBuilder() {
        setdataLoading(true)
        const checkSheetData = await getChecksheetsById(selectedListItemData._id)
        const metaData = await getChecksheetMetaDataById(selectedListItemData._id)
        setchecksheetMetaData(metaData)
        const finalSignOff = checkSheetData?.finalSignOff
        compileDependenciesIntoConnectors(checkSheetData.sections, finalSignOff)
        setkittinglist(checkSheetData.kittinglist)
        setdataLoading(false)
    }

    function handleClose() {
        //close method
        clearData()
        toggleModal(false, 3)
    }

    function clearData() {
        setstep(0)
        setdataLoading(false)
        setname("")
        setsections([])
        setkittinglist([])
        setcheckElements([])
        setelementConnectors([])
        setchecksheetMetaData({})
    }

    function onSubmit() {
        const { compiledSections, finalSignOff } = compileCanvasDependencyData()
        const metadata = compileCanvasMetaData()
        const localData = {
            name,
            sections: compiledSections,
            kittinglist,
            finalSignOff,
            metadata
        }
        // console.log(JSON.stringify(localData))
        modalOnSubmit(localData, true, 1) //true to execute api call
        handleClose()
    }

    function compileCanvasMetaData() {
        let compiledMetaData = { checkCoordinates: {} }
        checkElements.forEach(checkElement => {
            const checkId = checkElement.checkId //if final sig off checkId = 'final'
            compiledMetaData.checkCoordinates[checkId] = { x: checkElement.x, y: checkElement.y }
        });
        return compiledMetaData
    }

    function compileCanvasDependencyData() {
        let compiledSections = [...sections]
        let finalSignOff = { dependencies: [] }
        elementConnectors.forEach(connectorObj => {
            if (connectorObj.endCheckIds.checkId === 'final') { //Final Sign Off Dependencies
                finalSignOff.dependencies.push({ sectionID: connectorObj.startCheckIds.sectionId, checkID: connectorObj.startCheckIds.checkId })
            } else {// Normal dependencies
                const endSectionIndex = compiledSections.findIndex((section) => section._id === connectorObj.endCheckIds.sectionId);
                if (endSectionIndex !== -1) {
                    const endCheckIndex = compiledSections[endSectionIndex].checks.findIndex((check) => check._id === connectorObj.endCheckIds.checkId);
                    if (endCheckIndex !== -1) {
                        let currentCheckObj = compiledSections[endSectionIndex].checks[endCheckIndex]
                        currentCheckObj.dependencies.push({ sectionID: connectorObj.startCheckIds.sectionId, checkID: connectorObj.startCheckIds.checkId })
                    }
                }
            }
        });
        return { compiledSections, finalSignOff }
    }

    function compileDependenciesIntoConnectors(sectionsInput, finalSignOffInput) { //edit mode
        let compiledElementConnectors = []
        let copySections = [...sectionsInput]
        sectionsInput.forEach((section, secionIndex) => {
            section.checks.forEach((check, checkIndex) => {
                if (check?.dependencies.length > 0) {
                    check.dependencies.forEach(dependency => {
                        const connectorObj = {
                            startCheckIds: { sectionId: dependency.sectionID, checkId: dependency.checkID },
                            endCheckIds: { sectionId: section._id, checkId: check._id }, end: { x: null, y: null },
                        }
                        compiledElementConnectors.push(connectorObj)
                    });
                    //clear dependency array
                    copySections[secionIndex].checks[checkIndex].dependencies = []
                }
            });
        });
        finalSignOffInput?.dependencies?.forEach(dependency => {
            const connectorObj = {
                startCheckIds: { sectionId: dependency.sectionID, checkId: dependency.checkID },
                endCheckIds: { sectionId: 'final', checkId: 'final' }, end: { x: null, y: null },
            }
            compiledElementConnectors.push(connectorObj)
        });

        setsections(copySections)
        setelementConnectors(compiledElementConnectors)
    }

    function cleanUpDependencies(idKey, id) {//delete removed check/section dependencies 
        const copyelementConnectors = elementConnectors.filter((connector) => {
            return (connector.startCheckIds[idKey] !== id && connector.endCheckIds[idKey] !== id)
        })
        setelementConnectors(copyelementConnectors)
    }

    function buttonPressed(btnType) {
        if (btnType === "submit") {
            if (step === 2) {//last step
                if (signedOff) {
                    onSubmit()
                } else {
                    toggleAlert(true, 1, "Please ensure a dependancy is set for final sign off")
                }
            } else {
                if (isValid(step)) {
                    setstep(prev => prev + 1)
                }
            }
        } else {
            if (step === 0) {//first step
                handleClose()
            } else {
                setstep(prev => prev - 1)
            }
        }
    }

    function isValid(stepNum) {
        if (stepNum === 0) {
            if (name === "") {
                toggleAlert(true, 1, "Please enter a checksheet name")
                return false
            }
            if (sections.length === 0) {
                toggleAlert(true, 1, "Checksheet must have at least one section")
                return false
            }
            //check if every section has at least one unit
            for (let index = 0; index < sections.length; index++) {
                const section = sections[index];
                if (section.title === "") {
                    toggleAlert(true, 1, "Please ensure all sections have a title")
                    return false
                }
                if (section.checks.length < 1) {
                    toggleAlert(true, 1, "Please ensure all sections have at least one check")
                    return false
                } else {
                    for (let index = 0; index < section.checks.length; index++) {
                        const check = section.checks[index];
                        if (check.title === "") {
                            toggleAlert(true, 1, "Please ensure all checks have a title")
                            return false
                        }
                    }
                }
            }
        }
        return true
    }


    const renderPage = () => {
        if (dataLoading) {
            return (
                <div className='builder-loader-container'>
                    <Oval
                        visible={true}
                        height="42"
                        width="42"
                        color="var(--accent-color)"
                        secondaryColor="var(--secondary-color)"
                        ariaLabel="oval-loading"
                        wrapperStyle={{ marginTop: '3%' }}
                        wrapperClass=""
                    />
                </div>
            )
        }
        switch (step) {
            case 0:
                return <CheckSheetBuilderModal1
                    name={name}
                    setname={setname}
                    sections={sections}
                    setsections={setsections}
                    nextColorIndex={nextColorIndex}
                    setnextColorIndex={setnextColorIndex}
                    cleanUpDependencies={cleanUpDependencies}
                    toggleAlert={toggleAlert} />;
            case 1:
                return <CheckSheetBuilderModal2
                    kittinglist={kittinglist}
                    setkittinglist={setkittinglist} />;
            case 2:
                return <CheckSheetBuilderModal3
                    checkElements={checkElements}
                    setcheckElements={setcheckElements}
                    sections={sections}
                    elementConnectors={elementConnectors}
                    setelementConnectors={setelementConnectors}
                    checksheetMetaData={checksheetMetaData}
                    signedOff={signedOff}
                    setSignedOff={setSignedOff}
                />;
            default:
                return <CheckSheetBuilderModal1 />;
        }
    };

    return (
        <>
            {show && (
                <div className='backdrop' >
                    <div className='builder-modal-body ' onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header" >
                            <h3 className='modal-title' style={{ width: '100%', margin: '9px 0px 9px 32px' }} >{(modalInEditMode ? "Edit" : "Add") + " Check Sheet - " + stepHeaders[step]}</h3>
                            <CloseIcon onClick={() => handleClose()} sx={{ color: 'white', marginRight: '8px', cursor: 'pointer' }} />
                        </div>
                        {renderPage()}
                        <div className='submit-container'>
                            <button className='submit-btn back-btn' onClick={() => { buttonPressed("back") }}
                            >{(step === 0) ? "Cancel" : "Back"}</button>
                            <button className='submit-btn' onClick={() => buttonPressed("submit")}
                            >{(step === 2) ? "Submit" : "Next"}<EastIcon sx={{ marginLeft: '9px' }} /></button>
                        </div>
                    </div>
                </div>)}
        </>
    )
}
