import React from 'react'
import './styling/SideMenu.css'
import MenuOptionButton from '../components/MenuOptionButton'
import ColcabLogo from '../media/ColcabLogoNoText.png'
import FLLogo from '../media/white-faustlink.png'
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import PersonPinCircleOutlinedIcon from '@mui/icons-material/PersonPinCircleOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import colcabLogo from '../media/Artboard-2.png'
import { menuOptions } from '../DashboardConstants'
function SideMenu({ currentMenuOption, selectMenuOption, dbDataCounts, qcDashboardActive, topSpinnerIsLoading }) {

    const icons = [
        OfflineBoltOutlinedIcon,
        BuildCircleOutlinedIcon,
        LibraryAddCheckOutlinedIcon,
        DocumentScannerOutlinedIcon,
        EngineeringOutlinedIcon,
        PersonPinCircleOutlinedIcon,
        LocalHospitalOutlinedIcon,
        PermMediaOutlinedIcon
    ]

    return (
        <div className={'side-menu-body'}>
            <div className='options-container'>
                <div className='colcab-logo-container'>
                    <img src={ColcabLogo} alt='Colcab Logo' className='col-logo' style={{ imageRendering: '-webkit-optimize-contrast' }} id={topSpinnerIsLoading ? 'spinner' : null} />
                    <div className='logo-text'>
                        {qcDashboardActive === 'sqc' ? <h3>Store</h3> : <h3>Factory</h3>}
                        <h3>Quality Control</h3>
                    </div>

                </div>

                {menuOptions[qcDashboardActive].map((option, index) => {
                    let isSelected = false;
                    if (currentMenuOption === index) isSelected = true
                    return (
                        <MenuOptionButton
                            key={index}
                            isSelected={isSelected}
                            index={index}
                            title={option.title}
                            selectMenuOption={selectMenuOption}
                            Icon={icons[option.icon]}
                            amount={dbDataCounts[index]}
                            qcDashboardActive={qcDashboardActive} />)
                })}
            </div>
            <div className='logo-container'>
                {/* <img src={ColcabLogo} alt="Logo" id="logo-colcab" style={{ imageRendering: '-webkit-optimize-contrast' }} /> */}
                {/* <h4 style={{ margin: 0, width: '60%' }}>Software by</h4> */}
                <img src={FLLogo} alt="Logo" id="logo-fl" draggable='falses' style={{ imageRendering: '-webkit-optimize-contrast' }} />
            </div>
        </div>
    )
}

export default SideMenu