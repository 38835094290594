import React, { useState, useRef, useEffect } from 'react'
import './styling/OverviewToolbar.css'
import SearchBar from './SearchBar'
import NotificationsIcon from '@mui/icons-material/Notifications';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ConstructionIcon from '@mui/icons-material/Construction';
import { uploadCSVFile } from '../api/sqc/FilesAPI';
import '../layouts/styling/TopToolbar.css'
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import CoffeeIcon from '@mui/icons-material/Coffee';
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import colcabLogo from '../media/ColcabLogoNoText.png'
import { ReactComponent as AscendingIcon } from '../media/icons/ascending.svg'
import { ReactComponent as DescendingIcon } from '../media/icons/descending.svg'
import { ReactComponent as DevIcon } from '../media/icons/devIcon.svg'
import { menuOptions } from '../DashboardConstants';

export default function OverviewToolbar({ searchTerm, activeListType,
    displayCSVuploadButton, setsearchTerm, selectedFilterButton,
    setselectedFilterButton, toggleModal, currentMenuOption,
    selectMenuOption, toggleAlert, readDataDB, jobMetaData, logout, changeActiveDashboard, topSpinnerIsLoading, qcDashboardActive, setShowDevNotes, newJobAlertShow,
    setnewJobAlertShow }) {

    const [newDataNotificationNumber, setnewDataNotificationNumber] = useState({ numNewReworks: 0, numNewCompleted: 0 })
    const [sortDirection, setSortDirection] = useState('asc')

    function handleSort() {
        if (sortDirection === 'asc') {
            setSortDirection('desc')
        } else {
            setSortDirection('asc')
        }
    }

    useEffect(() => {
        const numNewReworks = jobMetaData.newReworks?.length
        const numNewCompleted = jobMetaData.newCompleted?.length
        if (numNewReworks !== undefined && numNewCompleted !== undefined) {
            setnewDataNotificationNumber({ numNewReworks, numNewCompleted })
        }
    }, [jobMetaData])

    function getboxShadow(btnNum) { //filter tool buttons
        if (selectedFilterButton === btnNum) {
            return "inset 0 0 2px #000000"
        } else {
            return "0 0 0px"
        }
    }
    function getColor(btnNum) {//filter tool buttons
        if (selectedFilterButton === btnNum) {
            return "#009c9e"
        } else {
            return "#7b7b7b"
        }
    }

    const inputFileRef = useRef(null);
    const uploadClicked = () => {
        inputFileRef.current.click();
    };

    // dropdown menu function and vars
    const [showDropDown, setshowDropDown] = useState(false)
    function toggleMenu() { //show/hide dropdown
        setshowDropDown(prev => !prev)
    }
    function handleClose() { //hide dropdown
        setshowDropDown(false)
    }

    async function csvLoaded(event) {
        if (!("0" in event.target.files)) return
        const apiResponse = await uploadCSVFile(event.target.files[0])
        // console.log("CSV data: " + JSON.stringify(apiResponse))
        if (apiResponse.success) {
            toggleAlert(true, 2, apiResponse.message)
            readDataDB(3, true)
        } else {
            toggleAlert(true, 0, apiResponse.message)
        }
    }

    function creatNewButtonClicked() {
        if (qcDashboardActive === "sqc") {
            toggleModal(true, currentMenuOption)
        } else {
            let modalIndex = currentMenuOption
            if (currentMenuOption === 0) {
                toggleModal(true, currentMenuOption)
            } else {
                modalIndex = modalIndex - 1
                toggleModal(true, modalIndex)
            }
        }
    }

    const handleShowDevNotes = () => {
        setShowDevNotes(true)
        handleClose()
    }

    return (
        <div className='overview-toolbar-body'>
            <div className='search-and-filter-container'>
                {menuOptions[qcDashboardActive][currentMenuOption]?.hideSearch ?
                    <></> :
                    <SearchBar
                        searchTerm={searchTerm}
                        setsearchTerm={setsearchTerm} />
                }
                {activeListType === 2 &&
                    <div className='filter-tool-box'>
                        <button onClick={() => setselectedFilterButton(-1)} style={{ boxShadow: getboxShadow(-1) }}>
                            <p style={{ fontWeight: 600, color: getColor(-1) }}>All</p>
                        </button>
                        <button onClick={() => setselectedFilterButton(0)}
                            style={{ boxShadow: getboxShadow(0), borderLeft: '1px solid #7b7b7b', borderRight: '1px solid #7b7b7b' }}>
                            <AttachMoneyIcon sx={{ fontSize: '18px', color: getColor(0) }} />
                        </button>
                        <button onClick={() => setselectedFilterButton(1)} style={{ boxShadow: getboxShadow(1) }}>
                            <ConstructionIcon sx={{ fontSize: '18px', color: getColor(1) }} />
                        </button>
                    </div>
                }
            </div>
            <div className='tool-box'>
                {(displayCSVuploadButton === true) && //file upload button for CSV files
                    <button className='just-for-spacing' onClick={() => uploadClicked()}>
                        <input
                            type="file"
                            id="csvfile"
                            name="csvfile"
                            accept=".csv"
                            ref={inputFileRef}
                            style={{ display: "none" }}
                            onChange={(event) => csvLoaded(event)}
                        />
                        <UploadFileIcon />
                    </button>
                }
                {qcDashboardActive === "sqc" ?
                    <>
                        <button style={{ backgroundColor: 'darkred' }} onClick={() => selectMenuOption(1)}>
                            <NotificationsIcon sx={{ color: 'white' }} />
                            {newDataNotificationNumber.numNewReworks > 0 &&
                                <div className='notification-number' style={{ backgroundColor: 'red' }}>{newDataNotificationNumber.numNewReworks}</div>
                            }
                            <span className='tooltip-text' >Reworks</span>
                        </button>
                        <button style={{ backgroundColor: 'darkgreen' }} onClick={() => selectMenuOption(2)}>
                            <NotificationsIcon sx={{ color: 'white' }} />
                            {newDataNotificationNumber.numNewCompleted > 0 &&
                                <div className='notification-number' style={{ backgroundColor: '#19b119' }}>{newDataNotificationNumber.numNewCompleted}</div>
                            }
                            <span className='tooltip-text' >Completed</span>
                        </button>
                    </> : <>
                        <button style={{ backgroundColor: 'darkred' }} onClick={() => [setnewJobAlertShow(prev => ({ ...prev, rework: false })), selectMenuOption(1)]}>
                            <NotificationsIcon sx={{ color: 'white' }} />
                            {newJobAlertShow.rework &&
                                <div className='notification-number' style={{ backgroundColor: 'red' }}>{"new"}</div>
                            }
                            <span className='tooltip-text' >Reworks</span>
                        </button>
                        <button style={{ backgroundColor: '#154e69' }} onClick={() => [setnewJobAlertShow(prev => ({ ...prev, hospital: false })), selectMenuOption(2)]}>
                            <NotificationsIcon sx={{ color: 'white' }} />
                            {newJobAlertShow.hospital &&
                                <div className='notification-number' style={{ backgroundColor: '#50aed4' }}>{"new"}</div>
                            }
                            <span className='tooltip-text' >Hospital</span>
                        </button>
                        <button style={{ backgroundColor: 'darkgreen' }} onClick={() => [setnewJobAlertShow(prev => ({ ...prev, completed: false })), selectMenuOption(3)]}>
                            <NotificationsIcon sx={{ color: 'white' }} />
                            {newJobAlertShow.completed &&
                                <div className='notification-number' style={{ backgroundColor: '#19b119' }}>{"new"}</div>
                            }
                            <span className='tooltip-text' >Completed</span>
                        </button>
                    </>
                }
                {(menuOptions[qcDashboardActive][currentMenuOption]?.showCreateNewBtn) &&
                    <button className='create-new-button'
                        onClick={() => creatNewButtonClicked()}>
                        Create New
                    </button>
                }
                <div className='menu-container'  >
                    <div className='menu-btn' onClick={() => toggleMenu()} >
                        {showDropDown ? <CoffeeIcon className='icon' sx={{ fontSize: '18px' }} /> : <MenuIcon className='icon' />}
                    </div>
                </div>
                {showDropDown &&
                    <div className='dropdown-backdrop' onClick={handleClose}>
                        <div className='dropdown-menu' onClick={(e) => e.stopPropagation()}>
                            <div className='dropdown-option' onClick={() => [changeActiveDashboard()]} style={{ borderBottom: '1px solid #153154' }}>
                                {qcDashboardActive === "sqc" ? <PrecisionManufacturingOutlinedIcon /> : <LocalMallOutlinedIcon />}
                                <p>
                                    {qcDashboardActive === "sqc" ? "Factory QC" : "Store QC"}
                                </p>
                            </div>
                            {/* <div className='dropdown-option' style={{ borderBottom: '1px solid #153154' }} onClick={() => handleShowDevNotes()} >
                                <DevIcon className='dev-icon' />
                                <p>
                                    Dev Notes
                                </p>
                            </div> */}
                            <div className='dropdown-option' onClick={() => [handleClose(), logout()]}>
                                <LogoutIcon />
                                <p>
                                    Logout
                                </p>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
