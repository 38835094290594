import React, { useState, useEffect } from 'react'
import { addGroup } from '../api/fqc/GroupsAPI';

export default function AddEditJobGroupModal({ show, toggleAddGroupModal, settopSpinnerIsLoading, toggleAlert, groupAddedTrigger }) {

    const [name, setname] = useState("");

    function onChange(event) {
        switch (event.target.name) {
            case "name":
                setname(event.target.value)
                break;
            default:
                break;
        }
    }

    async function onSubmit() {
        if ((name === "")) {
            toggleAlert(true, 1, "Please enter a group name")
            return false
        }
        settopSpinnerIsLoading(true)
        const props = { name }
        const apiData = await addGroup(props)
        if (apiData?.success === true) {
            groupAddedTrigger(apiData.data)
            toggleAlert(true, 2, "Group Added")
        } else {
            toggleAlert(true, 0, "An error occurred")

        }
        settopSpinnerIsLoading(false)
        handleClose()
    }


    function clearInputFields() {
        setname("")
    }

    function handleClose() {
        //clear local data
        clearInputFields()
        //close method
        toggleAddGroupModal(false)
    }

    return (
        <>
            {show ? (
                <div className='backdrop' onClick={handleClose}>
                    <div className='modal-body ' style={{ width: '510px' }} onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header" >
                            <h3 className='modal-title' >{"Add Job Group"}</h3>
                        </div>
                        {/* body container */}
                        <form className='input-form'   >
                            <div className="form-group-vertical">
                                <label htmlFor="body" className="col-form-label">Name</label>
                                <input value={name} type="text" autoComplete='off' placeholder='' className="modal-input" name="name" onChange={onChange} ></input>
                            </div>
                        </form>
                        <div className='submit-container'>
                            <button className='submit-btn back-btn' onClick={() => handleClose()}   >{"Cancel"}</button>
                            <button className='submit-btn' onClick={() => onSubmit()} >{"Submit"}</button>
                        </div>
                    </div>
                </div >)
                : <></>}
        </>)
}
