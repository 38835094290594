import React, { useState } from 'react'
import { ThreeDots } from 'react-loader-spinner'
import { resetPassword } from '../api/sqc/UserAPI';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import './styling/ResetPassword.css'


export default function ResetPassword() {

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setisLoading] = useState(false)

    // Collect confirmation code and new password
    async function resetPasswordSubmit() {
        try {
            setisLoading(true)
            const url = new URL(window.location.href);
            const searchParams = url.searchParams;
            const token = searchParams.get('token')
            const data = await resetPassword(token, newPassword);
            // console.log(JSON.stringify(data))
            alert(data.message)
            if(data.success){
                window.location = '/';
            }
            // window.location.reload();

        } catch (err) {
            console.log(err);
        }
        setisLoading(false)
    }

    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    // Function to handle the reset password button click
    const handleResetPasswordClick = () => {
        if (newPassword === confirmPassword) {
            // Reset password logic here
            resetPasswordSubmit()
        } else {
            alert("Passwords do not match")
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleResetPasswordClick()
        }
    }

    return (
        <div className="reset-password-container">
            <div className="left-section">
                <div className="reset-password-text">
                    <h1>Reset Password</h1>
                    <h3>
                        Enter your new password.
                    </h3>
                </div>
            </div>

            <div className="right-section">
                <div className="reset-password-form" >
                    <input
                        onKeyDown={(e) => handleKeyPress(e)}
                        type={showPassword ? 'text' : 'password'}
                        placeholder="New Password"
                        className="new-password"
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                    >
                    </input>
                    <input
                        onKeyDown={(e) => handleKeyPress(e)}
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Confirm Password"
                        className="confirm-password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                    />

                    <div className="show-password">
                        {showPassword ? (
                            <CheckBox
                                className='visibility-icon'
                                onClick={() => setShowPassword(!showPassword)}
                            />
                        ) : (
                            <CheckBoxOutlineBlank
                                className='visibility-icon'
                                onClick={() => setShowPassword(!showPassword)}
                            />
                        )}
                        <p> Show Passwords </p>
                    </div>
                    <button className="reset-button" onClick={handleResetPasswordClick}>
                        {isLoading ?
                            <ThreeDots
                                visible={true}
                                height="42"
                                width="80"
                                color="#ffffff"
                                radius="9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                            :
                            "Reset Password"
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}
