export const sectionColors = [
    "#AFEEEE", // Pale Turquoise
    "#98FB98", // Pale Green
    "#FFDAB9", // Peach Puff
    "#FFFACD", // Light Goldenrod Yellow
    "#FFB6C1", // Light Pink
    "#e0ff2e", // Green Yellow
    "#6495ED", // Cornflower Blue
    "#FF6347", // Tomato (same as before, already a lighter shade)
    "#FFA07A", // Light Salmon (same as before, already a lighter shade)
    "#FFA500"  // Orange (same as before, already a lighter shade)
]