import React, { useState, useEffect } from 'react'
import './styling/modals.css'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Bars, Oval } from 'react-loader-spinner'
import AddEditJobGroupModal from '../modals/AddEditJobGroupModal'

export default function FactoryJobAddEditModal({ show, settopSpinnerIsLoading, jobGroups, modalInEditMode, toggleModal, modalOnSubmit, customers, checksheets, customersLoading, checksheetsLoading,
    selectedListItemData, toggleAlert, setjobGroups }) { //from = Add / Edit
    const [showGroupModal, setshowGroupModal] = useState(false)
    //form input fields
    const [group, setgroup] = useState("");//Job Name
    const [serialNumber, setserialNumber] = useState("");
    const [checksheetTemplate, setchecksheetTemplate] = useState();
    const [customer, setcustomer] = useState("")
    const [additionalInfo, setadditionalInfo] = useState("");
    //dropdown options
    const [checksheetOptions, setchecksheetOptions] = useState([]);
    const [customerOptions, setcustomerOptions] = useState([])
    const [groupOptions, setgroupOptions] = useState([]);
    const [inputFocused, setinputFocused] = useState(-1);

    const [isLoading, setisLoading] = useState(false)

    useEffect(() => {
        if (show) {
            setchecksheetOptions(checksheets)
            setcustomerOptions(customers)
            setgroupOptions(jobGroups)
            if (modalInEditMode) {
                populateInputFields()
            }
        } else { //clear data on close (show===false trigger)
            clearInputFields()
        }
    }, [show])

    function populateInputFields() { //on edit
        setserialNumber(selectedListItemData.serialNumber)
        if (selectedListItemData.checksheetTemplate !== null) {
            setchecksheetTemplate(selectedListItemData.checksheetTemplate._id)
        }
        if (selectedListItemData.customer !== null) {
            setcustomer(selectedListItemData.customer._id)
        }
        if (selectedListItemData?.group?._id) {
            setgroup(selectedListItemData.group._id)
        }
    }

    function clearInputFields() {
        setgroup("")
        setserialNumber("")
        setchecksheetTemplate("")
        setcustomer("")
        setadditionalInfo("")
    }

    function handleClose(cancelClicked) {
        //clearInputFields()
        //close method
        toggleModal(false, 0)
    }

    function onChange(event) {
        switch (event.target.name) {
            case "group":
                setgroup(event.target.value)
                break;
            case "serialNumber":
                setserialNumber(event.target.value)
                break;
            case "checksheetTemplate":
                setchecksheetTemplate(event.target.value)
                break;
            case "customer":
                setcustomer(event.target.value)
                break;
            case "additionalInfo":
                setadditionalInfo(event.target.value)
                break;
            default:
                break;
        }
    }

    async function onSubmit() {
        if (!validateInput()) return //if input not valid, return
        let jobJsonObject = {
            serialNumber,
            checksheetTemplate,
            customer
        }
        if (group !== "") {
            jobJsonObject.group = group
        }
        setisLoading(true)
        const submissionResponse = await modalOnSubmit(jobJsonObject, true) //true to execute api call
        if (submissionResponse?.success !== false) {
            setisLoading(false)
            handleClose()
        }
    }

    function validateInput() {
        if ((serialNumber === "") ||
            (checksheetTemplate === "") ||
            (customer === "")) {
            toggleAlert(true, 1, "Please make sure all required fields are filled in")
            return false
        }
        return true
    }

    function toggleAddGroupModal(state) {
        setshowGroupModal(state)
    }

    function groupAddedTrigger(groupObj) {
        setjobGroups(prev => [...prev, groupObj])
        setgroupOptions(prev => [...prev, groupObj])
        setgroup(groupObj._id)
    }

    return (
        <>
            {show ? (
                <div className='backdrop' onClick={handleClose}>
                    <div className='modal-body ' onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header" >
                            <h3 className='modal-title' >{(modalInEditMode ? "Edit" : "Add") + " Job"}</h3>
                        </div>
                        {/* body container */}
                        <form className='input-form'  >
                            <div className="form-group-vertical">
                                <label htmlFor="requiredMaterials" className="col-form-label">Job Name<span className='optional-label'>(optional)</span></label>
                                <div className='horizontal-align' style={{ alignItems: 'center' }}>
                                    <select value={group} style={{ width: '95%' }} className="modal-input" name="group" id='group' onChange={onChange} >
                                        <option value="" ></option>
                                        {groupOptions.map((option) => {
                                            return (
                                                <option key={option._id} value={option._id}>{option.name}</option>
                                            )
                                        })}</select>
                                    <div className='add-icon'>
                                        <AddCircleOutlineIcon onClick={() => toggleAddGroupModal(true)} sx={{ cursor: 'pointer' }} className='icon' />
                                        <span className='tooltip-text'>Add New Group</span>
                                    </div>

                                </div>
                            </div>
                            <div className="form-group-vertical">
                                <label htmlFor="serialNumber" className="col-form-label">Case Serial Number</label>
                                <input value={serialNumber} type="text" autoComplete='off' id='serialNumber' placeholder='' className="modal-input" name="serialNumber" onChange={onChange} ></input>
                            </div>
                            <div className="form-group-vertical">
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                    <label htmlFor="checksheet" className="col-form-label">Checksheet</label>
                                    {(inputFocused === 1 && checksheetsLoading) &&
                                        <Bars
                                            height="15"
                                            width="15"
                                            color="var(--primary-color)"
                                            ariaLabel="bars-loading"
                                            wrapperStyle={{ marginLeft: '9px' }}
                                            wrapperClass=""
                                            visible={true}
                                        />
                                    }
                                </div>
                                <select onFocus={() => setinputFocused(1)} value={checksheetTemplate} className="modal-input" name="checksheetTemplate" id='checksheetTemplate' onChange={onChange} >
                                    <option value="" disabled={true}></option>
                                    {checksheetOptions.map((option) => {
                                        return (
                                            <option key={option._id} value={option._id}>{option.name}</option>
                                        )
                                    })}</select>
                            </div>
                            <div className="form-group-vertical">
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                    <label htmlFor="customer" className="col-form-label">Customer
                                    </label>
                                    {(inputFocused === 2 && customersLoading) &&
                                        <Bars
                                            height="15"
                                            width="15"
                                            color="var(--primary-color)"
                                            ariaLabel="bars-loading"
                                            wrapperStyle={{ marginLeft: '9px' }}
                                            wrapperClass=""
                                            visible={true}
                                        />
                                    }
                                </div>
                                <select onFocus={() => setinputFocused(2)} value={customer} className="modal-input" name="customer" id='customer' onChange={onChange} >
                                    <option value="" disabled={true}></option>
                                    {customerOptions.map((option) => {
                                        return (
                                            <option key={option._id} value={option._id}>{option.name}</option>
                                        )
                                    })}</select>
                            </div>
                            {/* <div className="form-group-vertical">
                                <label htmlFor="additionalInfo" className="col-form-label">Additional Info<span className='optional-label'>(optional)</span></label>
                                <textarea value={additionalInfo} name='additionalInfo' id='additionalInfo' onChange={(e) => onChange(e)}
                                    style={{ resize: 'none', background: 'white', border: '1px solid #009c9e', borderRadius: '3px' }} />
                            </div> */}
                        </form>
                        <div className='submit-container'>
                            <button className='submit-btn back-btn' onClick={() => { handleClose() }}>{"Cancel"}</button>
                            <button className='submit-btn' onClick={() => { onSubmit() }} disabled={isLoading}>
                                {isLoading ?
                                    <Oval
                                        secondaryColor='#fff'
                                        color="#fff"
                                        strokeOpacity={0.2}
                                        strokeWidth={5}
                                        height={15}
                                        width={15}
                                    /> : "Submit"}
                            </button>
                        </div>
                    </div>
                    <AddEditJobGroupModal
                        show={showGroupModal}
                        toggleAddGroupModal={toggleAddGroupModal}
                        settopSpinnerIsLoading={settopSpinnerIsLoading}
                        toggleAlert={toggleAlert}
                        groupAddedTrigger={groupAddedTrigger}
                    />
                </div >)
                : <></>}
        </>)
}
