import React from 'react'
import './styling/List.css'
import { ReactComponent as ArrowUp } from '../media/icons/arrowUp.svg'
import { ReactComponent as ArrowDown } from '../media/icons/arrowDown.svg'

export default function ListHeader({ numColumns, headerArray, sort, setSort }) {
  const sortDate = () => {
    setSort({ key: 'createdAt', direction: sort.direction === 'asc' ? 'desc' : 'asc' })
  }

  const sortProgress = () => {
    setSort({ key: 'progress', direction: sort.direction === 'asc' ? 'desc' : 'asc' })
  }

  return (
    <div className='header-body'>
      {headerArray.map((header, index) => {
        return (
          <div key={index}
            className='header-item'
            style={{ width: 'calc(100% / ' + numColumns + ')' }}>
            <div>
              {header === 'Start Date' ? (
                <div className='sorted-field'>
                  {header}

                  <div className='sorting-icons-container' onClick={() => { sortDate() }}>
                    <ArrowUp style={{
                      height: '12px',
                      color: (
                        sort.direction === 'asc' && sort.key === 'createdAt' ? 'lightgreen' : 'white'
                      )
                    }} />
                    <ArrowDown style={{
                      height: '12px',
                      color: (
                        sort.direction === 'desc' && sort.key === 'createdAt' ? 'lightgreen' : 'white'
                      )
                    }} />
                  </div>

                </div>
              ) : header === 'Progress' ? (
                <div className='sorted-field'>
                  {header}
                  <div className='sorting-icons-container' onClick={() => { sortProgress() }}>
                    <ArrowUp style={{
                      height: '12px',
                      color: (
                        sort.direction === 'asc' && sort.key === 'progress' ? 'lightgreen' : 'white'
                      )
                    }} />
                    <ArrowDown style={{
                      color: (
                        sort.direction === 'desc' && sort.key === 'progress' ? 'lightgreen' : 'white'
                      )
                    }} />
                  </div>

                </div>
              ) : (
                <div className='unsorted-field'>{header}</div>
              )}
            </div>
          </div>
        )
      })}
    </div >
  )
}
