import { sqcConfig } from "../ApiConfig"

export async function getAllChecksheets() {
    return fetch(sqcConfig.url + '/api/checksheets', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        }
    }).then(res => res.json())
        .then(data => {
            //console.log("Data: " + JSON.stringify(data.result))
            // setcustomers(data.result)
            return data.result
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function addChecksheet(checksheetProps) {
    return fetch(sqcConfig.url + '/api/checksheets', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: JSON.stringify(checksheetProps)
    }).then(res => res.json())
        .then(data => {
            // if (data.success) {
            //     openAlertModal(data.message, 2)
            // }
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}


export async function updateChecksheet(checksheetId, checksheetProps) {
    return fetch(sqcConfig.url + '/api/checksheets/' + checksheetId, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: JSON.stringify(checksheetProps)
    }).then(res => res.json())
        .then(data => {
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function deleteChecksheet(checksheetId) {
    return fetch(sqcConfig.url + '/api/checksheets/' + checksheetId, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
    }).then(res => res.json())
        .then(data => {
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}