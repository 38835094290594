import './App.css';
import SQCDashboard from './screens/SQCDashboard';
import FQCDashboard from './screens/FQCDashboard';
import { Login } from './screens/Login';
import Unauthorized from './screens/Unauthorized';
import useToken from './useToken';
import React, { useState, useEffect } from 'react'

function App() {
  const { token, setToken, type, setType } = useToken(null); //auth token
  const [sqcSocket, setsqcSocket] = useState(null);
  const [fqcSocket, setfqcSocket] = useState(null);
  const [sqcMessage, setsqcMessage] = useState('');
  const [fqcmessage, setfqcMessage] = useState('');
  const [qcDashboardActive, setQcDashboardActive] = useState('sqc');

  useEffect(() => {
    const sessionActiveDashboard = localStorage.getItem('qcDashboardActive') || "sqc";
    setQcDashboardActive(sessionActiveDashboard)
    setTheme(sessionActiveDashboard)
  }, [])

  useEffect(() => {
    if (token == null) {
      if (sqcSocket !== null) {
        sqcSocket.close()
      }
      if (fqcSocket !== null) {
        fqcSocket.close()
      }
      return
    }
    // Create a new WebsqcSocket object
    const tokenData = parseJwt(token)
    checkTokenExpiry(tokenData)
    //---> SQC web socket connection
    let newsqcSocket = new WebSocket(process.env.REACT_APP_SQC_WEBSOCKET_URL + '?id=' + tokenData.userID);
    setsqcSocket(newsqcSocket);
    //---> FQC web socket connection
    let newfqcSocket = new WebSocket(process.env.REACT_APP_FQC_WEBSOCKET_URL + '?id=' + tokenData.userID);
    setfqcSocket(newfqcSocket);
    // Clean up the Websocket connection on unmount
    return () => {
      newsqcSocket.close();
      newfqcSocket.close();
    };
  }, [token])

  useEffect(() => {
    const tokenData = parseJwt(token)
    if (sqcSocket !== null) {
      // Handle incoming WebSocket messages
      sqcSocket.onmessage = (event) => {
        const parsedMsg = JSON.parse(event.data)
        if (parsedMsg.type !== 'ping') {
          setsqcMessage(parsedMsg);
        }
      };
      sqcSocket.onopen = () => {
        console.log('(S)Connection opened');
      };
      // Handle WebsqcSocket connection close
      sqcSocket.onclose = () => {
        console.log('(S)Connection closed');
        const newsqcSocket = new WebSocket(process.env.REACT_APP_SQC_WEBSOCKET_URL + '?id=' + tokenData.userID);
        setsqcSocket(newsqcSocket);
      };
    }
  }, [sqcSocket]);

  useEffect(() => {
    const tokenData = parseJwt(token)
    if (fqcSocket !== null) {
      // Handle incoming WebSocket messages
      fqcSocket.onmessage = (event) => {
        const parsedMsg = JSON.parse(event.data)
        if (parsedMsg.type !== 'ping') {
          setfqcMessage(parsedMsg);
        }
      };
      fqcSocket.onopen = () => {
        console.log('(F)Connection opened');
      };
      // Handle WebfqcSocket connection close
      fqcSocket.onclose = () => {
        console.log('(F)Connection closed');
        const newfqcSocket = new WebSocket(process.env.REACT_APP_FQC_WEBSOCKET_URL + '?id=' + tokenData.userID);
        setfqcSocket(newfqcSocket);
      };
    }
  }, [fqcSocket]);

  function checkTokenExpiry(tokenData) {
    const expirationTime = (tokenData.exp * 1000) - 60000
    if (Date.now() >= expirationTime) {
      if (window.confirm("Access Token expired. Please login again.")) {
        logout()
      }
    }
  }

  function parseJwt(token) {
    if (token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    }
  }

  function changeActiveDashboard() {
    if (qcDashboardActive === "sqc") {
      setQcDashboardActive("fqc")
      localStorage.setItem('qcDashboardActive', 'fqc')
      setTheme('fqc')
    } else {
      setQcDashboardActive("sqc")
      localStorage.setItem('qcDashboardActive', 'sqc')
      setTheme('sqc')
    }
  }

  function setTheme(name) {
    const root = document.body;
    root.style.setProperty('--primary-color', `var(--${name}-primary-color)`);
    root.style.setProperty('--primary-color-eb', `var(--${name}-primary-color-eb)`);
    root.style.setProperty('--secondary-color', `var(--${name}-secondary-color)`);
    root.style.setProperty('--accent-color', `var(--${name}-accent-color)`);
    root.style.setProperty('--selected-row-color', `var(--${name}-selected-row-color)`);
  }

  function logout() {
    setToken(null)
    setType(null)
    sessionStorage.removeItem('token')
  }

  if (token === null) {
    return <Login
      setToken={setToken}
      setType={setType}
    />
  } else {
    if (type == 1) {
      return (
        <>
          {
            qcDashboardActive === "sqc" ?
              <SQCDashboard
                logout={logout}
                message={sqcMessage}
                qcDashboardActive={qcDashboardActive}
                changeActiveDashboard={changeActiveDashboard} /> :
              <FQCDashboard
                logout={logout}
                message={fqcmessage}
                qcDashboardActive={qcDashboardActive}
                changeActiveDashboard={changeActiveDashboard} />
          }
        </>
      );
    } else {
      return <Unauthorized
        logout={logout} />
    }
  }
}

export default App;

