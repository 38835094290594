import React, { useState } from 'react'
import './styling/Login.css'
import { loginUser } from '../api/sqc/UserAPI'

export const Login = ({ setToken, setType }) => {
    //input field values
    const [email, setemail] = useState();
    const [password, setPassword] = useState();
    //misc vars
    const [showInvalidDetailsText, setshowInvalidDetailsText] = useState(false)
    const [logoIs, setlogoIs] = useState("not-spinning")

    // const [notification, setNotification] = useState({ title: '', body: '' });
    // const [isTokenFound, setTokenFound] = useState(false);

    const handleSubmit = async e => {
        setlogoIs("spinning")
        e.preventDefault();
        const emailFromInput = document.getElementById('email').value
        const token = await loginUser({
            email: emailFromInput,
            password,

        });
        setlogoIs("not-spinning")
        try {
            if (token.success) {
                setType(token.result.type)
                setToken(token.result.accessToken);
                setshowInvalidDetailsText(false)
            } else {
                setshowInvalidDetailsText(true)
            }
        } catch (error) {
            console.log("Fetch Error: " + error)
            setlogoIs("not-spinning")
            alert("(Internal Server Error):\n Please contact the developer if the issue persists")
        }
    }

    return (
        <div className='login-backdrop' >
            <h1 className='login-header-1'>Colcab Quality Control</h1>
            {/* <img src={Logo} alt="Colcab" className='login-header-1' style={{height: '100px'}} /> */}
            <div className='login-container-box' >
                {/* <img src={Logo} alt="Colcab" id="login-logo" /> */}
                {/* <h1 style={{ margin: 0, color: '#009c9e' }}>Colcab QC</h1>
                <hr style={{ border: '0px solid #9a282d', width: '93%' }}></hr> */}
                <div className='login-logo-container' id={"login-logo-" + logoIs}>
                    <h3>
                        <span style={{ color: '#153154' }}>C</span>
                        <span style={{ color: '#009c9e' }}>Q</span>
                        <span style={{ color: '#8c9fba' }}>C</span>
                    </h3>
                </div>
                <p className='invalid-details-text' style={{ opacity: showInvalidDetailsText ? 1 : 0 }} >Invalid Login Credentials</p>
                <form className='login-form' onSubmit={handleSubmit} >
                    <div className="form-group" >
                        {/* <label >Email:</label> */}
                        <input id='email' onChange={e => [setemail(e.target.value), setshowInvalidDetailsText(false)]} type="email" name="email" className="form-control" placeholder='Email Address' />
                    </div>
                    <div className="form-group" >
                        {/* <label >Password:</label> */}
                        <input onChange={e => [setPassword(e.target.value), setshowInvalidDetailsText(false)]} type="password" name="password" className="form-control" placeholder='Password' />
                    </div>
                    <button id='login-button' type="submit" className="btn btn-primary btn-block">
                        LOG IN
                    </button>
                </form>
                <a style={{ fontSize: '12px', color: '#009c9e' }} href='/forgot-password'>Forgot Password?</a>
            </div>
        </div >
    )
}