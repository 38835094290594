import React, { useState, useRef, useEffect } from 'react'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './styling/List.css'
import { DateTime } from 'luxon'
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CameraIcon from '@mui/icons-material/Camera';
import DownloadIcon from '@mui/icons-material/Download';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import CloseIcon from '@mui/icons-material/Close';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import { getJobById } from '../api/fqc/JobsAPI';

import { Bars } from 'react-loader-spinner';
import { CheckCircleOutline, CircleOutlined } from '@mui/icons-material';

export default function ListRowExpandable({
    currentMenuOption, indexID, isSelected, setSelectListItem,
    numColumns, keyArray, rowData, toggleModal,
    editButtonClicked, startFileDownload, generateButtonClicked, updatedCheckData }) {

    const qcStatusText = {
        "0": "Pending",
        "1": "In Progress",
        "2": "Passed",
        "3": "Rework"
    }

    const statusText = {
        "0": "Pending",
        "1": "In Progress",
        "2": "Rework",
        "3": "Passed"

    }

    const holdStatusText = {
        "0": "Pending",
        "1": "In Progress",
        "2": "Completed",
    }

    const superStatusText = {
        "0": "Pending",
        "1": "In Progress",
        "2": "Completed",
    }

    const statusColor = {
        "0": "transparent",
        "1": "rgba(255, 250, 205, 0.4)",
        "2": "rgba(255, 182, 193, 0.4)",
        "3": "rgba(152, 251, 152, 0.4)"
    }

    const holdStatusColor = {
        "0": "transparent",
        "1": "rgba(255, 250, 205, 0.4)",
        "2": "rgba(152, 251, 152, 0.4)"
    }

    const [bgColor, setbgColor] = useState('')
    const [expandState, setexpandState] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])
    const [selectedChecksheetIndex, setSelectedChecksheetIndex] = useState(0) //for when multiple checksheets attached
    const [selectedCheckIndexes, setselectedCheckIndexes] = useState({ sectionIndex: null, checkIndex: null }); //selected check row
    const [pictureIconCheckIdSelected, setpictureIconCheckIdSelected] = useState(null);

    useEffect(() => { //init
        const shouldDisplayActionsOnSelect = (currentMenuOption === 0 || currentMenuOption === 5)//for active jobs and resources(fqc)
        if (isSelected) {
            setbgColor("var(--selected-row-color)")
            shouldDisplayActionsOnSelect && showDetails()
        } else {
            setbgColor("")
            shouldDisplayActionsOnSelect && hideDetails()
        }
    }, [isSelected, rowData])


    useEffect(() => { //init
        if (updatedCheckData?._id) {
            if (expandState === "open") {
                let copyData = JSON.parse(JSON.stringify(data))
                const checksheetIndex = copyData.findIndex((checksheet) => checksheet._id === updatedCheckData.checksheetId)
                if (checksheetIndex === -1) return
                const sectionIndex = copyData[checksheetIndex]?.sections.findIndex((section) => section._id === updatedCheckData.sectionId)
                const checkIndex = copyData[checksheetIndex]?.sections[sectionIndex]?.checks.findIndex((check) => check._id === updatedCheckData._id)
                let newCheckDataValue = updatedCheckData
                delete newCheckDataValue.sectionId
                delete newCheckDataValue.checksheetId
                copyData[checksheetIndex].sections[sectionIndex].checks[checkIndex] = newCheckDataValue
                setData(copyData)
            }
        }
    }, [updatedCheckData])

    //More Details functions
    const showMoreDetails = useRef(false)
    function showDetails() { //show row function buttons
        let container = document.getElementById('list-row-details-container' + indexID)
        if (container === null) return
        container.style.display = "flex";
        showMoreDetails.current = true;
    }
    function hideDetails() {
        if (!showMoreDetails.current) return
        let container = document.getElementById('list-row-details-container' + indexID)
        if (container === null) return
        container.style.display = "none";
        showMoreDetails.current = false;
    }

    function editButtonClickedLocal(e) { //to stop propagation and open edit modal
        e.stopPropagation()
        editButtonClicked()
    }

    function deleteButtonClicked(e) { //delete record...obviously 
        e.stopPropagation()
        toggleModal(true, 7)
    }

    function openAddressIssuesModal() { //only for rework jobs in SQC
        if (!isSelected) {
            setSelectListItem(rowData)
        }
        toggleModal(true, 6)
    }


    async function changeExpandState(id) { //Expand / Collapse expandable record (FQC only)
        if (expandState === "open") {
            setselectedCheckIndexes({ sectionIndex: null, checkIndex: null })
            setexpandState("close")
        } else {
            setexpandState("open")
            setIsLoading(true)
            try {
                let res = await getJobById(rowData._id)
                setSelectedChecksheetIndex(res.length - 1)
                setData(res)
            } catch (error) {
                console.log("Error: " + error)
            }
            setIsLoading(false)
        }
    }

    function selectCheck(sectionIndex, checkIndex) {
        if (sectionIndex === selectedCheckIndexes.sectionIndex && checkIndex === selectedCheckIndexes.checkIndex) { //deselect if already selected and not grid
            setselectedCheckIndexes({ sectionIndex: null, checkIndex: null })
        } else { //else select
            setselectedCheckIndexes({ sectionIndex, checkIndex })
        }
    }

    function displayFinalSignOff(data, selectedChecksheetIndex) {
        let signatures = [
            <div className='signoff'>
                Supervisor Sign Off:
                {data[selectedChecksheetIndex]?.finalSignOff.supervisorSignature !== null ? (
                    <CheckCircleOutline sx={{ color: 'green' }} />
                ) : (<CircleOutlined />)}

            </div>,
            <div className='signoff'>
                QC Sign Off:
                {data[selectedChecksheetIndex]?.finalSignOff.finalSignature !== null ? (
                    <CheckCircleOutline sx={{ color: 'green' }} />
                ) : (<CircleOutlined />)}

            </div>
        ]

        if (data.length > 1) {
            const previousChecksheet = data[selectedChecksheetIndex - 1]
            if (previousChecksheet?.state === 1) {
                signatures.push(
                    <div className='signoff'>
                        Rework Sign Off:
                        {data[selectedChecksheetIndex]?.finalSignOff.reworkSignature !== null ? (
                            <CheckCircleOutline sx={{ color: 'green' }} />
                        ) : (<CircleOutlined />)}
                    </div>)
            } else if (previousChecksheet?.state === 2) {
                signatures.push(
                    <div className='signoff'>
                        Hospital Sign Off:
                        {data[selectedChecksheetIndex]?.finalSignOff.hospitalSignature !== null ? (
                            <CheckCircleOutline sx={{ color: 'green' }} />
                        ) : (<CircleOutlined />)}

                    </div>
                )
            }
        }

        const root = document.documentElement;
        let signoffWidth = 100 / signatures.length
        root.style.setProperty('--signoff-width', `${signoffWidth}%`)

        return (
            <div className='signoff-container'>
                <h2>Final Sign Off</h2>
                <div className='signoffs'>
                    {signatures.map((sign, index) => {
                        return (
                            <div key={index} className='signoff-box'>
                                {sign}
                            </div>
                        )
                    })}
                </div>
            </div >
        )
    }

    async function generateButtonClickedLocal(e) {
        e.stopPropagation()
        generateButtonClicked(rowData._id)
    }

    function getColumnComponent(key, value) { //get special column componenet
        let type = key // [progress, actions]
        if (key === "actions") type = type + currentMenuOption
        switch (type) {
            case "lastPermission": //actions for Resources
                return (
                    <>
                        <div className='permission-box'>
                            <div className='color-indicator' style={{ backgroundColor: value === true ? "green" : "red" }}>
                            </div>
                            <p style={{ textAlign: 'center' }}>
                                {value === true ? "Yes" : "No"}
                            </p>
                        </div>
                        {<div id={'list-row-details-container' + indexID} className='list-row-details-container' style={{ display: 'none' }}>
                            <button onClick={(e) => editButtonClickedLocal(e)}>
                                <EditIcon sx={{ fontSize: '18px', color: 'black' }} />
                            </button>
                            <button onClick={(e) => deleteButtonClicked(e)}>
                                <DeleteOutlinedIcon sx={{ fontSize: '18px', color: 'black' }} />
                            </button>
                        </div>}
                    </>
                )
                break;
            case "permissions":
                return (
                    <div className='permission-box'>
                        <div className='color-indicator' style={{ backgroundColor: value === true ? "green" : "red" }}>
                        </div>
                        <p style={{ textAlign: 'center' }}>
                            {value === true ? "Yes" : "No"}
                        </p>
                    </div>
                )
                break;
            case "progress": //actions for Active jobs
                return (
                    <>
                        <p style={{ flex: 2, textAlign: 'center' }}>
                            {value + "%"}
                        </p>
                        <div id={'list-row-details-container' + indexID} className='list-row-details-container' style={{ display: 'none' }}>
                            <button onClick={(e) => generateButtonClickedLocal(e)}>
                                <SimCardDownloadOutlinedIcon sx={{ fontSize: '18px', color: 'black' }} />
                            </button>
                            <button onClick={(e) => editButtonClickedLocal(e)}>
                                <EditIcon sx={{ fontSize: '18px', color: 'black' }} />
                            </button>
                            <button onClick={(e) => deleteButtonClicked(e)}>
                                <DeleteOutlinedIcon sx={{ fontSize: '18px', color: 'black' }} />
                            </button>
                        </div>
                    </>)
                break;
            case "actions1": //actions for Rework jobs
                return (
                    <div className='action-box' onClick={(e) => e.stopPropagation()}>
                        <button onClick={() => { startFileDownload("/api/jobs/" + rowData._id + "/downloadMedia") }}><CameraIcon /></button>
                        <button onClick={() => { startFileDownload("/api/jobs/" + rowData._id + "/checksheet") }}
                            style={{ borderLeft: "1px solid black", borderRight: "1px solid black" }}><DownloadIcon /></button>
                        <button onClick={() => openAddressIssuesModal()}><BuildOutlinedIcon /></button>
                    </div>)
                break;
            case "actions2": //actions for Completed jobs
                return (
                    <div className='action-box' onClick={(e) => e.stopPropagation()}>
                        <button onClick={() => { startFileDownload("/api/jobs/" + rowData._id + "/downloadMedia") }}><CameraIcon /></button>
                        <button onClick={() => { startFileDownload("/api/jobs/" + rowData._id + "/checksheet") }}
                            style={{ borderLeft: "1px solid black", borderRight: "1px solid black" }}><DownloadIcon /></button>
                        <button onClick={() => [setSelectListItem(rowData, true), toggleModal(true, 8)]}><ForwardToInboxIcon /></button>
                    </div>)
                break;
            default:
                return (
                    <p style={{ flex: 1, textAlign: 'center' }}>
                        {value}
                    </p>)
                break;
        }
    }

    function pictureIconClicked(e, check) {
        e.stopPropagation()
        const id = check._id
        if (id === pictureIconCheckIdSelected) {
            setpictureIconCheckIdSelected(null)
        } else {
            if (check?.qcPicture?.originalUrl && check?.picture?.originalUrl) {
                setpictureIconCheckIdSelected(id)
            } else {
                window.open(check.picture.originalUrl)
            }
        }

    }

    return (
        <div className='list-row-expandable' >
            <div className='list-row-text' style={{ backgroundColor: bgColor }}
                id={'expanded-row-' + expandState}>
                {keyArray.map((key, index) => { //record item
                    let value = ""
                    let keyValue = key //change when key is array
                    if (Array.isArray(key)) {
                        keyValue = key[0]
                        if (rowData[key[0]] !== null) {
                            value = rowData[key[0]]?.[key[1]]
                        }
                        if (key[1] === "hospital") {
                            keyValue = "lastPermission"
                        }
                    } else if ((key === "createdAt") || (key === "endDate") || (key === "updatedAt")) {
                        value = DateTime.fromISO(rowData[key]).toFormat("dd/MM/yyyy");
                        if (value === "Invalid DateTime") {
                            value = "TBA"
                        }
                    } else {
                        value = rowData[key]
                    }
                    return (
                        <div key={index} onClick={() => setSelectListItem(rowData)} className='row-item'
                            style={{ width: 'calc(100% / ' + numColumns + ')' }}>
                            {
                                getColumnComponent(keyValue, value)
                            }
                        </div>)
                })}
            </div>
            <div className={'list-row-body list-body-' + (expandState)} >
                <div className='list-row-tabs' style={expandState === 'open' ? { display: 'flex' } : { display: 'none' }}>
                    {data?.map((checksheet, index) => {
                        return (
                            <div key={index} className={`list-row-tab-outer ${selectedChecksheetIndex === index ? 'active' : 'inactive'}`} onClick={() => setSelectedChecksheetIndex(index)}>
                                <div className={`list-row-tab-inner`} >
                                    <p>{checksheet.folderName}</p>
                                </div>
                            </div>
                        )
                    }
                    )}
                </div>
                {isLoading ?
                    (<div className='list-row-loader'>
                        <Bars
                            class='loading-icon'
                            className='loader-icon'
                            height="40"
                            width="100"
                            color="var(--secondary-color)"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>) :
                    (data?.[selectedChecksheetIndex]?.sections?.map((section, sectionIndex) => {
                        return (
                            <div key={sectionIndex} className={'list-body-sub-section'} style={{ paddingBottom: '30px', zIndex: 0 }}>
                                <h2>{section.title}</h2>
                                <div className='check-table'>
                                    <div className='check-table-fields' style={{}}>
                                        <p style={{ flex: 'calc(19.5% - 21px)', fontWeight: '800', fontSize: '16px' }}>Title</p>
                                        <p style={{ flex: 'calc(11.5% - 21px)', fontWeight: '800', fontSize: '16px' }}>Supervisor</p>
                                        <p style={{ flex: 'calc(11.5% - 21px)', fontWeight: '800', fontSize: '16px' }}>Supervisor Status</p>
                                        <p style={{ flex: 'calc(11.5% - 21px)', fontWeight: '800', fontSize: '16px' }}>QC</p>
                                        <p style={{ flex: 'calc(11.5% - 21px)', fontWeight: '800', fontSize: '16px' }}>QC Status</p>
                                        <p style={{ flex: 'calc(11.5% - 21px)', fontWeight: '800', fontSize: '16px' }}>Status</p>
                                        <p style={{ flex: 'calc(11.5% - 21px)', fontWeight: '800', fontSize: '16px' }}>Start Date</p>
                                        <p style={{ flex: 'calc(11.5% - 20px)', fontWeight: '800', fontSize: '16px' }}>End Date</p>
                                    </div>
                                    {section.checks.map((check, index) => {
                                        let selected = false
                                        if (selectedCheckIndexes.sectionIndex === sectionIndex && selectedCheckIndexes.checkIndex === index) {
                                            selected = true
                                        }
                                        const pictureSelected = pictureIconCheckIdSelected === check._id
                                        return (
                                            <div key={index} onClick={() => selectCheck(sectionIndex, index)} className='list-body-sub-section-sub-check'
                                                style={{
                                                    backgroundColor: selected ? "#236d71ba" : (check?.type === 0 ? `${statusColor[check?.status]}` : `${holdStatusColor[check?.status]}`),
                                                    color: selected ? 'white' : 'black'
                                                }}>
                                                {check?.type === 0 ? (
                                                    <>
                                                        <p style={{ flex: 'calc(19.5% - 21px)' }}>{check?.title}</p>
                                                        <p style={{ flex: 'calc(11.5% - 21px)' }}>{check?.supervisor?.name === undefined ? 'TBA' : check?.supervisor?.name}</p>
                                                        <p style={{ flex: 'calc(11.5% - 21px)' }}>{check?.supervisorState === null ? 'TBA' : superStatusText[check?.supervisorState]}</p>
                                                        <p style={{ flex: 'calc(11.5% - 21px)' }}>{check?.qc?.name === undefined ? 'TBA' : check?.qc?.name}</p>
                                                        <p style={{ flex: 'calc(11.5% - 21px)' }}>{check?.qcState === null ? 'TBA' : qcStatusText[check?.qcState]}</p>
                                                        <p style={{ flex: 'calc(11.5% - 21px)' }}>{check?.status === null ? 'TBA' : statusText[check?.status]}</p>
                                                        <p style={{ flex: 'calc(11.5% - 21px)' }}>{check?.startDate === null ? 'TBA' : DateTime.fromISO(check?.startDate).toFormat("dd/MM HH:mm")}</p>
                                                        <div className='final-column-container' style={{ flex: 'calc(11.5% - 20px)'}}>
                                                            {check?.endDate === null ? 'TBA' : DateTime.fromISO(check?.endDate).toFormat("dd/MM HH:mm")}
                                                            {
                                                                pictureSelected ? (
                                                                    <div id={pictureSelected ? 'media-open' : 'media-close'} className='media-icon-container'>
                                                                        <div onClick={(e) => [e.stopPropagation(), window.open(check.picture.originalUrl)]} className='media-icon-button'>Sup</div>
                                                                        <CloseIcon onClick={(event) => pictureIconClicked(event, check)} className='close-icon' />
                                                                        <div onClick={(e) => [e.stopPropagation(), window.open(check.qcPicture.originalUrl)]} className='media-icon-button'>QC</div>
                                                                    </div>
                                                                ) : (
                                                                    <div className='media-icon-button-container'>
                                                                        {(check.picture !== null) && <PermMediaIcon onClick={(event) => pictureIconClicked(event, check)} className='check-media-button' />}
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <p style={{ flex: 'calc(21.8333333333% - 21px)' }}><span style={{ fontWeight: '700', marginRight: '3px' }}>QC: </span>{check?.qc?.name === undefined ? 'TBA' : check?.qc?.name}</p>
                                                        <p style={{ flex: 'calc(21.8333333333% - 21px)' }}><span style={{ fontWeight: '700', marginRight: '3px' }}>Supervisor: </span> {check?.supervisor?.name === undefined ? 'TBA' : check?.supervisor?.name}</p>
                                                        <p style={{ flex: 'calc(21.8333333333% - 21px)' }}> <span style={{ fontWeight: '700', marginRight: '3px' }}>Wirer: </span>{check?.wirer?.name === undefined ? 'TBA' : check?.wirer?.name}</p>
                                                        <p style={{ flex: 'calc(11.5% - 21px)' }}>{check?.status === null ? 'TBA' : holdStatusText[check?.status]}</p>
                                                        <p style={{ flex: 'calc(11.5% - 21px)' }}>{check?.startDate === null ? 'TBA' : DateTime.fromISO(check?.startDate).toFormat("dd/MM HH:mm")}</p>
                                                        <p style={{ flex: 'calc(11.5% - 20px)', borderRight: '0px' }}>{check?.endDate === null ? 'TBA' : DateTime.fromISO(check?.endDate).toFormat("dd/MM HH:mm")}</p>
                                                    </>
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    }))
                }
                <div className="final-signoffs" style={expandState === 'open' && isLoading === false ? { display: 'flex' } : { display: 'none' }}>
                    {displayFinalSignOff(data, selectedChecksheetIndex)}
                </div>
            </div>
            <div className='list-row-toggle-button' onClick={() => changeExpandState()} style={{ zIndex: 2 }}>
                {expandState === "open" ?
                    <ArrowDropUpIcon fontSize='inherit' sx={{ color: 'white' }} /> :
                    <ArrowDropDownIcon fontSize='inherit' sx={{ color: 'white' }} />
                }
            </div>
        </div >
    )
}
