import React, { useState, useEffect } from 'react'
import './styling/modals.css'
import CloseIcon from '@mui/icons-material/Close';

export default function CheckSheetAddEditModal({ show, modalInEditMode, toggleModal, modalOnSubmit, selectedListItemData, toggleAlert }) { //from = Add / Edit

    //form input fields
    const [name, setname] = useState("")
    const [items, setitems] = useState([{ title: '', comment: '', mandatory: false }])

    useEffect(() => {
        if (!show) return
        if (modalInEditMode) {
            populateInputFields()
        }
    }, [show])

    function populateInputFields() { //on edit
        setname(selectedListItemData.name)
        setitems(selectedListItemData.items)
    }

    function clearInputFields() {
        setname("")
        setitems([{ title: '', comment: '', mandatory: false }])
    }

    function handleClose() {
        clearInputFields()
        //close method
        toggleModal(false, 3)
    }

    function onChange(event, index) {
        if (event.target.name === "name") {
            setname(event.target.value)
            return
        }
        const copyitems = JSON.parse(JSON.stringify(items))
        switch (event.target.name) {
            case "item":
                copyitems[index].title = event.target.value
                break;
            case "comment":
                copyitems[index].comment = event.target.value
                break;
            case "mandatory":
                copyitems[index].mandatory = event.target.checked
                break;
            default:
                break;
        }
        setitems(copyitems)
    }

    function addCheck() {
        setitems(arr => [...arr, { title: '', comment: '', mandatory: false }])
    }
    function removeCheck(checkIndex) {
        setitems(arr => arr.filter((check, index) => index !== checkIndex))
    }

    function onSubmit() {
        if (!validateInput()) return //if input not valid, return
        const localData = {
            name,
            items
        }

        modalOnSubmit(localData, true) //true to execute api call
        handleClose()
    }

    function validateInput() {
        if ((name === "")) {
            toggleAlert(true, 1, "Please make sure all required fields are filled in")
            return false
        }

        if (items.length < 1) {
            toggleAlert(true, 1, "Check Sheet requires at least one Item")
            return false
        } else {
            for (let index = 0; index < items.length; index++) {
                const item = items[index];
                if (item.title === "") {
                    toggleAlert(true, 1, "Please provide a name for each Item")
                    return false
                }
                if (item.comment === "") {
                    toggleAlert(true, 1, "Please provide a comment for each Item")
                    return false
                }
            }
        }
        return true
    }

    return (
        <>
            {show ? (
                <div className='backdrop' onClick={handleClose}>
                    <div className='modal-body ' onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header" >
                            <h3 className='modal-title' >{(modalInEditMode ? "Edit" : "Add") + " Check Sheet"}</h3>
                        </div>
                        {/* body container */}
                        <form className='input-form'  >
                            <div className="form-group-vertical">
                                <label htmlFor="name" className="col-form-label">Sheet Name:</label>
                                <input value={name} type="text" autoComplete='off' placeholder='' className="modal-input" name="name" id='name' onChange={onChange} />
                            </div>

                            <div className='checks-header' style={{ width: 'calc(100% - 18px' }}>
                                <h4 style={{ flex: 1, borderRight: '1px solid black' }}>Req.</h4>
                                <h4 style={{ flex: 4.9, borderRight: '1px solid black' }}>Item</h4>
                                <h4 style={{ flex: 8 }}>Comment</h4>
                            </div>
                            <div className='checks-scroll-container' >
                                {items.map((check, index) => {
                                    return (
                                        <div className='check-item' key={index} style={{ width: 'calc(100%)' }}>
                                            <div className='checkbox-container' style={{ flex: 1.4 }} >
                                                <input checked={check.mandatory} type="checkbox" name='mandatory' onChange={(e) => onChange(e, index)} />
                                            </div>
                                            <input autoComplete='off' value={check.title} style={{ flex: 5 }} name='item' onChange={(e) => onChange(e, index)} />
                                            <textarea value={check.comment} style={{ flex: 8 }} name='comment' onChange={(e) => onChange(e, index)} />
                                            <div className='remove-check-button' onClick={() => removeCheck(index)}>
                                                <CloseIcon sx={{ color: 'black', fontSize: '18px' }} />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </form>
                        <button className='add-button' onClick={() => addCheck()}>ADD CHECK</button>
                        <div className='submit-container'>
                            <button className='submit-btn back-btn' onClick={() => { handleClose() }}
                            >{"Cancel"}</button>
                            <button className='submit-btn' onClick={() => { onSubmit() }} >{"Submit"}</button>
                        </div>
                    </div>
                </div >)
                : <></>}
        </>)
}
