import React, { useRef } from 'react'
import './styling/GridListContainer.css'
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import PersonIcon from '@mui/icons-material/Person';
import TaskIcon from '@mui/icons-material/Task';

function GridBox({ setSelectListItem, indexID, data, currentMenuOption, toggleModal, editButtonClicked }) {

    //MoreDetails functions
    const showMoreDetails = useRef(false)
    function showDetails(event) {
        let main = document.getElementById('grid-box-container' + indexID)
        main.style.boxShadow = "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px";
        let container = document.getElementById('grid-box-details-container' + indexID)
        // container.style.display = "flex";
        container.style.visibility = "visible";
        showMoreDetails.current = true;
    }
    function hideDetails() {
        if (!showMoreDetails.current) return
        let main = document.getElementById('grid-box-container' + indexID)
        main.style.boxShadow = "0 0 0 0";
        let container = document.getElementById('grid-box-details-container' + indexID)
        container.style.visibility = "hidden";
        showMoreDetails.current = false;
    }


    function deleteButtonClicked() {
        toggleModal(true, 7)
    }

    return (
        <div className='grid-box-container' id={'grid-box-container' + indexID}
            onMouseOver={() => showDetails()} onMouseOut={() => hideDetails()} onClick={() => setSelectListItem(data)}>
            <div className='grid-box-details-container-parent'>
                <div id={'grid-box-details-container' + indexID} className='grid-box-details-container' style={{ display: 'flex', visibility: 'hidden' }}>
                    <button onClick={() => editButtonClicked()}>
                        {currentMenuOption === 5 ?
                            <LaunchOutlinedIcon sx={{ fontSize: '18px', color: 'black' }} />
                            : <EditIcon sx={{ fontSize: '18px', color: 'black' }} />
                        }
                    </button>
                    <button onClick={() => deleteButtonClicked()}>
                        <DeleteOutlinedIcon sx={{ fontSize: '18px', color: 'black' }} />
                    </button>
                </div>
            </div>
            {(currentMenuOption === 5 || currentMenuOption === 6) ?
                <PersonIcon sx={{ color: 'var(--accent-color)' }} />
                :
                <TaskIcon sx={{ color: 'var(--accent-color)' }} />
            }
            <h4 style={{ margin: 0, padding: '10px 20px', textAlign: 'center' }}>
                {data.name}
            </h4>
            <div className='grid-box-bottom-container' style={{ height: '30px', width: '100%' }}>

            </div>
        </div >
    )
}

export default GridBox