import React, { useState } from 'react'
import { ThreeDots } from 'react-loader-spinner'
import { forgotPassword } from '../api/sqc/UserAPI'
import './styling/ForgotPassword.css'


export default function ForgotPassword() {
    const [email, setemail] = useState("")
    const [isLoading, setisLoading] = useState(false)

    // Send confirmation code to user's email or phone number
    async function forgotPasswordSubmit() {
        if (email === "") {
            alert("Please enter a valid email address")
            return
        }
        setisLoading(true)
        try {
            const props = { email }
            const data = await forgotPassword(props)
            // console.log(data);
            alert(data.message)

        } catch (err) {
            alert("Internal Error: " + err)
        }
        setisLoading(false)
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            forgotPasswordSubmit()
        }
    }


    return (

        <div className="forgot-password-container">
            <div className="left-section">
                <div className="forgot-password-text">
                    <h1>Forgot Password</h1>
                    <h3>
                        Enter your email address and we'll send you a link to reset your
                        password.
                    </h3>
                </div>
            </div>

            <div className="right-section">
                <div className="forgot-password-form">
                    <input
                        onKeyDown={(e) => handleKeyPress(e)}
                        type="text"
                        placeholder="Email Address"
                        className="forgot-email"
                        onChange={e => setemail(e.target.value)}

                    />
                    <button onClick={() => forgotPasswordSubmit()} className="reset-button">
                        {isLoading ?
                            <ThreeDots
                                visible={true}
                                height="42"
                                width="80"
                                color="#ffffff"
                                radius="9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                            :
                            "Send Reset Link"
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}
