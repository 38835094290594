import { fqcConfig } from "../ApiConfig"

export async function getFQCMedia(queryParams) {
    return fetch(fqcConfig.url + '/media' + queryParams, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        }
    }).then(res => res.json())
        .then(data => {
            return data.data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}