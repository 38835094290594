import React, { useState, useEffect, useRef } from 'react'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import testimg from '../media/ColcabLogoNoText.png'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import CachedIcon from '@mui/icons-material/Cached';
import { getJobUnitsById } from '../api/sqc/JobsAPI';
import { Oval } from 'react-loader-spinner';

export default function AddressIssuesModal({ show, toggleModal, selectedListItemData, processAddressedIssues, getMediaForDisplay }) {
    const [slideToggle, setslideToggle] = useState("") //id for slide animation
    const currentIssueNumber = useRef(0)
    const [issues, setissues] = useState([{ unitGroupName: '', unitSerialNumber: '', issueAddressed: false, correction: "", displayMedia: "" }]) //data from DB
    const [selectedIssue, setselectedIssue] = useState({}) //props of current issue in focus
    const [issueSubmittedStateArray, setissueSubmittedStateArray] = useState([])
    const [initDone, setinitDone] = useState(false) //update issues array (skip first init)
    const [showLargeImage, setshowLargeImage] = useState(false)
    const [unitDataLoading, setunitDataLoading] = useState(false);
    const [imgLoading, setimgLoading] = useState(false);

    useEffect(() => {
        if (show) {
            formatAndGroupUnitData()
        }
    }, [show])

    useEffect(() => { //update issues array (skip first init)
        if (initDone === false) return
        if (Object.keys(selectedIssue).length < 1) return
        const copyIssues = JSON.parse(JSON.stringify(issues))
        copyIssues[currentIssueNumber.current] = selectedIssue
        setissues(copyIssues)
    }, [selectedIssue])

    async function formatAndGroupUnitData() {
        //group by checksheetTemplate 
        // const unitsData = selectedListItemData.units
        setunitDataLoading(true)
        const unitsData = await getJobUnitsById(selectedListItemData._id)
        let unitGroups = {} //{63f746cce1dd546f53adb91c: { name: , units:[] }}
        unitsData.forEach(unit => {
            if (!(unit.checksheetTemplate in unitGroups)) {
                unitGroups[unit.checksheetTemplate] = { name: unit.name, units: [] }
            }
            unitGroups[unit.checksheetTemplate].units.push(unit)
        });
        //format unitGroups to array of issues 
        const checkIssues = []
        const submittedArr = []
        Object.keys(unitGroups).forEach(unitGroupKey => {
            const unitGroup = unitGroups[unitGroupKey]
            unitGroup.units.forEach(unit => {

                if (unit.isFound === false) {
                    checkIssues.push({
                        title: "Unit not located.", comment: "", issueAddressed: false, displayMedia: '',
                        unitGroupName: unitGroup.name, unitId: unit._id, unitSerialNumber: unit.serialNumber,
                    })
                    submittedArr.push(false)
                } else {
                    unit.checkItems.forEach(checkItem => {
                        if (checkItem.passed === false) {
                            //add issue to list
                            checkIssues.push({
                                ...checkItem, unitGroupName: unitGroup.name, unitId: unit._id, unitSerialNumber: unit.serialNumber,
                                issueAddressed: false, displayMedia: ''
                            })
                            submittedArr.push(false)
                            // checkIssues.push({ ...checkItem, unitName: unit.name, unitId: unit._id, issueAddressed: false })
                        }
                    });
                }



            });
        });
        setissues(checkIssues)
        setissueSubmittedStateArray(submittedArr)
        if (checkIssues.length > 0) {
            setselectedIssue(checkIssues[currentIssueNumber.current]) //set to 0 ?
        }
        setinitDone(true)
        setunitDataLoading(false)
    }

    function handleClose() {
        setshowLargeImage(false)
        clearInputFields()
        //close method
        toggleModal(false, 6)
    }
    function clearInputFields() {
        setslideToggle("")
        currentIssueNumber.current = 0
        setissues([{ unitGroupName: '', unitSerialNumber: '', issueAddressed: false, correction: "", displayMedia: "" }])
        setselectedIssue({})
        setinitDone(false)
    }

    function executeSlide(slideNext) {
        let timer = null
        if (slideNext) {
            if (currentIssueNumber.current === (issues.length - 1)) return
            setslideToggle("left")
            currentIssueNumber.current = currentIssueNumber.current + 1
        } else {
            if (currentIssueNumber.current === 0) return
            setslideToggle("right")
            currentIssueNumber.current = currentIssueNumber.current - 1
        }
        clearTimeout(timer)
        timer = setTimeout(() => { //wait for aniimation to finish
            setslideToggle("")
            setselectedIssue(issues[currentIssueNumber.current])
        }, 1000);
    }

    function onChange(event) {
        const copySelectedIssue = JSON.parse(JSON.stringify(selectedIssue))
        switch (event.target.name) {
            case "correction":
                copySelectedIssue.correction = event.target.value
                setselectedIssue(copySelectedIssue)
                break;
            default:
                break;
        }
    }

    function issueAddressedButtonClick() {
        const copySelectedIssue = JSON.parse(JSON.stringify(selectedIssue))
        copySelectedIssue.issueAddressed = !copySelectedIssue.issueAddressed
        setselectedIssue({ ...copySelectedIssue })
    }

    function onSubmit() {
        let isLastIssue = false
        if (selectedIssue.issueAddressed) {
            if (currentIssueNumber.current === (issues.length - 1)) {
                isLastIssue = true
                //submit finish
                handleClose()
            } else { //issue submit
                const copyissueSubmittedStateArray = JSON.parse(JSON.stringify(issueSubmittedStateArray))
                copyissueSubmittedStateArray[currentIssueNumber.current] = true
                setissueSubmittedStateArray(copyissueSubmittedStateArray)
                executeSlide(true)
            }
            processAddressedIssues(selectedIssue, isLastIssue)
        }
    }


    async function fetchImage() {
        setimgLoading(true)
        const img = await getMediaForDisplay(selectedIssue)
        const copySelectedIssue = JSON.parse(JSON.stringify(selectedIssue))
        copySelectedIssue.displayMedia = img.url
        setselectedIssue(copySelectedIssue)
        setimgLoading(false)
    }

    function getIssueStateColors(type) {
        //type {border, color, button}
        if (selectedIssue === undefined) return
        const red = '#db7b7b'
        const green = '#4bcc52'
        const buttonBlue = '#153154ca'
        const switchKey = type + "-" + selectedIssue.issueAddressed
        switch (switchKey) {
            case "button-true":
                return green
                break;
            case "button-false":
                return buttonBlue
                break;
            case "color-true":
                return green
                break;
            case "color-false":
                return red
                break;
            case "border-true":
                return "2px solid " + green
                break;
            case "border-false":
                return "2px solid " + red
                break;
            default:
                break;
        }
    }
    function getNavBtnState(button) {
        if (button === "left") {//left button
            if (currentIssueNumber.current > 0) {
                return (
                    <div className='nav-btn' onClick={() => executeSlide(false)}>
                        <KeyboardArrowLeftIcon />
                    </div>)
            }
        } else { //right button
            if (selectedIssue.issueAddressed && (issueSubmittedStateArray[currentIssueNumber.current])) {
                return (
                    <div className='nav-btn' onClick={() => executeSlide(true)} >
                        <KeyboardArrowRightIcon />
                    </div>)
            }
        }
        return (
            <div className='nav-btn' style={{ pointerEvents: 'none', backgroundColor: 'transparent' }}  >
            </div>)

    }

    return (
        <>
            {show ? (
                <div className='backdrop' onClick={handleClose}>
                    <div className='modal-body' onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header" >
                            <h3 className='modal-title' >Address Issues</h3>
                        </div>
                        <div className='issue-nav-container' onClick={() => setshowLargeImage(false)}>
                            {getNavBtnState("left")}
                            <h4> {(currentIssueNumber.current + 1) + " / " + issues.length}</h4>
                            {getNavBtnState("right")}
                        </div>
                        {/* body container */}
                        {showLargeImage ?
                            <img onClick={() => setshowLargeImage(false)}
                                style={{ backgroundColor: 'transparent', margin: '3px', objectFit: 'contain', height: '400px' }} src={selectedIssue.displayMedia}></img>
                            :
                            <div className='sliding-container' id={"slide-" + slideToggle}>
                                <div className='full-issue-body'>
                                    <div className='address-issue-body' style={{ border: getIssueStateColors("border") }}>
                                        <div className='issue-header-container' style={{ backgroundColor: getIssueStateColors("color") }}>
                                            <h5 style={{ flex: 3, borderRight: '1px solid white' }}>{selectedIssue.unitGroupName}</h5>
                                            <h5 style={{ flex: 7 }}>{selectedIssue.unitSerialNumber}</h5>
                                        </div>
                                        <div className='issue-details-container'>
                                            <div className="issue-details-title-container" >
                                                <textarea style={{
                                                    border: getIssueStateColors("border"), pointerEvents: 'none',
                                                    resize: 'none', padding: '3px', fontWeight: 500
                                                }}
                                                    value={(selectedIssue.title) ? selectedIssue.title : ""} onChange={() => { }} name='title' contentEditable={false} />
                                            </div>
                                            <div className="issue-details-body-container" >
                                                <div className="issue-details-body-sub-container" style={{ flex: 6 }}>
                                                    <label htmlFor="correction" className="col-form-label">Comment:</label>
                                                    <textarea style={{ border: getIssueStateColors("border"), pointerEvents: 'none', }}
                                                        value={(selectedIssue.comment) ? selectedIssue.comment : ""} onChange={() => { }} name='comment' contentEditable={false} />
                                                </div>
                                                <div className="issue-details-body-sub-container" style={{ flex: 2, marginLeft: 20 }}>
                                                    <label htmlFor="correction" className="col-form-label" >Media:</label>
                                                    <div className='media-container' style={{ border: getIssueStateColors("border") }} onClick={() => fetchImage()}>
                                                        {selectedIssue.uploadedMedia ?
                                                            <>
                                                                {selectedIssue.displayMedia === "" ?
                                                                    <>  {imgLoading ? <Oval
                                                                        visible={true}
                                                                        height="90"
                                                                        width="42"
                                                                        color="var(--accent-color)"
                                                                        secondaryColor="#cad6ed"
                                                                        ariaLabel="oval-loading"
                                                                        wrapperStyle={{}}
                                                                        wrapperClass=""
                                                                    /> :
                                                                        <div className='image-placeholder' >
                                                                            <CachedIcon sx={{ color: '#dadada' }} />
                                                                        </div>}
                                                                    </>
                                                                    :
                                                                    <img onClick={() => setshowLargeImage(true)}
                                                                        style={{ height: '90px', backgroundColor: 'transparent', margin: '3px' }} src={selectedIssue.displayMedia}></img>
                                                                }
                                                            </> :
                                                            <div className='image-placeholder' >
                                                                <p style={{ textAlign: 'center', color: '#dadada' }}>No Media Available</p>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group-vertical" style={{ margin: '10px 20px' }}>
                                        <label htmlFor="correction" className="col-form-label">Correction:</label>
                                        <textarea value={(selectedIssue.correction) ? selectedIssue.correction : ""} onChange={onChange} name='correction'
                                            style={{ backgroundColor: 'white', border: '1px solid #009c9e', borderRadius: '3px', height: '60px' }} />
                                        <button className='addressed-button' style={{ backgroundColor: getIssueStateColors("button") }} onClick={() => issueAddressedButtonClick()}>
                                            {selectedIssue.issueAddressed ? <DoneOutlinedIcon /> : "Issue Addressed"}
                                        </button>
                                    </div>
                                </div>
                                {/* copy of body for sliding animation */}
                                <div className='full-issue-body'>
                                    <div className='address-issue-body' >
                                        <div className='issue-header-container' >
                                            <h5 style={{ flex: 3, borderRight: '1px solid white' }}>{""}</h5>
                                            <h5 style={{ flex: 7 }}>{""}</h5>
                                        </div>
                                        <div className='issue-details-container'>
                                            <div className="issue-details-title-container" >
                                                <textarea style={{
                                                    pointerEvents: 'none',
                                                    resize: 'none', padding: '3px', fontWeight: 500
                                                }} value={""} onChange={() => { }} name='title' contentEditable={false} />
                                            </div>
                                            <div className="issue-details-body-container" >
                                                <div className="issue-details-body-sub-container" style={{ flex: 6 }}>
                                                    <label htmlFor="correction" className="col-form-label">Comment:</label>
                                                    <textarea style={{ pointerEvents: 'none', }}
                                                        value={""} onChange={() => { }} name='comment' contentEditable={false} />
                                                </div>
                                                <div className="issue-details-body-sub-container" style={{ flex: 2, marginLeft: 20 }}>
                                                    <label htmlFor="correction" className="col-form-label" >Media:</label>
                                                    <div className='media-container'>
                                                        <img style={{ height: '90px', backgroundColor: 'transparent', margin: '3px' }} src={testimg}></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group-vertical" style={{ margin: '10px 20px' }}>
                                        <label htmlFor="correction" className="col-form-label">Correction:</label>
                                        <textarea value={""} onChange={onChange} name='correction'
                                            style={{ backgroundColor: 'white', border: '1px solid #009c9e', borderRadius: '3px', height: '60px' }} />
                                        <button className='addressed-button' >
                                            {"Issue Addressed"}
                                        </button>
                                    </div>
                                </div>
                            </div>}
                        <div className='submit-container'>
                            <button className='submit-btn back-btn' onClick={() => { handleClose() }}
                            >{"Cancel"}</button>
                            <button className='submit-btn' style={{ backgroundColor: selectedIssue.issueAddressed ? '#009c9e' : '#009c9e33' }}
                                onClick={() => onSubmit()} >{(currentIssueNumber.current + 1) === issues.length ? "Finish" : "Submit"}</button>
                        </div>
                    </div>
                </div >)
                : <></>}

        </>)
}
