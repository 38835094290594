import React, { useState, useEffect } from 'react'
import './styling/modals.css'
import CloseIcon from '@mui/icons-material/Close';

function CustomerAddEditModal({ show, modalInEditMode, toggleModal, modalOnSubmit, selectedListItemData, toggleAlert }) {

    const [name, setname] = useState("")
    const [email, setemail] = useState("")
    const [contactNumber, setcontactNumber] = useState("")
    const [contactPeople, setcontactPeople] = useState([])

    useEffect(() => {
        if (!show) return
        if (modalInEditMode) {
            populateInputFields()
        }
    }, [show])

    function populateInputFields() { //on edit
        setname(selectedListItemData.name)
        setemail(selectedListItemData.email)
        setcontactNumber(selectedListItemData.contactNumber)
        setcontactPeople(selectedListItemData.contactPeople)
    }

    function clearInputFields() {
        setname("")
        setemail("")
        setcontactNumber("")
        setcontactPeople([])
    }

    function handleClose() {
        //clear local data
        clearInputFields()
        //close method
        toggleModal(false, 5)
    }

    function onChange(event, index) {
        if (event.target.name.includes("contact-")) {
            const copyOfContactPeople = JSON.parse(JSON.stringify(contactPeople))
            switch (event.target.name) {
                case "contact-name":
                    copyOfContactPeople[index].name = event.target.value
                    break;
                case "contact-email":
                    copyOfContactPeople[index].email = event.target.value
                    break;
                case "contact-contactNumber":
                    copyOfContactPeople[index].contactNumber = event.target.value
                    break;
                default:
                    break;
            }
            setcontactPeople(copyOfContactPeople)
        } else {
            switch (event.target.name) {
                case "name":
                    setname(event.target.value)
                    break;
                case "email":
                    setemail(event.target.value)
                    break;
                case "contactNumber":
                    setcontactNumber(event.target.value)
                    break;
                default:
                    break;
            }
        }
    }

    function addcontactPeople() {
        setcontactPeople(arr => [...arr, { name: '', email: '', contactNumber: '' }])
    }
    function removecontactPeople(contactIndex) {
        setcontactPeople(arr => arr.filter((contact, index) => index !== contactIndex))
    }

    async function onSubmit() {
        if (!validateInput()) return //if input not valid, return
        const localData = {
            name,
            email,
            contactNumber,
            contactPeople
        }
        const submissionResponse = await modalOnSubmit(localData, true) //true to execute api call
        if (submissionResponse?.success !== false) {
            handleClose()
        }
    }

    function validateInput() {
        if ((name === "")) {
            toggleAlert(true, 1, "Please make sure all required fields are filled in")
            return false
        }
        if ((email !== "") && (!validateEmail(email))) {
            toggleAlert(true, 1, "Please provide a valid email address")
            return false
        }
        if (contactPeople.length > 0) {
            for (let index = 0; index < contactPeople.length; index++) {
                const person = contactPeople[index];
                if (person.name === "") {
                    toggleAlert(true, 1, "Please provide a name for each contact")
                    return false
                }
                if ((person.email !== "") && (!validateEmail(person.email))) {
                    toggleAlert(true, 1, "Please provide a valid email address")
                    return false
                }
            }
        } else {
            toggleAlert(true, 1, "Customer needs at least one Contact")
            return false
        }
        return true
    }

    function validateEmail(emailInput) {
        var reg = /^\S+@\S+\.\S+$/;
        return reg.test(emailInput);
    }

    return (
        <>
            {show ? (
                <div className='backdrop' onClick={handleClose}>
                    <div className='modal-body ' style={{ height: '374px' }} onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header" >
                            <h3 className='modal-title' >{(modalInEditMode ? "Edit" : "Add") + " Customer"}</h3>
                        </div>
                        {/* body container */}
                        <div className='for-aligning-buttons-at-bottom' style={{ flex: 1 }}>
                            <form className='input-form'   >
                                <div className="form-group-vertical">
                                    <label htmlFor="name" className="col-form-label">Customer Name</label>
                                    <input value={name} id='name' type="text" autoComplete='off' placeholder='' className="modal-input" name="name" onChange={onChange} ></input>
                                </div>
                                {/* <div className="form-group-vertical">
                                    <label htmlFor="email" className="col-form-label">Email Address<span className='optional-label'>(optional)</span></label>
                                    <input value={email} id='email' type="text" autoComplete='off' placeholder='' className="modal-input" name="email" onChange={onChange} ></input>
                                </div>
                                <div className="form-group-vertical">
                                    <label htmlFor="contactNumber" className="col-form-label">Contact Number<span className='optional-label'>(optional)</span></label>
                                    <input value={contactNumber} id='contactNumber' type="text" autoComplete='off' placeholder='' className="modal-input" name="contactNumber" onChange={onChange} ></input>
                                </div> */}
                                <h5 style={{ margin: 0 }}>Contact People:</h5>
                                <div className='checks-header'>
                                    <h4 style={{ flex: 5, borderRight: '1px solid whitesmoke', marginBottom: '1px' }}>Name</h4>
                                    <h4 style={{ flex: 5, borderRight: '1px solid whitesmoke', }}>Email</h4>
                                    <h4 style={{ flex: 5 }}>Contact no.</h4>
                                </div>
                                <div className='checks-scroll-container' style={{ maxHeight: '100px' }}>
                                    {contactPeople.map((contact, index) => {
                                        return (
                                            <div className='check-item-sub-modal' key={index}>
                                                <input autoComplete="off" value={contact.name} style={{}} name='contact-name' onChange={(e) => onChange(e, index)} />
                                                <input autoComplete="off" value={contact.email} style={{}} name='contact-email' onChange={(e) => onChange(e, index)} />
                                                <input autoComplete="off" value={contact.contactNumber} style={{}} name='contact-contactNumber' onChange={(e) => onChange(e, index)} />

                                                <div className='remove-check-button' onClick={() => removecontactPeople(index)}>
                                                    <CloseIcon sx={{ color: '#009c9e', fontSize: '18px' }} />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </form>
                            <button className='add-button' onClick={() => addcontactPeople()}>Add Contact</button>
                        </div>
                        <div className='submit-container'>
                            <button className='submit-btn back-btn' onClick={() => { handleClose() }}>{"Cancel"}</button>
                            <button className='submit-btn' onClick={() => onSubmit()} >{"Submit"}</button>
                        </div>
                    </div>
                </div >)
                : <></>}
        </>)
}

export default CustomerAddEditModal