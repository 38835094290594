import React, { useState, useRef, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

export default function CheckSheetBuilderModal2({ kittinglist, setkittinglist }) {

    function addItem() {//Add item to kitting list
        setkittinglist(arr => [...arr, { title: '' }])
    }

    function removeItem(itemIndex) { //remove item form kitting list
        setkittinglist(arr => arr.filter((item, index) => index !== itemIndex))
    }

    function updateItem(value, itemIndex) {//update kitting list item title
        let copykittinglist = [...kittinglist]
        copykittinglist[itemIndex].title = value
        setkittinglist(copykittinglist)
    }

    //drag and drop / move vars
    const [mouseIsDown, setmouseIsDown] = useState(false);
    const itemIndexDragged = useRef(null); //can be used as mouseDown aswell
    const kittingItemInitialTopValue = useRef(0); //can be used as mouseDown aswell
    const mousePositionXY = useRef({})
    const elementsPopped = useRef(0); //number of elements moved up/down

    function mouseDown(event, itemIndex) { //start move func...from SVGcontainer
        itemIndexDragged.current = itemIndex
        mousePositionXY.current = {
            x: event.clientX,
            y: event.clientY
        };
        let draggedElement = document.getElementById("kitting-item-" + itemIndex)
        const elementTop = draggedElement.getBoundingClientRect().top
        draggedElement.style.position = 'relative'
        draggedElement.style.zIndex = '999'; // Example z-index value
        draggedElement.style.boxShadow = 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
        draggedElement.style.top = elementTop; // Example z-index value
        elementsPopped.current = 0
        kittingItemInitialTopValue.current = elementTop
        setmouseIsDown(true)
    }

    function moveElement(event) { //start move func...from SVGcontainer
        if (mouseIsDown) {
            let elementContainer = document.getElementById("kitting-item-" + itemIndexDragged.current)
            const elementTopMoveDelta = event.clientY - kittingItemInitialTopValue.current - 25  //-25 to center on mouse
            elementContainer.style.top = (elementTopMoveDelta) + 'px'
            const mouseMovePopAmount = parseInt((elementTopMoveDelta) / 50) //num elements popped (...-2,-1, 0 ,1,2...)
            let indexToMove = null
            let movePosition = null
            let moveTopAmount = null
            let updatedElementsPopped = null
            if (elementTopMoveDelta > 0) {//moving down
                if (elementsPopped.current !== mouseMovePopAmount) {
                    if (elementsPopped.current < mouseMovePopAmount) {
                        indexToMove = (itemIndexDragged.current + mouseMovePopAmount)
                        movePosition = 'relative'
                        moveTopAmount = (-50) + 'px'
                        updatedElementsPopped = mouseMovePopAmount
                    } else {
                        indexToMove = (itemIndexDragged.current + elementsPopped.current)
                        movePosition = 'initial'
                        updatedElementsPopped = elementsPopped.current - 1
                    }
                }
            } else {//moving up
                if (elementsPopped.current !== mouseMovePopAmount) {
                    if (elementsPopped.current > mouseMovePopAmount) {
                        indexToMove = (itemIndexDragged.current + mouseMovePopAmount)
                        movePosition = 'relative'
                        moveTopAmount = (50) + 'px'
                        updatedElementsPopped = mouseMovePopAmount
                    } else {
                        indexToMove = (itemIndexDragged.current + elementsPopped.current)
                        movePosition = 'initial'
                        updatedElementsPopped = elementsPopped.current + 1
                    }
                }
            }
            if (indexToMove !== null && indexToMove < kittinglist.length && indexToMove >= 0) {
                let elementToMove = document.getElementById("kitting-item-" + indexToMove)
                elementToMove.style.position = movePosition
                if (movePosition === 'relative') {
                    elementToMove.style.top = moveTopAmount
                }
                elementsPopped.current = updatedElementsPopped
            }
        }
    }

    const updateElements = () => {
        if (mouseIsDown === false) return
        setmouseIsDown(false)
        const draggedIndex = itemIndexDragged.current
        const indexMovedTo = draggedIndex + elementsPopped.current
        // Make a copy of the array using spread operator
        let copykittinglist = [...kittinglist];
        let elementToMove = copykittinglist.splice(draggedIndex, 1)[0]
        copykittinglist.splice(indexMovedTo, 0, elementToMove)
        // Update the state with the modified array
        setkittinglist(copykittinglist);
        //return dragged element style to initial
        let movedElement = document.getElementById("kitting-item-" + (draggedIndex))
        movedElement.style.position = 'initial'
        movedElement.style.zIndex = '1'; // Example z-index value
        movedElement.style.boxShadow = ''
        movedElement.style.top = ''

        //return moved elements style to initial
        let indexPointer = draggedIndex
        while (indexPointer !== indexMovedTo) {
            if (indexMovedTo > indexPointer) {
                indexPointer = indexPointer + 1
                let element = document.getElementById("kitting-item-" + (indexPointer))
                element.style.position = 'initial'
                element.style.zIndex = '1'; // Example z-index value
                element.style.boxShadow = ''
                element.style.top = ''
            } else if (indexMovedTo < indexPointer) {
                indexPointer = indexPointer - 1
                let element = document.getElementById("kitting-item-" + (indexPointer))
                element.style.position = 'initial'
                element.style.zIndex = '1'; // Example z-index value
                element.style.boxShadow = ''
                element.style.top = ''
            }
        }
    };

    return (
        <>
            {/* body container */}
            <div onMouseLeave={() => updateElements()} onMouseMove={(e) => moveElement(e)} onMouseUp={() => updateElements()} className='kitting-list-scroll-container' id='kitting-list-sroll-container'>
                {
                    kittinglist?.length > 0 ?
                        <>
                            {kittinglist.map((item, itemIndex) => {
                                return (
                                    <div id={'kitting-item-' + itemIndex} className='kitting-item' key={itemIndex} >
                                        <div className='kitting-item-start'>
                                            <DragIndicatorIcon onMouseDown={(e) => mouseDown(e, itemIndex)} sx={{ cursor: mouseIsDown ? 'grabbing' : 'grab' }} />
                                            <input onChange={(e) => updateItem(e.target.value, itemIndex)} className='kitting-title' value={item.title} placeholder={'Item...'}></input>
                                        </div>
                                        <CloseIcon onClick={() => removeItem(itemIndex)} sx={{ color: 'darkgray', fontSize: '18px', cursor: 'pointer' }} />
                                    </div>
                                )
                            })}
                        </> :
                        <p className='no-data-text'>--Add Kitting List item--</p>
                }
            </div>
            <button className='add-section-button' onClick={() => addItem()}>+ Item</button>
        </>)
}
