import React from 'react'
import './styling/modals.css'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export default function CustomConfirmModal({ show, toggleModal, submitDelete }) {

    function handleClose() {
        toggleModal(false, 7)
    }

    function onButtonClick(btn) {
        if (btn === "end") {
            submitDelete()
        }
        handleClose()
    }


    return (
        <>
            {show ? (
                <div className='backdrop' onClick={handleClose}>
                    <div className='confirm-modal-container' onClick={(e) => e.stopPropagation()}>
                        <div className="confirm-header" >
                            <HighlightOffIcon sx={{ color: '#f53649', fontSize: '40px' }} />
                            <h3 className='modal-title' style={{ color: 'black' }} >{"Confirm Delete?"}</h3>
                            {/* <HighlightOffIcon sx={{ color: 'transparent', fontSize: '40px' }} /> */}

                        </div>
                        <div className='submit-container' >
                            <button className='confirm-cancel-btn' onClick={() => onButtonClick("start")}>{"Cancel"}</button>
                            <button className='confirm-confirm-btn' onClick={() => onButtonClick("end")} >{"Delete"}</button>
                        </div>
                    </div>
                </div >)
                : <></>}
        </>)
}
