import React from 'react'
import PersonIcon from '@mui/icons-material/Person';
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Oval } from 'react-loader-spinner';

export default function ({ recordDetailsLoading, props, width }) {
    return (
        <div className='details-box-container' style={{ width }}>
            <div className='details-box-header'>
                <h4>{props.title}</h4>
            </div>
            {recordDetailsLoading ?
                <div className='loader-container'>
                    <Oval
                        visible={true}
                        height="42"
                        width="42"
                        color="var(--accent-color)"
                        secondaryColor="#cad6ed"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
                : <div className='details-box-body'>
                    <div className='detail-container'>
                        <PersonIcon sx={{ color: 'whitesmoke', fontSize: '18px' }} /><h4>{props.name}</h4>
                    </div>
                    <div className='detail-container'>
                        <CallIcon sx={{ color: 'whitesmoke', fontSize: '18px' }} /><h4>{props.contactNumber}</h4>
                    </div>
                    <div className='detail-container'>
                        <MailOutlineIcon sx={{ color: 'whitesmoke', fontSize: '18px' }} /><h4>{props.email}</h4>
                    </div>
                </div>}
        </div>
    )
}
