import React, { useEffect } from 'react'
import ListRow from './ListRow'
import ListRowExpandable from './ListRowExpandable'
import './styling/List.css'
import ListSeperatorLine from './ListSeperatorLine'
import { ThreeDots } from 'react-loader-spinner'
import { menuOptions } from '../DashboardConstants'

export default function ListBody({ activeListType, pageDataLoading, currentMenuOption, setSelectListItem, selectedListItemData, numColumns, listData, searchTerm, keyArray,
    toggleModal, editButtonClicked, startFileDownload, jobMetaData, dismissRecentRecords, sort, generateButtonClicked, qcDashboardActive, updatedCheckSocketData }) {

    function displayCompiledData(dataIn, forNewEntries) {
        const searchableKeys = menuOptions[qcDashboardActive][currentMenuOption]?.searchKeys
        return (dataIn.sort((a, b) => {
            if (a[sort.key] < b[sort.key]) return sort.direction === "asc" ? -1 : 1;
            if (a[sort.key] > b[sort.key]) return sort.direction === "asc" ? 1 : -1;
            return 0;
        }).filter((row) => { //filter for former entries
            const reworkFilterBool = ((currentMenuOption === 1) && jobMetaData?.newReworks?.includes(row._id))
            const completedFilterBool = ((currentMenuOption === 2) && jobMetaData?.newCompleted?.includes(row._id))
            if (reworkFilterBool || completedFilterBool) {
                return forNewEntries ? row : null;
            } else {
                return forNewEntries ? null : row;
            }
        }).filter((row) => { //filter for searchbar input (only for jobs (search by job-name and job-customer-name))
            if (searchableKeys) {
                for (let index = 0; index < searchableKeys.length; index++) {
                    const key = searchableKeys[index];
                    if (Array.isArray(key)) {
                        if (row[key[0]]?.[key[1]]?.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return row;
                        }
                    } else {
                        if (row[key]?.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return row;
                        }
                    }
                }
                return null
            } else {//eventually deprecate
                let customerNameString = ""
                if (row?.customer?.name !== undefined) {
                    customerNameString = row.customer.name
                }
                if ((searchTerm === "")
                    || (row?.name?.toLowerCase().includes(searchTerm.toLowerCase()))
                    || (row?.group?.name?.toLowerCase().includes(searchTerm.toLowerCase()))
                    || (row?.serialNumber?.toLowerCase().includes(searchTerm.toLowerCase()))
                    || (customerNameString.toLowerCase().includes(searchTerm.toLowerCase()))) {
                    return row;
                } else {
                    return null;
                }
            }
        }))

    }

    return (
        <div className='list-body'>
            {pageDataLoading ?
                <div className='data-loader-container'>
                    <ThreeDots
                        visible={true}
                        height="80"
                        width="80"
                        color="#284160"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
                :
                <>  {(((currentMenuOption === 1) && jobMetaData.newReworks?.length > 0) || ((currentMenuOption === 2) && jobMetaData.newCompleted?.length > 0)) &&
                    // {/* line - New Entries - line - "Dismiss All" Button */ }
                    (<>
                        < ListSeperatorLine
                            startOrEnd={"start"}
                            dismissRecentRecords={dismissRecentRecords} />
                        {displayCompiledData(listData, true).map((rowData, index) => {
                            let isSelected = false
                            if (rowData._id === selectedListItemData._id) isSelected = true
                            const updatedCheckData = (rowData._id === updatedCheckSocketData?.jobId) ? updatedCheckSocketData : {}
                            return (
                                (activeListType.includes("expand")) ?
                                    <ListRowExpandable
                                        key={index}
                                        currentMenuOption={currentMenuOption}
                                        indexID={index}
                                        isSelected={isSelected}
                                        setSelectListItem={setSelectListItem}
                                        numColumns={numColumns}
                                        keyArray={keyArray}
                                        rowData={rowData}
                                        toggleModal={toggleModal}
                                        editButtonClicked={editButtonClicked}
                                        startFileDownload={startFileDownload}
                                        generateButtonClicked={generateButtonClicked}
                                        updatedCheckData={updatedCheckData} />
                                    :
                                    <ListRow
                                        key={index}
                                        currentMenuOption={currentMenuOption}
                                        indexID={index}
                                        isSelected={isSelected}
                                        setSelectListItem={setSelectListItem}
                                        numColumns={numColumns}
                                        keyArray={keyArray}
                                        rowData={rowData}
                                        toggleModal={toggleModal}
                                        editButtonClicked={editButtonClicked}
                                        startFileDownload={startFileDownload} />
                            )
                        })}
                        {/* line - Former Entries - line */}
                        <ListSeperatorLine
                            startOrEnd={"end"}
                            dismissRecentRecords={dismissRecentRecords} />
                    </>)
                }
                    {//All records
                        listData.length > 0 ?
                            (displayCompiledData(listData, false).map((rowData, index) => {
                                let isSelected = false
                                if (rowData._id === selectedListItemData._id) isSelected = true
                                const updatedCheckData = (rowData._id === updatedCheckSocketData?.jobId) ? updatedCheckSocketData.check : {}
                                return (
                                    (activeListType.includes("expand")) ?
                                        <ListRowExpandable
                                            key={index}
                                            currentMenuOption={currentMenuOption}
                                            indexID={index}
                                            isSelected={isSelected}
                                            setSelectListItem={setSelectListItem}
                                            numColumns={numColumns}
                                            keyArray={keyArray}
                                            rowData={rowData}
                                            toggleModal={toggleModal}
                                            editButtonClicked={editButtonClicked}
                                            startFileDownload={startFileDownload}
                                            generateButtonClicked={generateButtonClicked}
                                            updatedCheckData={updatedCheckData} />
                                        :
                                        <ListRow
                                            key={index}
                                            currentMenuOption={currentMenuOption}
                                            indexID={index}
                                            isSelected={isSelected}
                                            setSelectListItem={setSelectListItem}
                                            numColumns={numColumns}
                                            keyArray={keyArray}
                                            rowData={rowData}
                                            toggleModal={toggleModal}
                                            editButtonClicked={editButtonClicked}
                                            startFileDownload={startFileDownload} />
                                )
                            }))
                            :
                            <p className='no-data-text'>--No data to display--</p>
                    }
                </>
            }
        </div>
    )
}
