import React, { useState, useEffect } from 'react'
import './styling/modals.css'
import { Bars } from 'react-loader-spinner'

export default function JobAddEditModal({ show, modalInEditMode, toggleModal, modalOnSubmit, customers, resourcesLoading, customersLoading,
    resources, selectedListItemData, toggleAlert }) { //from = Add / Edit
    //form input fields
    const [name, setname] = useState("")
    const [customer, setcustomer] = useState("")
    const [contact, setcontact] = useState("")
    const [linedUpBy, setlinedUpBy] = useState("")
    const [inspectedBy, setinspectedBy] = useState("")
    const [salesRep, setsalesRep] = useState("")
    const [specSheetFile, setspecSheetFile] = useState({ file: null, fileName: "No file chosen..." })
    const [requiredMaterials, setrequiredMaterials] = useState("")
    //dropdown options
    const [customerOptions, setcustomerOptions] = useState([])
    const [customerContactPeopleOptions, setcustomerContactPeopleOptions] = useState([])
    const [resourceOptions, setresourceOptions] = useState([[], []]) //[[...salesRep], [...technicians]]
    const [inputFocused, setinputFocused] = useState(-1);

    useEffect(() => {
        if (show) {
            setcustomerOptions(customers)
            setcustomerContactPeopleOptions([])
            if (modalInEditMode) {
                populateInputFields()
            }
        } else { //clear data on close (show===false trigger)
            clearInputFields()
        }
    }, [show])

    useEffect(() => {
        if (customers.length > 0) {
            setcustomerOptions(customers)
        }
    }, [customers]);

    useEffect(() => {
        if (resources.length > 0) {
            const techniciansArr = []
            const salesRepsArr = []
            resources.forEach(res => {
                if (res.type === 0) { //technician
                    techniciansArr.push(res)
                } else if (res.type === 1) {//salesRep
                    salesRepsArr.push(res)
                }
            });
            setresourceOptions([salesRepsArr, techniciansArr])
        }
    }, [resources]);

    function populateInputFields() { //on edit
        setname(selectedListItemData.name)
        if (selectedListItemData.customer !== null) {
            setcustomer(selectedListItemData.customer._id)
            const index = customers.findIndex(c => c._id === selectedListItemData.customer._id);
            setcustomerContactPeopleOptions(customers[index].contactPeople)
        }
        selectedListItemData.contact !== null && setcontact(selectedListItemData.contact._id)
        selectedListItemData.linedUpBy !== null && setlinedUpBy(selectedListItemData.linedUpBy._id)
        selectedListItemData.inspectedBy !== null && setinspectedBy(selectedListItemData.inspectedBy._id)
        selectedListItemData.salesRep !== null && setsalesRep(selectedListItemData.salesRep._id)
        setspecSheetFile({ file: null, fileName: selectedListItemData.specSheet })
        setrequiredMaterials(selectedListItemData.requiredMaterials)
    }

    function clearInputFields() {
        setname("")
        setcustomer("")
        setcontact("")
        setlinedUpBy("")
        setinspectedBy("")
        setsalesRep("")
        setspecSheetFile({ file: null, fileName: "No file chosen..." })
        setrequiredMaterials("")
    }

    function handleClose(cancelClicked) {
        //clearInputFields()
        //close method
        toggleModal(false, 0)
    }

    function onChange(event) {
        switch (event.target.name) {
            case "name":
                setname(event.target.value)
                break;
            case "customer":
                setcustomer(event.target.value)
                const index = customerOptions.findIndex(c => c._id == event.target.value);
                setcontact("")
                setcustomerContactPeopleOptions(customerOptions[index].contactPeople)
                break;
            case "contact":
                setcontact(event.target.value)
                break;
            case "linedUpBy":
                setlinedUpBy(event.target.value)
                break;
            case "inspectedBy":
                setinspectedBy(event.target.value)
                break;
            case "salesRep":
                setsalesRep(event.target.value)
                break;
            case "requiredMaterials":
                setrequiredMaterials(event.target.value)
                break;
            default:
                break;
        }
    }
    function onChangeFile(e) {
        const fileIn = e.target.files[0];
        const fileObj = { file: fileIn, fileName: fileIn.name }
        setspecSheetFile(fileObj)
    }

    function onSubmit() {
        if (!validateInput()) return //if input not valid, return
        const jobJsonObject = {
            name,
            customer,
            contact,
            linedUpBy,
            inspectedBy,
            salesRep,
            requiredMaterials,
            specsheet: specSheetFile.file
        }
        modalOnSubmit(jobJsonObject, false, 1) //false to prevent api call / isFormData = 1
        toggleModal(true, 1)
    }

    function validateInput() {
        if ((name === "") ||
            (customer === "") ||
            (contact === "") ||
            (linedUpBy === "") ||
            (inspectedBy === "") ||
            (salesRep === "")) {
            toggleAlert(true, 1, "Please make sure all required fields are filled in")
            return false
        }
        return true
    }

    return (
        <>
            {show ? (
                <div className='backdrop' onClick={handleClose}>
                    <div className='modal-body ' onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header" >
                            <h3 className='modal-title' >{(modalInEditMode ? "Edit" : "Add") + " Job"}</h3>
                        </div>
                        {/* body container */}
                        <form className='input-form'  >
                            <div className="form-group-vertical">
                                <label htmlFor="name" className="col-form-label">Job Name</label>
                                <input value={name} type="text" autoComplete='off' id='name' placeholder='' className="modal-input" name="name" onChange={onChange} ></input>
                            </div>
                            <div className="form-group-vertical">
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                    <label htmlFor="customer" className="col-form-label">Customer
                                    </label>
                                    {(inputFocused === 1 && customersLoading) &&
                                        <Bars
                                            height="15"
                                            width="15"
                                            color="var(--primary-color)"
                                            ariaLabel="bars-loading"
                                            wrapperStyle={{ marginLeft: '9px' }}
                                            wrapperClass=""
                                            visible={true}
                                        />
                                    }
                                </div>
                                <select onFocus={() => setinputFocused(1)} value={customer} className="modal-input" name="customer" id='customer' onChange={onChange} >
                                    <option value="" disabled={true}></option>
                                    {customerOptions.map((option) => {
                                        return (
                                            <option key={option._id} value={option._id}>{option.name}</option>
                                        )
                                    })}</select>
                            </div>
                            <div className="form-group-vertical">
                                <label htmlFor="contact" className="col-form-label">Customer Contact</label>
                                <select value={contact} className="modal-input" name="contact" id='contact' onChange={onChange} >
                                    <option value="" disabled={true}></option>
                                    {customerContactPeopleOptions.map((option) => {
                                        return (
                                            <option key={option._id} value={option._id}>{option.name}</option>
                                        )
                                    })}</select>
                            </div>
                            <div className="form-group-vertical">
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                    <label htmlFor="linedUpBy" className="col-form-label">Lined Up By</label>
                                    {(inputFocused === 2 && resourcesLoading) &&
                                        <Bars
                                            height="15"
                                            width="15"
                                            color="var(--primary-color)"
                                            ariaLabel="bars-loading"
                                            wrapperStyle={{ marginLeft: '9px' }}
                                            wrapperClass=""
                                            visible={true}
                                        />
                                    }
                                </div>
                                <select onFocus={() => setinputFocused(2)} value={linedUpBy} className="modal-input" name="linedUpBy" id='linedUpBy' onChange={onChange} >
                                    <option value="" disabled={true}></option>
                                    {resourceOptions[0].map((option) => {
                                        return (
                                            <option key={option._id} value={option._id}>{option.name}</option>
                                        )
                                    })}</select>
                            </div>
                            <div className="form-group-vertical">
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                    <label htmlFor="inspectedBy" className="col-form-label">Inspected By</label>
                                    {(inputFocused === 3 && resourcesLoading) &&
                                        <Bars
                                            height="15"
                                            width="15"
                                            color="var(--primary-color)"
                                            ariaLabel="bars-loading"
                                            wrapperStyle={{ marginLeft: '9px' }}
                                            wrapperClass=""
                                            visible={true}
                                        />
                                    }
                                </div>
                                <select onFocus={() => setinputFocused(3)} value={inspectedBy} className="modal-input" name="inspectedBy" id='inspectedBy' onChange={onChange} >
                                    <option value="" disabled={true}></option>
                                    {resourceOptions[0].map((option) => {
                                        return (
                                            <option key={option._id} value={option._id}>{option.name}</option>
                                        )
                                    })}</select>
                            </div>
                            <div className="form-group-vertical">
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                    <label htmlFor="salesRep" className="col-form-label">Sales Rep</label>
                                    {(inputFocused === 4 && resourcesLoading) &&
                                        <Bars
                                            height="15"
                                            width="15"
                                            color="var(--primary-color)"
                                            ariaLabel="bars-loading"
                                            wrapperStyle={{ marginLeft: '9px' }}
                                            wrapperClass=""
                                            visible={true}
                                        />
                                    }
                                </div>
                                <select onFocus={() => setinputFocused(4)} value={salesRep} className="modal-input" name="salesRep" id='salesRep' onChange={onChange} >
                                    <option value="" disabled={true}></option>
                                    {resourceOptions[1].map((option) => {
                                        return (
                                            <option key={option._id} value={option._id}>{option.name}</option>
                                        )
                                    })}</select>
                            </div>
                            <div className="form-group-vertical">
                                <label htmlFor="spec-sheet-upload-input" className="col-form-label">Spec Sheet</label>
                                <label className="custom-file-upload-container" >
                                    <input
                                        type="file"
                                        id='spec-sheet-upload-input'
                                        name="specSheet"
                                        accept=".pdf"
                                        onChange={onChangeFile}
                                    />{specSheetFile.fileName}
                                    <div className='addfile-button' >
                                        Choose File
                                    </div>
                                </label>
                            </div>
                            <div className="form-group-vertical">
                                <label htmlFor="requiredMaterials" className="col-form-label">Required Materials<span className='optional-label'>(optional)</span></label>
                                <textarea value={requiredMaterials} name='requiredMaterials' id='requiredMaterials' onChange={(e) => onChange(e)}
                                    style={{ resize: 'none', background: 'white', border: '1px solid #009c9e', borderRadius: '5px' }} />
                            </div>
                        </form>
                        <div className='submit-container'>
                            <button className='submit-btn back-btn' onClick={() => { handleClose() }}>{"Cancel"}</button>
                            <button className='submit-btn' onClick={() => { onSubmit() }} >
                                {"Next"}
                            </button>
                        </div>
                    </div>
                </div >)
                : <></>}
        </>)
}
