import React, { useState, useRef, useEffect } from 'react'
import './styling/List.css'
import { DateTime } from 'luxon'
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import CameraIcon from '@mui/icons-material/Camera';
import DownloadIcon from '@mui/icons-material/Download';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { sendMail } from '../api/sqc/JobsAPI';

export default function ListRow({ currentMenuOption, indexID, isSelected, setSelectListItem, numColumns, keyArray, rowData, toggleModal,
    editButtonClicked, startFileDownload }) {
    const [bgColor, setbgColor] = useState('')

    useEffect(() => {
        const shouldDisplayActionsOnSelect = (currentMenuOption === 0 || currentMenuOption === 5)//for active jobs and resources(fqc)
        if (isSelected) {
            setbgColor("var(--selected-row-color)")
            shouldDisplayActionsOnSelect && showDetails()
        } else {
            setbgColor("")
            shouldDisplayActionsOnSelect && hideDetails()
        }
    }, [isSelected, rowData])

    //MoreDetails functions
    const showMoreDetails = useRef(false)
    function showDetails() {
        let container = document.getElementById('list-row-details-container' + indexID)
        if (container === null) return
        container.style.display = "flex";
        showMoreDetails.current = true;
    }
    function hideDetails() {
        if (!showMoreDetails.current) return
        let container = document.getElementById('list-row-details-container' + indexID)
        if (container === null) return
        container.style.display = "none";
        showMoreDetails.current = false;
    }

    function editButtonClickedLocal(e) { //to stop propagation
        e.stopPropagation()
        editButtonClicked()
    }

    function deleteButtonClicked(e) {
        e.stopPropagation()
        toggleModal(true, 7)
    }

    function openAddressIssuesModal() {
        if (!isSelected) {
            setSelectListItem(rowData)
        }
        toggleModal(true, 6)
    }

    function getColumnComponent(key, value) {
        let type = key // [progress, actions]
        if (key === "actions") type = type + currentMenuOption
        switch (type) {
            case "lastPermission": //actions for Resources
                return (
                    <>
                        <div className='permission-box'>
                            <div className='color-indicator' style={{ backgroundColor: value === true ? "green" : "red" }}>
                            </div>
                            <p style={{ textAlign: 'center' }}>
                                {value === true ? "Yes" : "No"}
                            </p>
                        </div>
                        {<div id={'list-row-details-container' + indexID} className='list-row-details-container' style={{ display: 'none' }}>
                            <button onClick={(e) => editButtonClickedLocal(e)}>
                                <EditIcon sx={{ fontSize: '18px', color: 'black' }} />
                            </button>
                            <button onClick={(e) => deleteButtonClicked(e)}>
                                <DeleteOutlinedIcon sx={{ fontSize: '18px', color: 'black' }} />
                            </button>
                        </div>}
                    </>
                )
                break;
            case "permissions":
                return (
                    <div className='permission-box'>
                        <div className='color-indicator' style={{ backgroundColor: value === true ? "green" : "red" }}>
                        </div>
                        <p style={{ textAlign: 'center' }}>
                            {value === true ? "Yes" : "No"}
                        </p>
                    </div>
                )
                break;
            case "progress": //actions for Active jobs
                return (
                    <>
                        <p style={{ flex: 2, textAlign: 'center' }}>
                            {value + "%"}
                        </p>
                        <div id={'list-row-details-container' + indexID} className='list-row-details-container' style={{ display: 'none' }}>
                            <button onClick={(e) => editButtonClickedLocal(e)}>
                                <EditIcon sx={{ fontSize: '18px', color: 'black' }} />
                            </button>
                            <button onClick={(e) => deleteButtonClicked(e)}>
                                <DeleteOutlinedIcon sx={{ fontSize: '18px', color: 'black' }} />
                            </button>
                        </div>
                    </>)
                break;
            case "actions1": //actions for Rework jobs
                return (
                    <div className='action-box' onClick={(e) => e.stopPropagation()}>
                        <button onClick={() => { startFileDownload("/api/jobs/" + rowData._id + "/downloadMedia") }}><CameraIcon /></button>
                        <button onClick={() => { startFileDownload("/api/jobs/" + rowData._id + "/checksheet") }}
                            style={{ borderLeft: "1px solid black", borderRight: "1px solid black" }}><DownloadIcon /></button>
                        <button onClick={() => openAddressIssuesModal()}><BuildOutlinedIcon /></button>
                    </div>)
                break;
            case "actions2": //actions for Completed jobs
                return (
                    <div className='action-box' onClick={(e) => e.stopPropagation()}>
                        <button onClick={() => { startFileDownload("/api/jobs/" + rowData._id + "/downloadMedia") }}><CameraIcon /></button>
                        <button onClick={() => { startFileDownload("/api/jobs/" + rowData._id + "/checksheet") }}
                            style={{ borderLeft: "1px solid black", borderRight: "1px solid black" }}><DownloadIcon /></button>
                        <button onClick={() => [setSelectListItem(rowData, true), toggleModal(true, 8)]}><ForwardToInboxIcon /></button>
                    </div>)
                break;
            default:
                return (
                    <p style={{ flex: 1, textAlign: 'center' }}>
                        {value}
                    </p>)
                break;
        }
    }

    return (
        <div className='list-row' style={{ backgroundColor: bgColor }}>
            {keyArray.map((key, index) => { //record item
                let value = ""
                let keyValue = key //change when key is array
                if (Array.isArray(key)) {
                    keyValue = key[0]
                    if (rowData[key[0]] !== null) {
                        value = rowData[key[0]]?.[key[1]]
                    }
                    if (key[1] === "rework") {
                        keyValue = "lastPermission"
                    }
                } else if ((key === "createdAt") || (key === "updatedAt")) {
                    value = DateTime.fromISO(rowData[key]).toFormat("dd/MM/yyyy");
                } else {
                    value = rowData[key]
                }
                return (
                    <div key={index} onClick={() => setSelectListItem(rowData)} className='row-item'
                        style={{ width: 'calc(100% / ' + numColumns + ')' }}>
                        {
                            getColumnComponent(keyValue, value)
                        }
                    </div>)
            })}
        </div>
    )
}
