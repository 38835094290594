import React, { useState } from 'react'
import './styling/ChecksheetDetailsContainer.css'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CameraIcon from '@mui/icons-material/Camera';
import DownloadIcon from '@mui/icons-material/Download';

function ChecksheetDetailsContainer({ props, jobID,startFileDownload }) {

    const [showDropdown, setshowDropdown] = useState(false)
    const unitStatusTitleArr = ["Pending", "In Progress", "FAIL", "PASS"]
    const unitStatusColorArr = ["#00000033", "#4e8df366", "#ea9999", "#b6d7a8"]

    return (
        <div className='checksheet-details-container'>
            <div className='checksheet-details-header'>
                <h5 style={{ flex: 6 }}>{props.name}</h5>
                <h5 style={{ flex: 3, fontWeight: 500 }}>{"Units: " + props.units.length}</h5>
                <h5 style={{ flex: 3, fontWeight: 500 }}>{"Completed: " + props.numUnitsCompleted}</h5>
                <div className='checksheet-dropdown-button' onClick={() => setshowDropdown(prev => !prev)}>
                    {showDropdown ?
                        <ArrowDropUpIcon /> :
                        <ArrowDropDownIcon />}
                </div>
            </div>
            {showDropdown &&
                (<div className='checksheet-details-body'>
                    {props.units.map((unit, index) => {

                        let statusColor = unitStatusColorArr[0] //normal for pending
                        //let statusTitle = unitStatusTitleArr[unit.status]
                        if (unit.status === 1) { //In Progress
                            statusColor = unitStatusColorArr[1];
                        } else if (unit.status === 2) { //fail
                            statusColor = unitStatusColorArr[2];
                        } else if (unit.status === 3) { //pass
                            statusColor = unitStatusColorArr[3];
                        }
                        return (<div className='checksheet-details-check-container' key={index}>
                            <h6 className='check-title'>{unit.serialNumber}</h6>
                            <div className='check-state-container' style={{ backgroundColor: statusColor }}>
                                {unitStatusTitleArr[unit.status]}
                            </div>
                            {(unit.status !== 0) ?
                                // not pending
                                (<div className='misc-container'>
                                    {unit.status === 1 ?
                                        //in progress ..show %
                                        <h5 style={{ margin: 0 }}>{unit.progress + "%"}</h5> :
                                        //PASS or FAIL
                                        <>
                                            <div className='action-btn' onClick={() => { startFileDownload("/api/jobs/" + jobID + "/units/" + unit._id + "/downloadMedia") }}
                                                style={{ display: 'flex', justifyContent: 'center', borderRight: '1px solid black', width: '50%', cursor: 'pointer' }}>
                                                <CameraIcon sx={{ fontSize: '21px' }} />
                                            </div>
                                            <div className='action-btn' onClick={() => { startFileDownload("/api/jobs/" + jobID + "/units/" + unit._id + "/checksheet") }}
                                                style={{ display: 'flex', justifyContent: 'center', width: '50%', cursor: 'pointer' }}>
                                                <DownloadIcon sx={{ fontSize: '21px' }} />
                                            </div>
                                        </>}
                                </div>) :
                                //pending
                                (<div className='misc-container'></div>)
                            }
                        </div>)
                    })}
                </div>)
            }
        </div >
    )
}

export default ChecksheetDetailsContainer