import { sqcConfig } from "../ApiConfig"

export async function loginUser(credentials) {
    return fetch(sqcConfig.url + '/api/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    }).then(response => {
        return response
    })
        .then(data => data.json()
        ).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}


export async function forgotPassword(props) {
    return fetch(sqcConfig.url + '/api/forgot-password', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(props)
    }).then(res => res.json())
        .then(data => {
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function resetPassword(token, password) {
    return fetch(sqcConfig.url + '/api/reset-password/' + token, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password })
    }).then(res => res.json())
        .then(data => {
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}