import React, { useState, useRef, useEffect } from 'react'
import { sectionColors } from '../Constants';

export default function CheckSheetBuilderModal3({ checkElements,
    setcheckElements, sections, elementConnectors,
    setelementConnectors, checksheetMetaData, setSignedOff }) {

    const elementBlockHeight = 60
    const elementBlockWidth = 90
    const [selectedConnectorIndex, setselectedConnectorIndex] = useState(null);
    const [legendHeaders, setlegendHeaders] = useState([]);//{title,colorIndex}
    //drag and drop + connection line moves
    const [mouseIsDown, setmouseIsDown] = useState(false);
    const mouseMoveFunction = useRef(0) //0: move element , 1: connector line
    const checkIndexDragged = useRef(null); //can be used as mouseDown aswell
    const mousePositionXY = useRef({})
    const tempLeft = useRef(0)//element move
    const tempTop = useRef(0)//element move
    const tempLineEndX = useRef(0)//connector line
    const tempLineEndY = useRef(0)//connector line
    const mouseOverConnectPointIndex = useRef(null) //drop point (connect to index)
    const connectorLineIndexBeingDragged = useRef(null)
    const snaptoGrid = false
    const [checkElementsInitialized, setcheckElementsInitialized] = useState(false);

    useEffect(() => { //init UI elements on sections update/show
        if (sections.length > 0) {
            initLegendHeaders(sections)
            initCanvasElements(sections)
        }
    }, []);

    useEffect(() => {
        checkFinalSignOff()
    }, [elementConnectors]);

    const checkFinalSignOff = () => {
        // Check if there are atleast one dependancies connected to final sign off
        const finalSignOff = elementConnectors.find(connector => connector.endCheckIds.checkId === "final");
        if (finalSignOff) {
            setSignedOff(true);
        } else {
            setSignedOff(false);
        }
    }

    function initLegendHeaders(sectionInput) {
        const mockSectionInput = [
            { title: 'Colors / Cladding and Line Up', colorIndex: 0 },
            { title: 'Interior', colorIndex: 1 },
            { title: 'Lorem ipsum dollar ammet', colorIndex: 2 },
            { title: 'This is a section', colorIndex: 3 },
            { title: 'Mep mep', colorIndex: 4 },
            { title: 'Electrical if applicable', colorIndex: 5 },
            { title: 'A very ong section name that shoul cause pronpems with the layout', colorIndex: 6 },
            { title: 'Section 7', colorIndex: 7 },
            { title: 'Potato', colorIndex: 8 },
            { title: 'Avo', colorIndex: 9 },
            { title: 'Cado', colorIndex: 0 },
            { title: 'Suprano munano', colorIndex: 1 },
            { title: 'Lines', colorIndex: 2 },
            { title: 'Avo', colorIndex: 9 },
            { title: 'Cado', colorIndex: 0 },
            { title: 'Suprano munano', colorIndex: 1 },
            { title: 'Lines', colorIndex: 2 }
        ]
        const legendObjects = []
        sectionInput.forEach(section => {
            legendObjects.push({ title: section.title, colorIndex: section.colorIndex })
        });
        setlegendHeaders(legendObjects)
    }

    function initCanvasElements(sectionInput) {
        const checkElementsOutput = []
        let x = 10
        let y = 10
        sectionInput.forEach(section => {
            y = 10
            section.checks.forEach(check => {
                const indexOfExistingCheckElement = checkElements.findIndex(element => element.checkId === check._id)
                if (indexOfExistingCheckElement !== -1) { //if exists local
                    x = checkElements[indexOfExistingCheckElement].x
                    y = checkElements[indexOfExistingCheckElement].y
                } else if (checksheetMetaData?.checkCoordinates?.[check._id]) {//if exists on server
                    x = checksheetMetaData.checkCoordinates[check._id].x
                    y = checksheetMetaData.checkCoordinates[check._id].y
                }
                const checkElement = {
                    sectionId: section._id,
                    checkId: check._id,
                    type: check.type,
                    title: check.title,
                    colorIndex: section.colorIndex,
                    x, y
                }
                checkElementsOutput.push(checkElement)
                y = y + elementBlockHeight + 30
                if (y >= (elementBlockHeight + 30) * 5) {
                    x = x + elementBlockWidth + 30
                    y = 10
                }
            });
            x = x + elementBlockWidth + 30
        });
        if (checksheetMetaData?.checkCoordinates?.["final"]) {
            x = checksheetMetaData.checkCoordinates["final"].x
            y = checksheetMetaData.checkCoordinates["final"].y
        }
        const finalSignOffElement = {
            sectionId: "final",
            checkId: "final",
            type: 99,
            title: "Sign Off",
            colorIndex: null,
            x, y: 10
        }
        checkElementsOutput.push(finalSignOffElement)
        setcheckElements(checkElementsOutput)
        setcheckElementsInitialized(true)
    }

    function removeElementConnector(lineIndex) {
        setelementConnectors(arr => arr.filter((line, index) => index !== lineIndex))
        setselectedConnectorIndex(null)
    }

    function mouseDown(type, event, itemIndex) { //start move func...from SVGcontainer
        mousePositionXY.current = {
            x: event.clientX,
            y: event.clientY
        };
        checkIndexDragged.current = itemIndex
        if (type === "drag") {
            tempLeft.current = checkElements[itemIndex].x
            tempTop.current = checkElements[itemIndex].y
            mouseMoveFunction.current = 0
        } else if (type === "connectStart") { //start connector line-> add new line component
            const startX = checkElements[itemIndex].x + elementBlockWidth
            const startY = checkElements[itemIndex].y + (elementBlockHeight / 2)
            tempLineEndX.current = startX
            tempLineEndY.current = startY
            mouseMoveFunction.current = 1
            const startCheckElementObj = checkElements[checkIndexDragged.current]
            const newConnectorLine = {
                startCheckIds: { sectionId: startCheckElementObj.sectionId, checkId: startCheckElementObj.checkId },
                endCheckIds: { sectionId: null, checkId: null }, end: { x: startX, y: startY },
            }
            connectorLineIndexBeingDragged.current = elementConnectors.length
            setelementConnectors(prev => [...prev, newConnectorLine])
        } else if (type === "connectEnd") { //connector head (arrow) dragged
            tempLineEndX.current = elementConnectors[itemIndex].end.x
            tempLineEndY.current = elementConnectors[itemIndex].end.y
            mouseMoveFunction.current = 1
            connectorLineIndexBeingDragged.current = itemIndex
        }
        setmouseIsDown(true)
    }

    function mouseMove(e) {
        if (mouseMoveFunction.current === 0) {
            moveElement(e)
        } else {
            changeLineElement(e)
        }
    }

    function moveElement(event) { //start move func...from SVGcontainer
        if (mouseIsDown) { //move element
            const copyCheckElements = [...checkElements]
            let distX = (event.clientX - mousePositionXY.current.x);
            let distY = (event.clientY - mousePositionXY.current.y);
            let updatedLeft = tempLeft.current + distX// Math.round((distX) / 10) * 10; //100 needs to change to element currentWidth?
            let updatedTop = tempTop.current + distY//Math.round((distY) / 10) * 10;
            updatedLeft = (Math.round(updatedLeft * 100) / 100)
            updatedTop = (Math.round(updatedTop * 100) / 100)
            if (snaptoGrid) {
                updatedLeft = Math.round((updatedLeft) / 9) * 9;
                updatedTop = Math.round((updatedTop) / 9) * 9;
            }
            copyCheckElements[checkIndexDragged.current].x = updatedLeft
            copyCheckElements[checkIndexDragged.current].y = updatedTop
            setcheckElements(copyCheckElements)
        }
    }

    function changeLineElement(event) { //manipulate line element here
        if (mouseIsDown) { //move element
            let copyelementConnectors = [...elementConnectors]
            let distX = (event.clientX - mousePositionXY.current.x);
            let distY = (event.clientY - mousePositionXY.current.y);
            let updatedX = tempLineEndX.current + distX// Math.round((distX) / 10) * 10; //100 needs to change to element currentWidth?
            let updatedY = tempLineEndY.current + distY//Math.round((distY) / 10) * 10
            copyelementConnectors[connectorLineIndexBeingDragged.current].end.x = updatedX
            copyelementConnectors[connectorLineIndexBeingDragged.current].end.y = updatedY
            setelementConnectors(copyelementConnectors)
        }
    }

    const mouseUp = () => {
        if (mouseMoveFunction.current === 1) {//isLine function
            if (mouseOverConnectPointIndex.current !== null) {
                const endCheckElementObj = checkElements[mouseOverConnectPointIndex.current]
                let copyelementConnectors = [...elementConnectors]
                const newEndCheckIds = endCheckElementObj.checkId === "final"
                    ? { sectionId: 'final', checkId: 'final' }
                    : { sectionId: endCheckElementObj.sectionId, checkId: endCheckElementObj.checkId };

                // Check if a line with the same start and end check IDs already exists
                const duplicateLineIndex = copyelementConnectors.findIndex(connector =>
                    connector.startCheckIds.sectionId === copyelementConnectors[connectorLineIndexBeingDragged.current].startCheckIds.sectionId &&
                    connector.startCheckIds.checkId === copyelementConnectors[connectorLineIndexBeingDragged.current].startCheckIds.checkId &&
                    connector.endCheckIds.sectionId === newEndCheckIds.sectionId &&
                    connector.endCheckIds.checkId === newEndCheckIds.checkId
                );

                if (duplicateLineIndex !== -1) {
                    // If a duplicate line exists, remove the new line
                    copyelementConnectors.splice(connectorLineIndexBeingDragged.current, 1);
                } else {
                    // If no duplicate line exists, set the end check IDs of the new line
                    copyelementConnectors[connectorLineIndexBeingDragged.current].endCheckIds = newEndCheckIds;
                }
                setelementConnectors(copyelementConnectors)
            }
        }

        setmouseIsDown(false)
    };

    const compileLinePath = (startX, startY, endX, endY) => { //for connector lines
        let path = ''
        if (endX < startX) {
            let halfLineYDist = (startY - endY) / 2
            path = path + startX + ',' + startY //start
            path = path + " " + (startX + 15) + ',' + startY //start
            path = path + " " + (startX + 15) + ',' + (startY - halfLineYDist) //start
            path = path + " " + (endX - 15) + ',' + (endY + halfLineYDist) //end
            path = path + " " + (endX - 15) + ',' + endY //end
            path = path + " " + endX + ',' + endY //end
        } else {
            let lineXDist = endX - startX
            path = path + startX + ',' + startY //start
            path = path + " " + (startX + lineXDist / 2) + ',' + startY //first bend
            path = path + " " + (startX + lineXDist / 2) + ',' + endY //second bend
            path = path + " " + endX + ',' + endY //end
        }
        return path
    }
    const compileArrowPoints = (endX, endY) => { //for connector head
        const arrowHeightVal = 4
        const arrowWidthVal = 3
        return (endX - arrowWidthVal) + ',' + (endY - arrowHeightVal) + ' ' + (endX - arrowWidthVal) + ',' + (endY + arrowHeightVal) + ' ' + (endX + arrowWidthVal) + ',' + (endY)
    }

    const scrollableRef = useRef(null);
    const handleScroll = (event) => {
      event.preventDefault();
      scrollableRef.current.scrollBy({ left: event.deltaY < 0 ? -30 : 30 });
    };
  

    return (
        <>
            {/* body container */}
            <div className='sections-canvas-container' ref={scrollableRef} style={{ overflowX: 'scroll' }} onWheel={handleScroll} >
                <svg onMouseMove={(e) => mouseMove(e)} onMouseUp={() => mouseUp()} onMouseLeave={() => mouseUp()}
                    onClick={() => setselectedConnectorIndex(null)}
                    style={{ width: 'calc(300%)', height: 'calc( 100%' }}>
                    {/* <rect width={'100%'} height="100%" x="0" y="0" fill="red" stroke='transparent' /> */}
                    {checkElementsInitialized &&
                        <>{elementConnectors.map((element, index) => {
                            const startCheckObj = checkElements.find((check) => check.checkId === element.startCheckIds.checkId)
                            const startX = startCheckObj.x + elementBlockWidth
                            const startY = startCheckObj.y + (elementBlockHeight / 2)
                            let strokeDasharray = '6,3'
                            let endX = element.end.x
                            let endY = element.end.y
                            if (element.endCheckIds?.checkId !== null) { //element is connected
                                const endCheckObj = checkElements.find((check) => check.checkId === element.endCheckIds.checkId)
                                endX = endCheckObj.x
                                endY = endCheckObj.y + elementBlockHeight / 2
                                strokeDasharray = ''
                            }
                            const linePath = compileLinePath(startX, startY, endX, endY)
                            const arrowPoints = compileArrowPoints(endX, endY)
                            return (
                                <g key={index}>
                                    <polyline
                                        className='display-line'
                                        points={linePath}
                                        stroke={'#37b6f2'} strokeWidth={1.5}
                                        fill='none'
                                        strokeDasharray={strokeDasharray}
                                    ></polyline>
                                    <polyline
                                        className='selector-line'
                                        onClick={(e) => [e.stopPropagation(), setselectedConnectorIndex(index)]}
                                        style={{ cursor: 'pointer' }}
                                        points={linePath}
                                        stroke={'transparent'} strokeWidth={3}
                                        fill='none'
                                    ></polyline>
                                    {selectedConnectorIndex === index &&
                                        <svg onClick={() => removeElementConnector(index)}
                                            style={{ cursor: 'pointer' }}
                                            x={startX - 6 + (endX - startX) / 2} y={startY - 6 + (endY - startY) / 2} width={12} height={12} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <circle cx="10" cy="10" r="9" fill="white" stroke='red' strokeWidth="2" /> <path d="M5 5L15 15 M15 5L5 15" stroke="red" strokeWidth="2" fill="none" />
                                        </svg>
                                    }
                                    <polygon style={{ cursor: 'pointer' }} onMouseDown={(e) => mouseDown("connectEnd", e, index)} points={arrowPoints} fill="#37b6f2" stroke="#37b6f2" />
                                </g>
                            )
                        })}</>}
                    {checkElements.map((section, index) => {
                        let fill = section.type === 0 ? sectionColors[section.colorIndex] : 'white'
                        if (section.checkId === "final") {
                            fill = '#00000099'
                        }
                        return (
                            <g key={index} transform={'translate(' + (section.x) + ',' + section.y + ')'}>
                                <rect className='draggable-element-container'
                                    width={elementBlockWidth} height={elementBlockHeight} x={0} y={0}
                                    fill={fill}
                                    rx={5} ry={5}
                                    stroke={section.type === 0 ? 'gray' : sectionColors[section.colorIndex]} />
                                {/* <text style={{ userSelect: 'none',width: '100%' }} y={elementBlockHeight / 2 + 6} x={10}></text> */}
                                <foreignObject style={{ cursor: mouseIsDown ? 'grabbing' : 'grab', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
                                    onMouseDown={(e) => mouseDown("drag", e, index)} width={elementBlockWidth} height={elementBlockHeight} x={0} y={0} >
                                    <div className='element-title-container' >
                                        <h5 className='element-title-header' style={{ color: section.checkId === "final" ? 'white' : 'black' }} >
                                            {section.title}
                                        </h5>
                                    </div>
                                </foreignObject>
                                <line x1={0} x2={0} y1={elementBlockHeight / 2} y2={elementBlockHeight / 2}
                                    stroke={'green'} strokeWidth={12}
                                    strokeLinecap='round'
                                    vectorEffect={'non-scaling-stroke'}></line>
                                {section.checkId !== "final" &&
                                    <line style={{ cursor: 'pointer' }} x1={elementBlockWidth} x2={elementBlockWidth} y1={elementBlockHeight / 2} y2={elementBlockHeight / 2}
                                        onMouseDown={(e) => mouseDown("connectStart", e, index)}
                                        stroke={'#37b6f2'} strokeWidth={12}
                                        strokeLinecap='round'
                                        vectorEffect={'non-scaling-stroke'}

                                    ></line>
                                }
                            </g>
                        )
                    })}

                    {/* connector endpoints */}
                    {checkElements.map((section, index) => {
                        return (
                            <g key={index} transform={'translate(' + (section.x) + ',' + section.y + ')'}>
                                <line x1={0} x2={0} y1={elementBlockHeight / 2} y2={elementBlockHeight / 2}
                                    onMouseEnter={() => [console.log('IN'), mouseOverConnectPointIndex.current = index]}
                                    onMouseLeave={() => [console.log('OUT'), mouseOverConnectPointIndex.current = null]}
                                    stroke={mouseOverConnectPointIndex.current === index ? '#29800066' : "transparent"} strokeWidth={22}
                                    strokeLinecap='round'
                                    vectorEffect={'non-scaling-stroke'}>
                                </line>
                            </g>
                        )
                    })}
                    <svg x={0} y={'93%'} height={'7%'}>
                        <rect width={'100%'} height="100%" x="0" y="0" fill="transparent" stroke='transparent' />
                        <foreignObject x={0} y={0} width={'100%'} height={'100%'}>
                            <div className='canvas-legend-container' >
                                {legendHeaders.map((headerObject, index) => {
                                    return (
                                        <p key={index} style={{ backgroundColor: sectionColors[headerObject.colorIndex], color: 'black', height: 'auto' }} >{headerObject.title}</p>
                                    )
                                })}
                            </div>
                        </foreignObject>
                    </svg>
                </svg>
            </div>
        </>)
}
