import React, { useState, useRef } from 'react'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ManIcon from '@mui/icons-material/Man';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import RuleIcon from '@mui/icons-material/Rule';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import { sectionColors } from '../Constants';
import UploadFile from '@mui/icons-material/UploadFile';
import Papa from "papaparse";
import { v4 as uuidv4 } from 'uuid';

export default function CheckSheetBuilderModal1({ name, setname, sections, setsections,
    nextColorIndex, setnextColorIndex, cleanUpDependencies, toggleAlert }) {

    const [sectionDisplayState, setsectionDisplayState] = useState([]);

    function addSection() {
        setsections(arr => [...arr, { _id: uuidv4(), title: '', colorIndex: nextColorIndex, checks: [] }])
        setsectionDisplayState(prev => [...prev, 'close'])
        setnextColorIndex(prev => {
            if (prev === 9) {
                return 0
            } else {
                return prev + 1
            }
        })
    }

    const defaultDisplayState = (index) => {
        if (sectionDisplayState[index] === undefined) {
            setsectionDisplayState(prev => [...prev, 'close'])
        }

        return sectionDisplayState[index]
    }

    function removeSection(sectionIndex) {
        const sectionId = sections[sectionIndex]._id
        setsections(arr => arr.filter((section, index) => index !== sectionIndex))
        setsectionDisplayState(arr => arr.filter((state, index) => index !== sectionIndex))
        cleanUpDependencies("sectionId", sectionId)
    }

    function addCheckToSection(type, sectionIndex) {
        const copySections = JSON.parse(JSON.stringify(sections))
        const _id = uuidv4()
        if (type === 0) {//check
            copySections[sectionIndex].checks.push({ _id, type: 0, title: '', allowNa: false, reqPicture: false, reqMeasurement: false, dependencies: [] })
        } else {//holdpoint
            copySections[sectionIndex].checks.push({ _id, type: 1, title: '', reqWirer: false, reqSupervisor: true, reqQc: true, dependencies: [] })
        }
        setsections(copySections)
    }

    function removeCheckFromSection(sectionIndex, checkIndex) {
        const checkId = sections[sectionIndex].checks[checkIndex]._id
        let copySections = [...sections]
        copySections[sectionIndex].checks = sections[sectionIndex].checks.filter((check, index) => index !== checkIndex)
        setsections(copySections)
        cleanUpDependencies("checkId", checkId)
    }

    function toggleSectionDisplayState(index) {//open close animation
        let copySectionDisplayStates = JSON.parse(JSON.stringify(sectionDisplayState))
        const elements = document.getElementsByClassName('section-expandable');
        const currentElement = elements[index];
        if (copySectionDisplayStates[index] === undefined) {
            copySectionDisplayStates[index] = "close"
        }
        if (copySectionDisplayStates[index] === "close") {
            copySectionDisplayStates[index] = "open"
            currentElement.style.transition = 'max-height 0.3s linear, opacity 0.2s linear'
        } else {
            copySectionDisplayStates[index] = "close"
            currentElement.style.transition = 'max-height 0.3s linear, opacity 0.2s linear'
        }
        setTimeout(() => {
            currentElement.style.transition = ""
        }, 300);
        setsectionDisplayState(copySectionDisplayStates)
    }

    function updateSection(value, sectionIndex) {
        let copySections = [...sections]
        copySections[sectionIndex].title = value
        setsections(copySections)
    }

    function updateSectionCheckItem(type, value, sectionIndex, checkIndex) {
        let copySections = [...sections]
        copySections[sectionIndex].checks[checkIndex][type] = value
        setsections(copySections)
    }

    const [mouseIsDown, setmouseIsDown] = useState(false);
    const sectionIndexDragged = useRef(null);
    const checkIndexDragged = useRef(null); //can be used as mouseDown aswell
    const checkItemInitialTopValue = useRef(0); //can be used as mouseDown aswell
    const mousePositionXY = useRef({})
    const elementsPopped = useRef(0); //number of elements moved up/down


    function mouseDown(event, checkItemIndex, sectionIndex) { //start move func...from SVGcontainer
        sectionIndexDragged.current = sectionIndex
        checkIndexDragged.current = checkItemIndex
        mousePositionXY.current = {
            x: event.clientX,
            y: event.clientY
        };
        let draggedElement = document.getElementById("check-item-" + checkItemIndex + '-sec-' + sectionIndex)
        const elementTop = draggedElement.getBoundingClientRect().top
        draggedElement.style.position = 'relative'
        draggedElement.style.zIndex = '999'; // Example z-index value
        draggedElement.style.boxShadow = 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
        draggedElement.style.top = elementTop;
        elementsPopped.current = 0
        checkItemInitialTopValue.current = elementTop
        setmouseIsDown(true)
    }

    function moveElement(event) { //start move func...from SVGcontainer
        if (mouseIsDown) {
            let elementContainer = document.getElementById("check-item-" + checkIndexDragged.current + '-sec-' + sectionIndexDragged.current)
            const elementTopMoveDelta = event.clientY - checkItemInitialTopValue.current - 25  //-25 to center on mouse
            elementContainer.style.top = (elementTopMoveDelta) + 'px'
            const mouseMovePopAmount = parseInt((elementTopMoveDelta) / 50) //num elements popped (...-2,-1, 0 ,1,2...)
            let indexToMove = null
            let movePosition = null
            let moveTopAmount = null
            let updatedElementsPopped = null
            if (elementTopMoveDelta > 0) {//moving down
                if (elementsPopped.current !== mouseMovePopAmount) {
                    if (elementsPopped.current < mouseMovePopAmount) {
                        indexToMove = (checkIndexDragged.current + mouseMovePopAmount)
                        movePosition = 'relative'
                        moveTopAmount = (-50) + 'px'
                        updatedElementsPopped = mouseMovePopAmount
                    } else {
                        indexToMove = (checkIndexDragged.current + elementsPopped.current)
                        movePosition = 'initial'
                        updatedElementsPopped = elementsPopped.current - 1
                    }
                }
            } else {//moving up
                if (elementsPopped.current !== mouseMovePopAmount) {
                    if (elementsPopped.current > mouseMovePopAmount) {
                        indexToMove = (checkIndexDragged.current + mouseMovePopAmount)
                        movePosition = 'relative'
                        moveTopAmount = (50) + 'px'
                        updatedElementsPopped = mouseMovePopAmount
                    } else {
                        indexToMove = (checkIndexDragged.current + elementsPopped.current)
                        movePosition = 'initial'
                        updatedElementsPopped = elementsPopped.current + 1
                    }
                }
            }
            if (indexToMove !== null && indexToMove < sections[sectionIndexDragged.current].checks.length && indexToMove >= 0) {
                let elementToMove = document.getElementById("check-item-" + indexToMove + '-sec-' + sectionIndexDragged.current)
                elementToMove.style.position = movePosition
                if (movePosition === 'relative') {
                    elementToMove.style.top = moveTopAmount
                }
                elementsPopped.current = updatedElementsPopped
            }
        }
    }

    const updateElements = () => {
        if (mouseIsDown === false) return
        setmouseIsDown(false)
        const draggedIndex = checkIndexDragged.current
        const indexMovedTo = draggedIndex + elementsPopped.current
        // Make a copy of the array using spread operator
        let copySections = [...sections];
        let sectionChecks = copySections[sectionIndexDragged.current].checks
        let elementToMove = sectionChecks.splice(draggedIndex, 1)[0]
        sectionChecks.splice(indexMovedTo, 0, elementToMove)
        // Update the state with the modified array
        setsections(copySections);
        //return dragged element style to initial
        let movedElement = document.getElementById("check-item-" + (draggedIndex) + '-sec-' + sectionIndexDragged.current)
        movedElement.style.position = 'initial'
        movedElement.style.zIndex = '1'; // Example z-index value
        movedElement.style.boxShadow = ''
        movedElement.style.top = ''
        //return moved elements style to initial
        let indexPointer = draggedIndex
        while (indexPointer !== indexMovedTo) {
            if (indexMovedTo > indexPointer) {
                indexPointer = indexPointer + 1
                let element = document.getElementById("check-item-" + (indexPointer) + '-sec-' + sectionIndexDragged.current)
                element.style.position = 'initial'
                element.style.zIndex = '1'; // Example z-index value
                element.style.boxShadow = ''
                element.style.top = ''
            } else if (indexMovedTo < indexPointer) {
                indexPointer = indexPointer - 1
                let element = document.getElementById("check-item-" + (indexPointer) + '-sec-' + sectionIndexDragged.current)
                element.style.position = 'initial'
                element.style.zIndex = '1'; // Example z-index value
                element.style.boxShadow = ''
                element.style.top = ''
            }
        }
    };


    const moveSectionAnimation = (direction, index) => {
        const elements = document.getElementsByClassName('section-item');
        const currentElement = elements[index];
        const nextElement = direction === 'up' ? elements[index - 1] : elements[index + 1];
        if (currentElement && nextElement) {
            // Inform the browser that we will change the transform property
            currentElement.style.willChange = 'transform';
            nextElement.style.willChange = 'transform';
            // Calculate the height of the elements
            const currentElementHeight = currentElement.offsetHeight;
            const nextElementHeight = nextElement.offsetHeight;
            // Set Move Distance Height for CSS Variables 
            currentElement.style.setProperty('--move-distance', `${direction === 'up' ? -nextElementHeight : nextElementHeight}px`);
            nextElement.style.setProperty('--move-distance', `${direction === 'up' ? currentElementHeight : -currentElementHeight}px`);
            // Apply the animation
            currentElement.style.animation = direction === 'up' ? 'moveUp 0.4s forwards, currentMove 0.4s forwards' : 'moveDown 0.4s forwards, currentMove 0.4s forwards';
            nextElement.style.animation = direction === 'up' ? 'moveDown 0.4s forwards' : 'moveUp 0.4s forwards';
            requestAnimationFrame(() => {
                setTimeout(() => {
                    // Move the section after the animation ends
                    moveSection(direction, index);
                    // Remove the will-change property
                    currentElement.style.willChange = '';
                    nextElement.style.willChange = '';
                    // Remove the animation
                    currentElement.style.animation = '';
                    nextElement.style.animation = '';
                    // Reset the CSS Variables
                    currentElement.style.removeProperty('--move-distance');
                    nextElement.style.removeProperty('--move-distance');
                }, 300);
            });
        }
    }

    function moveSection(direction, index) {
        let copySections = [...sections]
        let copyDisplayStates = [...sectionDisplayState]
        let tempSection
        let tempDisplayState
        if (direction === "up") {
            if (index === 0) return
            // Array index swap for display states
            tempDisplayState = copyDisplayStates[index]
            copyDisplayStates[index] = copyDisplayStates[index - 1]
            copyDisplayStates[index - 1] = tempDisplayState
            // Array index swap for sections
            tempSection = copySections[index]
            copySections[index] = copySections[index - 1]
            copySections[index - 1] = tempSection
        } else {
            if (index === sections.length - 1) return
            // Array index swap for display states
            tempDisplayState = copyDisplayStates[index]
            copyDisplayStates[index] = copyDisplayStates[index + 1]
            copyDisplayStates[index + 1] = tempDisplayState
            // Array index swap for sections
            tempSection = copySections[index]
            copySections[index] = copySections[index + 1]
            copySections[index + 1] = tempSection
        }
        // console.log(sectionDisplayState)
        setsections(copySections)
        // console.log(copyDisplayStates)
        setsectionDisplayState(copyDisplayStates)
    }

    async function csvLoaded(e) {
        if (e.target.files.length) {
            const inputFile = e.target.files[0];
            //PARSE
            if (!inputFile) return alert("Enter a valid file");
            // Initialize a reader which allows user
            // to read any file or blob.
            const reader = new FileReader();
            // Event listener on reader when the file
            // loads, we parse it and set the data.
            reader.onload = async ({ target }) => {
                const csv = Papa.parse(target.result, {
                    header: true,
                });
                const parsedData = csv?.data;
                let localNextColorIndex = nextColorIndex
                let csvInputSections = []
                try {
                    parsedData.forEach(row => {
                        let sectionIndex = csvInputSections.findIndex((section) => section.title === row.Section.trim())
                        if (sectionIndex === -1) { //section not created yet
                            csvInputSections.push({ _id: uuidv4(), title: row.Section, colorIndex: localNextColorIndex, checks: [] })
                            sectionIndex = csvInputSections.length - 1
                            if (localNextColorIndex === 9) {
                                localNextColorIndex = 0
                            } else {
                                localNextColorIndex = localNextColorIndex + 1
                            }
                        }
                        const _id = uuidv4()
                        if (row.CheckType === "Check") {//check
                            const allowNa = (row.allowNa === "TRUE") ? true : false
                            const reqPicture = (row.reqPicture === "TRUE") ? true : false
                            const reqMeasurement = (row.reqMeasurement === "TRUE") ? true : false
                            csvInputSections[sectionIndex].checks.push({ _id, type: 0, title: row.Title, allowNa, reqPicture, reqMeasurement, dependencies: [] })
                        } else if (row.CheckType === "Holdpoint") {//holdpoint
                            const reqWirer = (row.reqWirer === "TRUE") ? true : false
                            const reqSupervisor = true//always true
                            const reqQc = true//always true
                            csvInputSections[sectionIndex].checks.push({ _id, type: 1, title: row.Title, reqWirer, reqSupervisor, reqQc, dependencies: [] })
                        }
                    });
                    setnextColorIndex(localNextColorIndex)
                    setsections(prev => [...prev, ...csvInputSections])
                } catch (error) {
                    toggleAlert(true, 0, "Invalid file structure")
                }
            };
            reader.readAsText(inputFile);
        }
    }

    const inputFileRef = useRef(null);
    const uploadClicked = () => {
        inputFileRef.current.click();
    };


    return (
        <>
            {/* body container */}
            <div className="sheet-name-input-container">
                <label htmlFor="name" className="col-form-label">Sheet Name:</label>
                <input value={name} type="text" autoComplete='off' placeholder='' className="modal-input" name="name" id='name' onChange={(e) => setname(e.target.value)} />
                <button className='just-for-spacing' onClick={() => uploadClicked()} style={{ background: 'white', border: '1px solid gray', borderRadius: '5px', cursor: 'pointer' }}>
                    <input
                        type="file"
                        id="csvInput"
                        name="csvInput"
                        accept=".csv"
                        ref={inputFileRef}
                        style={{ display: "none" }}
                        onChange={(event) => csvLoaded(event)}
                    />
                    <UploadFile />
                    {/* <p>CSV</p> */}
                </button>
                {/* <span className='tooltip-text'>Upload CSV</span> */}
            </div>

            <div onMouseLeave={() => updateElements()} onMouseMove={(e) => moveElement(e)} onMouseUp={() => updateElements()} className='sections-scroll-container' id='sections-sroll-container'>
                {
                    sections?.length > 0 ?
                        <>
                            {sections.map((section, sectionIndex) => {
                                return (
                                    <div className='section-item' key={sectionIndex}>
                                        <div className='header-container' style={{ backgroundColor: sectionColors[section.colorIndex] + '66' }}>
                                            <div className='section-move-container'>
                                                <svg height="26" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <polygon onClick={() => moveSectionAnimation("up", sectionIndex)} className='section-arrow-move' points="0,11 10,0 20,11" fill='white' stroke='black' />
                                                    <polygon onClick={() => moveSectionAnimation("down", sectionIndex)} className='section-arrow-move' points="0,15 10,26 20,15" fill='white' stroke='black' />
                                                </svg>
                                            </div>
                                            <input className='section-header-text' onChange={(e) => updateSection(e.target.value, sectionIndex)} value={section.title} placeholder='Section Title...'></input>
                                            <div className='remove-check-button' onClick={() => removeSection(sectionIndex)}>
                                                <DeleteSweepIcon sx={{ color: 'black', fontSize: '24px' }} />
                                            </div>
                                        </div>
                                        {/* <div className={'body-container section-' + sectionDisplayState[sectionIndex]}> */}
                                        <div className={'body-container section-expandable section-' + defaultDisplayState(sectionIndex)} style={{ "--my-css-var": `${((section.checks.length) * 51) + 100}px` }}>
                                            {section.checks.map((check, checkIndex) => {
                                                return (
                                                    <div id={'check-item-' + checkIndex + '-sec-' + sectionIndex} className='check-item' key={checkIndex} style={{ backgroundColor: check.type === 0 ? "whitesmoke" : '#fcdada' }}>
                                                        <div className='check-item-start'>
                                                            <DragIndicatorIcon onMouseDown={(e) => mouseDown(e, checkIndex, sectionIndex)} sx={{ cursor: mouseIsDown ? 'grabbing' : 'grab' }} />
                                                            <input onChange={(e) => updateSectionCheckItem("title", e.target.value, sectionIndex, checkIndex)} className='check-title' value={check.title}
                                                                placeholder={check.type === 0 ? 'Check...' : 'Hold Point...'}></input>
                                                        </div>
                                                        {check.type === 0 ? <>
                                                            <div className='section-check-input-container' onClick={() => updateSectionCheckItem("allowNa", !check.allowNa, sectionIndex, checkIndex)}>

                                                                <div className='section-text'>
                                                                    <RuleIcon sx={{ fontSize: '18px', color: 'var(--primary-color)' }} />
                                                                    {"Allow N/A "}
                                                                </div>
                                                                {check.allowNa ?
                                                                    <CheckCircleIcon sx={{ fontSize: '18px', color: 'var(--primary-color)' }} /> : <CircleIcon sx={{ fontSize: '18px', color: 'white' }} />}
                                                            </div>
                                                            <div className='section-check-input-container' onClick={() => updateSectionCheckItem("reqPicture", !check.reqPicture, sectionIndex, checkIndex)}>

                                                                <div className='section-text'>
                                                                    <InsertPhotoIcon sx={{ fontSize: '18px', color: 'var(--primary-color)' }} />
                                                                    {"Picture Req. "}
                                                                </div>
                                                                {check.reqPicture ?
                                                                    <CheckCircleIcon sx={{ fontSize: '18px', color: 'var(--primary-color)' }} /> : <CircleIcon sx={{ fontSize: '18px', color: 'white' }} />}
                                                            </div>
                                                            <div className='section-check-input-container' onClick={() => updateSectionCheckItem("reqMeasurement", !check.reqMeasurement, sectionIndex, checkIndex)}>
                                                                <div className='section-text'>
                                                                    <SquareFootIcon sx={{ fontSize: '18px', color: 'var(--primary-color)' }} />
                                                                    {"Meas. Req. "}
                                                                </div>
                                                                {check.reqMeasurement ?
                                                                    <CheckCircleIcon sx={{ fontSize: '18px', color: 'var(--primary-color)' }} /> : <CircleIcon sx={{ fontSize: '18px', color: 'white' }} />}
                                                            </div>
                                                        </> :
                                                            <>

                                                                <div className='section-check-input-container' style={{ backgroundColor: 'transparent', cursor: 'default' }} >
                                                                    <div className='section-text'>
                                                                        <ManIcon sx={{ fontSize: '18px', color: 'var(--primary-color)' }} />
                                                                        {"Supervisor "}
                                                                    </div>
                                                                    {check.reqSupervisor ?
                                                                        <CheckCircleIcon sx={{ fontSize: '18px', color: 'var(--primary-color)' }} /> : <CircleIcon sx={{ fontSize: '18px', color: 'white' }} />}
                                                                </div>
                                                                <div className='section-check-input-container' style={{ backgroundColor: 'transparent', cursor: 'default' }} >
                                                                    <div className='section-text'>
                                                                        <FindInPageIcon sx={{ fontSize: '18px', color: 'var(--primary-color)' }} />
                                                                        {"QC "}
                                                                    </div>
                                                                    {check.reqQc ?
                                                                        <CheckCircleIcon sx={{ fontSize: '18px', color: 'var(--primary-color)' }} /> : <CircleIcon sx={{ fontSize: '18px', color: 'white' }} />}
                                                                </div>
                                                                <div className='section-check-input-container' onClick={() => updateSectionCheckItem("reqWirer", !check.reqWirer, sectionIndex, checkIndex)}>
                                                                    <div className='section-text'>
                                                                        <ManageAccountsIcon sx={{ fontSize: '18px', color: 'var(--primary-color)' }} />
                                                                        {"Wirer "}
                                                                    </div>
                                                                    {check.reqWirer ?
                                                                        <CheckCircleIcon sx={{ fontSize: '18px', color: 'var(--primary-color)' }} /> : <CircleIcon sx={{ fontSize: '18px', color: 'white' }} />}
                                                                </div>
                                                            </>
                                                        }
                                                        <CloseIcon onClick={() => removeCheckFromSection(sectionIndex, checkIndex)} sx={{ color: 'darkgray', fontSize: '18px', cursor: 'pointer' }} />
                                                    </div>
                                                )
                                            })}
                                            <div className='section-check-button-container'>
                                                <button className='add-check-button' onClick={() => addCheckToSection(1, sectionIndex)}>+ Hold Point</button>
                                                <button className='add-check-button' onClick={() => addCheckToSection(0, sectionIndex)}>+ Check</button>
                                            </div>
                                        </div>
                                        <div className='section-display-toggle-button' onClick={() => toggleSectionDisplayState(sectionIndex)}>
                                            {sectionDisplayState[sectionIndex] === "close" ?
                                                <ArrowDropDownIcon fontSize='inherit' sx={{ color: 'white' }} /> :
                                                <ArrowDropUpIcon fontSize='inherit' sx={{ color: 'white' }} />
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </> :
                        <p className='no-data-text'>--Add a Section--</p>
                }
            </div>
            <button className='add-section-button' onClick={() => addSection()}>+ Section</button>
        </>)
}
