import { fqcConfig } from "../ApiConfig"

export async function getAllGroups() {
    return fetch(fqcConfig.url + '/groups', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        }
    }).then(res => res.json())
        .then(data => {
            return data.data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function addGroup(groupProps) {
    return fetch(fqcConfig.url + '/groups', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: JSON.stringify(groupProps)
    }).then(res => res.json())
        .then(data => {
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}
