import React, { useState, useEffect } from 'react'
import './styling/PullupDetailsContainer.css'
import DetailsBoxContainer from '../components/DetailsBoxContainer';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ChecksheetDetailsContainer from '../components/ChecksheetDetailsContainer';
import { getJobUnitsById } from '../api/sqc/JobsAPI';
import { Oval } from 'react-loader-spinner';

function PullupDetailsContainer({ recordDetailsLoading, currentMenuOption, selectedListItemData, startFileDownload }) {

  const [stateId, setstateId] = useState("") // hide-show / show-hide / slide-up / slide-down
  const [nestedStateId, setnestedStateId] = useState("") // slide-up / slide-down
  const [customerNameHeader, setcustomerNameHeader] = useState("")
  const [containerDetails, setcontainerDetails] = useState([])
  const [groupedUnitsDetails, setgroupedUnitsDetails] = useState({}) //{checksheetTemplate: {name: , serialNumber: , units:{} } }
  const [jobProgressPercentage, setjobProgressPercentage] = useState(0)
  const [unitDataLoading, setunitDataLoading] = useState(false);

  useEffect(() => { //reset component state on menu option change
    setstateId("")
    setnestedStateId("")
  }, [currentMenuOption])

  useEffect(() => { //init display data
    initInterface()
  }, [selectedListItemData])

  function initInterface() {
    if (Object.keys(selectedListItemData).length > 0) { //row selected
      if (selectedListItemData.customer !== null) {
        setcustomerNameHeader(selectedListItemData.customer.name)
      }
      let formattedListData = [
        { ...selectedListItemData.contact, title: "Customer Contact" },
        { ...selectedListItemData.salesRep, title: "SalesRep" },
        { ...selectedListItemData.linedUpBy, title: "Lined Up By" },
        { ...selectedListItemData.inspectedBy, title: "Inspected by" }]
      setcontainerDetails(formattedListData)
      if ((stateId === "") || (stateId === "show-hide")) {//show if hidden
        setstateId("hide-show")
      }
    } else { //no row selected
      if ((stateId === "slide-down") || stateId === "hide-show") {//hide if shown
        setstateId("show-hide")
      }
    }
  }

  async function formatAndGroupUnitData() { //group by checksheetTemplate and calc progress % if applicable
    setunitDataLoading(true)
    const unitsData = await getJobUnitsById(selectedListItemData._id)
    if (unitsData) {
      let unitGroups = {} //{63f746cce1dd546f53adb91c: {name: , serialNumber: , units:{} } }
      let totalCompletedUnits = 0
      unitsData.forEach(unit => {
        if (!(unit.checksheetTemplate in unitGroups)) {
          unitGroups[unit.checksheetTemplate] = { name: unit.name, serialNumber: unit.serialNumber, numUnitsCompleted: 0, units: [] }
        }
        let progress = 0
        if (unit.status === 1) {//in progress: get percentage
          let numCheckItems = unit.checkItems.length
          let numCheckItemsPassed = 0
          for (let index = 0; index < unit.checkItems.length; index++) {
            const check = unit.checkItems[index];
            if (check.passed === true) {
              numCheckItemsPassed = numCheckItemsPassed + 1
            }
          }
          progress = Math.round((numCheckItemsPassed / numCheckItems * 100) * 100) / 100
        } else if ((unit.status === 3)) { //pass
          unitGroups[unit.checksheetTemplate].numUnitsCompleted = unitGroups[unit.checksheetTemplate].numUnitsCompleted + 1
          totalCompletedUnits = totalCompletedUnits + 1
        }
        unitGroups[unit.checksheetTemplate].units.push({ ...unit, progress })
      });
      const jobProgressPerc = Math.round((totalCompletedUnits / unitsData.length * 100) * 100) / 100
      setjobProgressPercentage(jobProgressPerc)
      setgroupedUnitsDetails(unitGroups)
      setunitDataLoading(false)
    }
  }

  function toggleContainerState() {
    if (nestedStateId === "slide-up") {
      setstateId("slide-down") //full container
      setnestedStateId("slide-down") //body
    } else {
      formatAndGroupUnitData()

      setstateId("slide-up") //full container
      setnestedStateId("slide-up") //body
    }
  }

  return (
    <div className='pullup-details-container' id={stateId} >
      <div className='pullup-toggle-button' onClick={() => toggleContainerState()}>
        {nestedStateId === "slide-up" ?
          <ArrowDropDownIcon fontSize='inherit' /> :
          <ArrowDropUpIcon fontSize='inherit' />
        }
      </div>
      <div className='pullup-details-body' id={"body-" + nestedStateId}>
        <div className='start-body-container' id={"start-" + nestedStateId}>
          {containerDetails.map((containerProps, index) => {
            let width = "21%"
            if (nestedStateId === "slide-up") width = "100%"
            return (
              <DetailsBoxContainer
                key={index}
                recordDetailsLoading={recordDetailsLoading}
                props={containerProps}
                width={width} />)
          })}
        </div>
        <div className='end-body-container' id={"end-" + nestedStateId}>
          <h1 style={{ margin: '12px' }}>{customerNameHeader}</h1>
          <div className='checksheets-scroll-container'>
            {unitDataLoading ?
              <Oval
                visible={true}
                height="42"
                width="42"
                color="var(--accent-color)"
                secondaryColor="var(--secondary-color)"
                ariaLabel="oval-loading"
                wrapperStyle={{ marginTop: '3%' }}
                wrapperClass=""
              />
              :
              <>
                {Object.keys(groupedUnitsDetails).map((unitGroup, index) => {
                  return (<ChecksheetDetailsContainer
                    key={index}
                    props={groupedUnitsDetails[unitGroup]}
                    jobID={selectedListItemData._id}
                    startFileDownload={startFileDownload} />)
                })}
              </>
            }
          </div>
          <div className='progress-bar-container'>
            <h4>{jobProgressPercentage + "%"}</h4>
            <div className='progress-bar' style={{ width: jobProgressPercentage + "%" }}>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PullupDetailsContainer