import { sqcConfig } from "../ApiConfig"

export async function getSQCMedia(queryParams) {
    return fetch(sqcConfig.url + '/api/media' + queryParams, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        }
    }).then(res => res.json())
        .then(data => {
            return data.data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}