import { fqcConfig } from "../ApiConfig"

export async function getAllJobs(state) {
    //state {("": allJobs), "?state=ACTIVE", ?state=REWORK, ?state=HOSPITAL, ?state=COMPLETED}
    return fetch(fqcConfig.url + '/jobs' + state, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        }
    }).then(res => res.json())
        .then(data => {
            return data.data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function addJob(jobProps) {
    return fetch(fqcConfig.url + '/jobs', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: JSON.stringify(jobProps)
    }).then(res => res.json())
        .then(data => {
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function updateJob(jobId, jobProps) {
    return fetch(fqcConfig.url + '/jobs/' + jobId, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: JSON.stringify(jobProps)
    }).then(res => res.json())
        .then(data => {
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}


export async function deleteJob(jobId) {
    return fetch(fqcConfig.url + '/jobs/' + jobId, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
    }).then(res => res.json())
        .then(data => {
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function getJobCounts() {
    return fetch(fqcConfig.url + '/jobs/count', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        }
    }).then(res => res.json())
        .then(data => {
            return data.data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function getJobById(id) {
    return fetch(fqcConfig.url + '/jobs/' + id + '/checksheets', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        }
    }).then(res => res.json())
        .then(data => {
            return data.data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}

export async function generateCheckSheet(jobId) {
    return fetch(fqcConfig.url + '/jobs/' + jobId + '/checksheets/generatePdf', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
    }).then(res => res.json())
        .then(data => {
            return data
        }).catch((error) =>
            console.log("Fetch Error: " + error)
        )
}