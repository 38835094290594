import React, { useRef } from 'react'
import './styling/GridListContainer.css'
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ConstructionIcon from '@mui/icons-material/Construction';


function GridBoxWithHeader({ setSelectListItem, indexID, data, toggleModal, editButtonClicked }) {

    //MoreDetails functions
    const showMoreDetails = useRef(false)
    function showDetails(event) {
        let main = document.getElementById('grid-box-with-header-container' + indexID)
        main.style.boxShadow = "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px";
        let container = document.getElementById('grid-box-header-details-container' + indexID)
        container.style.display = "flex";
        showMoreDetails.current = true;
    }
    function hideDetails() {
        if (!showMoreDetails.current) return
        let main = document.getElementById('grid-box-with-header-container' + indexID)
        main.style.boxShadow = "0 0 0 0";
        let container = document.getElementById('grid-box-header-details-container' + indexID)
        container.style.display = "none";
        showMoreDetails.current = false;
    }

    function deleteButtonClicked() {
        toggleModal(true, 7)
    }

    return (
        <div className='grid-box-with-header-container' id={'grid-box-with-header-container' + indexID}
            onMouseOver={() => showDetails()} onMouseOut={() => hideDetails()} onClick={() => setSelectListItem(data)}>
            <div className='grid-box-header'>
                <h4 style={{ paddingTop: '6px' }}>
                    {data.type === 0 ?
                        <AttachMoneyIcon sx={{ color: '#009c9e', fontSize: "21px", margin: '0 3px' }} />
                        : <ConstructionIcon sx={{ color: '#009c9e', fontSize: "21px", margin: '0 3px' }} />
                    }
                    {data.name}</h4>
                <div id={'grid-box-header-details-container' + indexID} className='grid-box-details-container'
                    style={{ display: 'none', width: '50px', background: 'var(--accent-color)' }}>
                    <button onClick={() => editButtonClicked()}>
                        <EditIcon sx={{ fontSize: '18px', color: 'black' }} />
                    </button>
                    <button onClick={() => deleteButtonClicked()}>
                        <DeleteOutlinedIcon sx={{ fontSize: '18px', color: 'black' }} />
                    </button>
                </div>
            </div>
            <div className='box-body'>
                <div className='grid-info-container'>
                    <CallIcon sx={{ color: '#153154', fontSize: "18px" }} />
                    <p>{data.contactNumber}</p>
                </div>
                <div className='grid-info-container'>
                    <MailOutlineIcon sx={{ color: '#153154', fontSize: "18px" }} />
                    <p> {data.email}</p>
                </div>
            </div>
        </div>
    )
}

export default GridBoxWithHeader