
//SQC constants
const sqcmenuOptions = [
    { title: "Active Jobs", icon: 0, displayType: 'list', showCreateNewBtn: true },
    { title: "Reworks", icon: 1, displayType: 'list', showCreateNewBtn: false },
    { title: "Completed Jobs", icon: 2, displayType: 'list', showCreateNewBtn: false },
    { title: "Check Sheets", icon: 3, displayType: 'grid', showCreateNewBtn: true },
    { title: "Resources", icon: 4, displayType: 'grid-header', showCreateNewBtn: true },
    { title: "Customers", icon: 5, displayType: 'grid', showCreateNewBtn: true },
    { title: "Media", icon: 7, displayType: 'file-tree', showCreateNewBtn: false, hideSearch: true }]

export const sqclistHeaders = {
    0: ["Customer", "Job", "Date", "Lined Up By", "Inspected By", "Progress"],
    1: ["Customer", "Job", "Start Date", "End Date", "Lined Up By", "Actions"],
    2: ["Customer", "Job", "Start Date", "End Date", "Lined Up By", "Actions"]
}
export const sqclistKeys = {
    0: [["customer", "name"], "name", "createdAt", ["linedUpBy", "name"], ["inspectedBy", "name"], "progress"],
    1: [["customer", "name"], "name", "createdAt", "updatedAt", ["linedUpBy", "name"], "actions"],
    2: [["customer", "name"], "name", "createdAt", "updatedAt", ["linedUpBy", "name"], "actions"],
}

//FQC constants
const fqcmenuOptions = [
    {
        title: "Active Jobs", icon: 0, displayType: 'list-expand', showCreateNewBtn: true,
        searchKeys: ["serialNumber", ["checksheetTemplate", "name"], ["group", "name"], ["customer", "name"]]
    },
    { title: "Reworks", icon: 1, displayType: 'list-expand', showCreateNewBtn: false },
    { title: "Hospital", icon: 6, displayType: 'list-expand', showCreateNewBtn: false },
    { title: "Completed Jobs", icon: 2, displayType: 'list-expand', showCreateNewBtn: false },
    { title: "Check Sheets", icon: 3, displayType: 'grid', showCreateNewBtn: true },
    { title: "Resources", icon: 4, displayType: 'list', showCreateNewBtn: true },
    { title: "Customers", icon: 5, displayType: 'grid', showCreateNewBtn: true },
    { title: "Media", icon: 7, displayType: 'file-tree', showCreateNewBtn: false, hideSearch: true }]

export const fqclistHeaders = {
    0: ["Serial No.", "Checksheet", "Job Name", "Customer", "Start Date", "Progress"],
    1: ["Serial No.", "Checksheet", "Job Name", "Customer", "Start Date", "End Date"],
    2: ["Serial No.", "Checksheet", "Job Name", "Customer", "Start Date", "End Date"],
    3: ["Serial No.", "Checksheet", "Job Name", "Customer", "Start Date", "End Date"],
    5: ["Name", "Email", "Sup Checks", "QC Checks", "Rework"]
}
export const fqclistKeys = {
    0: ["serialNumber", ["checksheetTemplate", "name"], ["group", "name"], ["customer", "name"], "createdAt", "progress"],
    1: ["serialNumber", ["checksheetTemplate", "name"], ["group", "name"], ["customer", "name"], "createdAt", "endDate"],
    2: ["serialNumber", ["checksheetTemplate", "name"], ["group", "name"], ["customer", "name"], "createdAt", "endDate"],
    3: ["serialNumber", ["checksheetTemplate", "name"], ["group", "name"], ["customer", "name"], "createdAt", "endDate"],
    5: ["name", "email", ["permissions", "supervisor"], ["permissions", "qc"], ["permissions", "rework"]],
}

export const menuOptions = { "sqc": sqcmenuOptions, "fqc": fqcmenuOptions }


const conceptOptionStructure = [
    {
        title: "Active Jobs", icon: 0, displayType: 'list-expand',
        searchKeys: [],
        columns: [
            { header: "Serial No.", key: "serialNumber", searchable: true },
            { header: "Checksheet", key: ["checksheetTemplate", "name"], searchable: true },
            { header: "Job Name", key: ["group", "name"], searchable: true },
            { header: "Customer", key: ["customer", "name"], searchable: true },
            { header: "Start Date", key: "startDate", searchable: false },
            { header: "Progress", key: "progress", searchable: false }
        ], showCreateNewBtn: true
    },
    {
        title: "Reworks", icon: 1, displayType: 'list',
        columns: [
            { header: "Serial No.", key: "serialNumber", searchable: true },
            { header: "Checksheet", key: ["checksheetTemplate", "name"], searchable: true },
            { header: "Job Name", key: ["group", "name"], searchable: true },
            { header: "Customer", key: ["customer", "name"], searchable: true },
            { header: "Start Date", key: "startDate", searchable: false },
            { header: "End Date", key: "endDate", searchable: false }
        ], showCreateNewBtn: false
    },
    {
        title: "Hospital", icon: 6, displayType: 'list',
        columns: [
            { header: "Serial No.", key: "serialNumber", searchable: true },
            { header: "Checksheet", key: ["checksheetTemplate", "name"], searchable: true },
            { header: "Job Name", key: ["group", "name"], searchable: true },
            { header: "Customer", key: ["customer", "name"], searchable: true },
            { header: "Start Date", key: "startDate", searchable: false },
            { header: "End Date", key: "endDate", searchable: false }
        ], showCreateNewBtn: false
    },
    {
        title: "Completed Jobs", icon: 2, displayType: 'list',
        columns: [
            { header: "Serial No.", key: "serialNumber", searchable: true },
            { header: "Checksheet", key: ["checksheetTemplate", "name"], searchable: true },
            { header: "Job Name", key: ["group", "name"], searchable: true },
            { header: "Customer", key: ["customer", "name"], searchable: true },
            { header: "Start Date", key: "startDate", searchable: false },
            { header: "End Date", key: "endDate", searchable: false }
        ], showCreateNewBtn: false
    },
    { title: "Check Sheets", icon: 3, displayType: 'grid', showCreateNewBtn: true },
    {
        title: "Resources", icon: 4, displayType: 'list',
        columns: [
            { header: "Name", key: "name" },
            { header: "Email", key: "email" },
            { header: "Sup Checks", key: ["permissions", "supervisor"] },
            { header: "QC Checks", key: ["permissions", "qc"] },
            { header: "Rework", key: ["permissions", "rework"] },
            { header: "Hospital", key: ["permissions", "hospital"] },
        ], showCreateNewBtn: true
    },
    { title: "Customers", icon: 5, displayType: 'grid', showCreateNewBtn: true },
    { title: "Media", icon: 7, displayType: 'file-tree', showCreateNewBtn: false }]