import React, { useState } from 'react';

export default function useToken() {
    const getToken = () => {
        const tokenString = sessionStorage.getItem('token');
        const userToken = tokenString;
        return userToken
    };
    const [token, setToken] = useState(getToken());
    const saveToken = userToken => {
        sessionStorage.setItem('token', userToken);
        setToken(userToken);
    };


    const getType = () => {
        const userTypeString = sessionStorage.getItem('type');
        const userType = userTypeString;
        return userType
    };
    const [type, setType] = useState(getType());
    const saveUserType = userType => {
        sessionStorage.setItem('type', userType);
        setType(userType);
    };

    return {
        setToken: saveToken,
        token,
        setType: saveUserType,
        type
    }
}