import React, { useState, useEffect } from 'react'
import './styling/Dashboard.css'
import './styling/DashboardAnimation.css'
//Main component imports
import TopToolbar from '../layouts/TopToolbar'
import SideMenu from '../layouts/SideMenu'
import OverviewContainer from '../layouts/OverviewContainer'
//Dialog imports (modals+alert)
import FactoryJobAddEditModal from '../modals/FactoryJobAddEditModal'
import ChecksheetBuilderMain from '../modals/ChecksheetBuilder/ChecksheetBuilderMain'
import ResourceAddEditModal from '../modals/ResourceAddEditModal'
import CustomerAddEditModal from '../modals/CustomerAddEditModal'
import CustomConfirmModal from '../modals/CustomConfirmModal'
import CustomAlert from '../modals/CustomAlert'
//API imports
import { getAllJobs, addJob, updateJob, deleteJob, getJobCounts, generateCheckSheet } from '../api/fqc/JobsAPI'
import { getAllGroups } from '../api/fqc/GroupsAPI'
import { getAllChecksheets, addChecksheet, updateChecksheet, deleteChecksheet } from '../api/fqc/ChecksheetAPI'
import { addResource, updateResource, deleteResource, getResourceById } from '../api/sqc/ResourceAPI'
import { getAllCustomers, addCustomer, updateCustomer, deleteCustomer, getCustomerById } from '../api/sqc/CustomerAPI'
import { getAllResources } from '../api/fqc/ResourceAPI'
import { downloadFileFrom, getIssueMedia } from '../api/sqc/FilesAPI'

function FQCDashboard({ logout, message, qcDashboardActive, changeActiveDashboard }) {
    //search and filter
    const [searchTerm, setsearchTerm] = useState("")
    //selection vars
    const [currentMenuOption, setcurrentMenuOption] = useState(0)
    // const [selectedListData, setselectedListData] = useState([])
    const [selectedListItemData, setselectedListItemData] = useState({}) //list item selected {}
    //Dialogs
    const [showJobModal, setshowJobModal] = useState(false)
    const [showCheckSheetBuilderModal, setshowCheckSheetBuilderModal] = useState(false)
    const [showResourceModal, setshowResourceModal] = useState(false)
    const [showCustomerAddEditModal, setshowCustomerAddEditModal] = useState(false)
    //-->modal data
    const [currentModalData, setcurrentModalData] = useState({}) //save input data when >1 modals 
    const [modalInEditMode, setmodalInEditMode] = useState(false)
    //-->Alert/Confirm 
    const [showCustomConfirmModal, setshowCustomConfirmModal] = useState(false)
    const [showCustomAlert, setshowCustomAlert] = useState(false)
    const [alertProps, setalertProps] = useState({ alertType: 0, message: "" })
    //DB data
    const [jobMetaData, setjobMetaData] = useState({ newCompleted: [], newReworks: [] })//for new(recent) completed/rework   {newCompleted:[],newRework:[]}
    const [jobGroups, setjobGroups] = useState([]);
    const [activeJobs, setactiveJobs] = useState([])
    const [reworkJobs, setreworkJobs] = useState([])
    const [hospitalJobs, sethospitalJobs] = useState([]);
    const [completedJobs, setcompletedJobs] = useState([])
    const [checksheets, setchecksheets] = useState([])
    const [resources, setresources] = useState([])
    const [customers, setcustomers] = useState([])
    const [media, setmedia] = useState([]);
    //-->data counts
    const [dbDataCounts, setdbDataCounts] = useState({ 0: 0, 1: 0, 2: 0, 3: 0 }) //for sidemenu display {activeJobs, reworks, completedJobs}
    //selected record refresh trigger
    const [refreshSelectedListItem, setrefreshSelectedListItem] = useState(false)
    //loading spinner (media downloads)
    const [topSpinnerIsLoading, settopSpinnerIsLoading] = useState(false)
    const [pageDataLoading, setpageDataLoading] = useState(false);

    const [checksheetsLoading, setchecksheetsLoading] = useState(false);
    const [resourcesLoading, setresourcesLoading] = useState(false);
    const [customersLoading, setcustomersLoading] = useState(false);
    const [newJobAlertShow, setnewJobAlertShow] = useState({ rework: false, hospital: false, completed: false });
    //For menu index pointer
    const dataLists = [activeJobs, reworkJobs, hospitalJobs, completedJobs, checksheets, resources, customers, media]

    useEffect(() => { //init get DB data
        getMetaDataDB()
        readDataDB(0, true) //active jobs
        readDataDB(4, false) //checksheets
        readDataDB(6, false) //customers
    }, [])

    const [updatedCheckSocketData, setupdatedCheckSocketData] = useState({});

    useEffect(() => {//Web socket messages trigger
        // console.log("FQC" + JSON.stringify(message))
        if ((message === undefined) || (message.type === "")) return
        const messageData = message.data
        if (message.type === "progressCheck") { //-1 job on active / +1 job on rework
            if (currentMenuOption < 4) {//is on jobs
                const jobIndex = dataLists[currentMenuOption].findIndex((job) => job._id === messageData?.jobId)
                if (jobIndex > -1) { //if job displayed on dashboard (in dataList[currentMenuOption])
                    const selectedJob = dataLists[currentMenuOption][jobIndex]
                    setupdatedCheckSocketData(messageData)
                } else {
                    return
                }
            }
        }
        if (message.type === "jobStateUpdated") {//-1 job on active / +1 job on completed
            handleJobStateUpdate(messageData)
        }
    }, [message])

    useEffect(() => { //Triggered by setrefreshSelectedListItem( true )
        if (refreshSelectedListItem) {
            const index = dataLists[currentMenuOption].findIndex(obj => obj._id === selectedListItemData._id)
            setSelectListItem(dataLists[currentMenuOption][index], true)
            setrefreshSelectedListItem(false)
        }
    }, [refreshSelectedListItem])

    function handleJobStateUpdate(messageData) {
        const jobCounts = messageData.jobCounts
        const countMetaData = { 0: jobCounts.active, 1: jobCounts.rework, 2: jobCounts.hospital, 3: jobCounts.complete }
        setdbDataCounts({ ...countMetaData })
        const dataKeys = ["ACTIVE", "REWORK", "HOSPITAL", "COMPLETE"]
        if (messageData.updatedJobState === "REWORK") {//bell alert icon show
            setnewJobAlertShow(prev => ({ ...prev, rework: true }))
        }
        if (messageData.updatedJobState === "HOSPITAL") {//bell alert icon show
            setnewJobAlertShow(prev => ({ ...prev, hospital: true }))
        }
        if (messageData.updatedJobState === "COMPLETE") {//bell alert icon show
            setnewJobAlertShow(prev => ({ ...prev, completed: true }))
        }
        if (currentMenuOption < 4) { //jobs tab open
            if (dataKeys[currentMenuOption] === messageData.oldJobState) {
                switch (currentMenuOption) {
                    case 0:
                        setactiveJobs(prev => prev.filter(job => job._id !== messageData.job.jobId))
                        break;
                    case 1:
                        setreworkJobs(prev => prev.filter(job => job._id !== messageData.job.jobId))
                        break;
                    case 2:
                        sethospitalJobs(prev => prev.filter(job => job._id !== messageData.job.jobId))
                        break;
                    case 3:
                        setcompletedJobs(prev => prev.filter(job => job._id !== messageData.job.jobId))
                        break;
                    default:
                        break;
                }
            } else if (dataKeys[currentMenuOption] === messageData.updatedJobState) {
                //add
                switch (currentMenuOption) {
                    case 0:
                        setactiveJobs(prev => [...prev, messageData.job])
                        break;
                    case 1:
                        setreworkJobs(prev => [...prev, messageData.job])
                        break;
                    case 2:
                        sethospitalJobs(prev => [...prev, messageData.job])
                        break;
                    case 3:
                        setcompletedJobs(prev => [...prev, messageData.job])
                        break;
                    default:
                        break;
                }
            }
        }
    }

    //GET data from DB functions 
    async function readDataDB(listNum, setDisplayList) { //get list data from DB
        if (setDisplayList) setpageDataLoading(true)
        switch (listNum) {
            case 0:
                const allJobGroups = await getAllGroups()
                setjobGroups(allJobGroups)
                const allActiveJobs = await getAllJobs("?state=ACTIVE")
                setactiveJobs(allActiveJobs)
                break;
            case 1:
                const allReworkJobs = await getAllJobs("?state=REWORK")
                setreworkJobs(allReworkJobs)
                break;
            case 2:
                const allHospitalJobs = await getAllJobs("?state=HOSPITAL")
                sethospitalJobs(allHospitalJobs)
                break;
            case 3:
                const allCompletedJobs = await getAllJobs("?state=COMPLETE")
                setcompletedJobs(allCompletedJobs)
                break;
            case 4:
                if (!setDisplayList) setchecksheetsLoading(true)//loader for AddJobModal
                const allChecksheets = await getAllChecksheets()
                if (allChecksheets) setchecksheets(allChecksheets)
                if (!setDisplayList) setchecksheetsLoading(false)
                break;
            case 5:
                if (!setDisplayList) setresourcesLoading(true)//loader for AddJobModal
                const allResources = await getAllResources()
                if (allResources) setresources(allResources)
                if (!setDisplayList) setresourcesLoading(false)
                break;
            case 6:
                if (!setDisplayList) setcustomersLoading(true)//loader for AddJobModal
                const allCustomers = await getAllCustomers()
                if (allCustomers) setcustomers(allCustomers)
                if (!setDisplayList) setcustomersLoading(false)
                break;
            case 7:
                const allMedia = []
                setmedia(allMedia)
                break;
            default:
                break;
        }
        if (setDisplayList) setpageDataLoading(false)

    }

    async function getMetaDataDB() {
        const jobCounts = await getJobCounts()
        const countMetaData = { 0: jobCounts.active, 1: jobCounts.rework, 2: jobCounts.hospital, 3: jobCounts.complete }
        setdbDataCounts({ ...countMetaData })
    }

    //Selection functions
    function selectMenuOption(index) {//select list to display from side menu
        setSelectListItem(null)//clear selected item
        // setpageDataLoading(true)
        readDataDB(index, true)
        setcurrentMenuOption(index)
        setsearchTerm("")
    }

    function setSelectListItem(props, refresh) {
        if (refresh) {
            setselectedListItemData(props)
            return
        }
        if (props === null) { //clear
            setselectedListItemData({})
            return
        }
        if ((props._id === selectedListItemData._id) && currentMenuOption < 3) { //deselect if already selected and not grid
            setselectedListItemData({})
        } else { //else select
            setselectedListItemData(props)
        }
    }

    //CRUD functions (+submits)
    async function modalOnSubmit(modalData, shouldCallAPI, formFormat) { //submit create record
        const sideMenuIndex = currentMenuOption //Save to prevent change during async operation
        let updatedModalData = ""
        if (formFormat === 1) {
            updatedModalData = modalData
        } else if (formFormat === 2) {
            updatedModalData = currentModalData
            // console.log("UPDATE: " + JSON.stringify(modalData.units))
            updatedModalData.append("units", JSON.stringify(modalData.units))
        } else {
            updatedModalData = JSON.parse(JSON.stringify(currentModalData))
            updatedModalData = { ...updatedModalData, ...modalData }
        }
        if (shouldCallAPI) {
            //call api
            let apiData = {}
            settopSpinnerIsLoading(true)
            if (modalInEditMode) {
                apiData = await updateDataDB(updatedModalData, sideMenuIndex)
            } else {
                apiData = await createDataDB(updatedModalData, sideMenuIndex)
            }
            settopSpinnerIsLoading(false)
            setcurrentModalData({})
            if (apiData.success) { //update display data
                await readDataDB(currentMenuOption, true)
                if (currentMenuOption === 0) {
                    getMetaDataDB()
                }
                if (modalInEditMode) {
                    setrefreshSelectedListItem(true)
                }
                toggleAlert(true, 2, apiData.message)
            } else {
                let message = "Data error occurred, please try again"
                if (apiData?.errors?.[0]) {
                    message = apiData.errors[0]
                }
                toggleAlert(true, 0, message)
            }
        } else {
            setcurrentModalData(updatedModalData)
        }
    }

    async function submitDelete() { //submit delete record
        settopSpinnerIsLoading(true)
        const apiData = await deleteDataDB()
        settopSpinnerIsLoading(false)
        if (apiData.success) { //update display data
            readDataDB(currentMenuOption, true)
            toggleAlert(true, 2, apiData.message)
        } else {
            toggleAlert(true, 0, "Data error occurred, please try again")
        }
    }

    function createDataDB(updatedModalData) {//create record
        switch (currentMenuOption) {
            case 0:
                return addJob(updatedModalData)
            case 1:
                return false
            case 2:
                return false
            case 3:
                return false
            case 4:
                return addChecksheet(updatedModalData)
            case 5:
                return addResource(updatedModalData)
            case 6:
                return addCustomer(updatedModalData)
            default:
                break;
        }
        setcurrentModalData({}) //clear saved modal data
    }
    async function updateDataDB(updatedModalData, sideMenuIndex) {
        const selectedItemID = selectedListItemData._id
        switch (sideMenuIndex) {
            case 0:
                return updateJob(selectedItemID, updatedModalData)
            case 1:
                return false
            case 2:
                return false
            case 3:
                return false
            case 4:
                return updateChecksheet(selectedItemID, updatedModalData)
            case 5:
                return updateResource(selectedItemID, updatedModalData)
            case 6:
                return updateCustomer(selectedItemID, updatedModalData)
            default:
                break;
        }
        setcurrentModalData({}) //clear saved modal data
        setmodalInEditMode(false)//clear edit mode
    }
    function deleteDataDB() {//delete record
        const selectedItemID = selectedListItemData._id
        switch (currentMenuOption) {
            case 0:
                return deleteJob(selectedItemID)
            case 1:
                return false
            case 2:
                return false
            case 3:
                return false
            case 4:
                return deleteChecksheet(selectedItemID)
            case 5:
                return deleteResource(selectedItemID)
            case 6:
                return deleteCustomer(selectedItemID)
            default:
                break;
        }
    }
    //Toggle dialogs functions
    function toggleModal(shouldOpen, modalID) { //open/close for all modals
        switch (modalID) {
            case 0: //JobModal
                setshowJobModal(shouldOpen)
                shouldOpen === false && setcurrentModalData({}) //clear saved modal data (sub modal)
                break;
            case 1: //none
                break;
            case 2: //none
                break;
            case 3: //ChecksheetModal
                setshowCheckSheetBuilderModal(shouldOpen)
                break;
            case 4://ResourceModal
                setshowResourceModal(shouldOpen)
                break;
            case 5://CustomerAddEditModal
                setshowCustomerAddEditModal(shouldOpen)
                break;
            case 6: //none
                break;
            case 7://CustomConfirmModal
                setshowCustomConfirmModal(shouldOpen)
                break;
            default:
                break;
        }
        if ((shouldOpen === false) && (modalID !== 1) && (modalInEditMode)) setmodalInEditMode(false)//clear edit mode
    }
    function toggleAlert(show, alertType, message) {
        //Type: {0: red, 1: blue, 2: green}
        setshowCustomAlert(show)
        if (show) {
            setalertProps({ alertType, message })
        }
    }

    function editButtonClicked() {
        setmodalInEditMode(true)
        let modalIndex = currentMenuOption
        if (currentMenuOption > 0) {
            modalIndex = modalIndex - 1
        }
        toggleModal(true, modalIndex)
    }

    async function generateButtonClicked(jobId) {
        settopSpinnerIsLoading(true)
        const response = await generateCheckSheet(jobId)
        if (response.success) {
            toggleAlert(true, 2, "Generate Checksheet request sent, please check your inbox")
        }
        settopSpinnerIsLoading(false)
    }

    async function startFileDownload(url) {
        //start loading spinner
        settopSpinnerIsLoading(true)
        //pass param to stop loading spinner
        await downloadFileFrom(url, settopSpinnerIsLoading)
    }

    async function dismissRecentRecords() {
        const copyJobMetaData = JSON.parse(JSON.stringify(jobMetaData))
        let alertMessage = ""
        switch (currentMenuOption) {
            case 1: //rework jobs
                copyJobMetaData.newReworks = []
                alertMessage = "New rework jobs dismissed"
                break;
            case 2: //completed jobs
                copyJobMetaData.newCompleted = []
                alertMessage = "Newly completed jobs dismissed"
                break;
            default:
                break;
        }
        const updatedData = { metadata: { ...copyJobMetaData } }
        // const apiData = await updateJobMetaData(updatedData)
        // if (apiData.success) { //update display data
        //     readDataDB(currentMenuOption, true)
        //     toggleAlert(true, 2, alertMessage)
        // } else {
        //     toggleAlert(true, 0, "An error occurred while updating metadata")
        // }
    }


    return (
        <div >
            <div className={'dashboard-body'}>
                <SideMenu
                    currentMenuOption={currentMenuOption}
                    selectMenuOption={selectMenuOption}
                    dbDataCounts={dbDataCounts}
                    qcDashboardActive={qcDashboardActive}
                    topSpinnerIsLoading={topSpinnerIsLoading} />
                <OverviewContainer
                    qcDashboardActive={qcDashboardActive}
                    pageDataLoading={pageDataLoading}
                    currentMenuOption={currentMenuOption}
                    setSelectListItem={setSelectListItem}
                    selectedListItemData={selectedListItemData}
                    selectedListData={dataLists[currentMenuOption]}
                    searchTerm={searchTerm}
                    setsearchTerm={setsearchTerm}
                    toggleModal={toggleModal}
                    toggleAlert={toggleAlert}
                    selectMenuOption={selectMenuOption}
                    readDataDB={readDataDB}
                    editButtonClicked={editButtonClicked}
                    startFileDownload={startFileDownload}
                    jobMetaData={jobMetaData}
                    dismissRecentRecords={dismissRecentRecords}
                    logout={logout}
                    changeActiveDashboard={changeActiveDashboard}
                    topSpinnerIsLoading={topSpinnerIsLoading}
                    generateButtonClicked={generateButtonClicked}
                    updatedCheckSocketData={updatedCheckSocketData}
                    newJobAlertShow={newJobAlertShow}
                    setnewJobAlertShow={setnewJobAlertShow}
                />
            </div>
            {/* modals */}
            <FactoryJobAddEditModal
                show={showJobModal}
                settopSpinnerIsLoading={settopSpinnerIsLoading}
                jobGroups={jobGroups}
                modalInEditMode={modalInEditMode}
                toggleModal={toggleModal}
                modalOnSubmit={modalOnSubmit}
                customers={customers}
                checksheets={checksheets}
                customersLoading={customersLoading}
                checksheetsLoading={checksheetsLoading}
                selectedListItemData={selectedListItemData}
                toggleAlert={toggleAlert}
                setjobGroups={setjobGroups} />

            <ChecksheetBuilderMain
                show={showCheckSheetBuilderModal}
                modalInEditMode={modalInEditMode}
                modalOnSubmit={modalOnSubmit}
                selectedListItemData={selectedListItemData}
                toggleModal={toggleModal}
                toggleAlert={toggleAlert} />
            <ResourceAddEditModal
                forFQC={true}
                show={showResourceModal}
                modalInEditMode={modalInEditMode}
                toggleModal={toggleModal}
                modalOnSubmit={modalOnSubmit}
                selectedListItemData={selectedListItemData}
                toggleAlert={toggleAlert} />
            <CustomerAddEditModal
                show={showCustomerAddEditModal}
                modalInEditMode={modalInEditMode}
                toggleModal={toggleModal}
                modalOnSubmit={modalOnSubmit}
                selectedListItemData={selectedListItemData}
                toggleAlert={toggleAlert} />
            {/* dialogs */}
            <CustomConfirmModal
                show={showCustomConfirmModal}
                toggleModal={toggleModal}
                submitDelete={submitDelete} />
            <CustomAlert
                show={showCustomAlert}
                props={alertProps}
                toggle={toggleAlert} />
        </div>
    )
}

export default FQCDashboard