import React from 'react'

export default function MenuOptionButton({ isSelected, index, title, selectMenuOption, Icon, amount, qcDashboardActive }) {
    const selectedColor = qcDashboardActive === "sqc" ? 'var(--sqc-primary-color)' : 'var(--fqc-primary-color)'

    return (
        <div className='menu-option-button'
            style={{
                backgroundColor: isSelected ? selectedColor : 'transparent'
            }}
            onClick={() => { selectMenuOption(index) }}>
            <Icon sx={{ color: 'white', margin: '3px' }} />
            <h4 style={{ color: 'white' }}>
                {title}
            </h4>
            <h4 style={{ flex: 1, color: 'white', textAlign: 'center' }}>
                {amount}
            </h4>
        </div>
    )
}
