import React from 'react'
import './styling/List.css'


export default function ListSeperatorLine({ startOrEnd, dismissRecentRecords }) {

    if (startOrEnd === "start") {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <hr style={{ flex: 1, height: '1px', margin: 0 }} />
                <p style={{ paddingBottom: '3px', color: '#a3a3a3', fontSize: '12px', margin: '3px 3px' }}>New Entries</p>
                <div style={{ flex: 1, margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <hr style={{ flex: 1, height: '1px', margin: 0 }} />
                    <button id='dismiss-btn' onClick={() => dismissRecentRecords()} >Dismiss All</button>
                </div>
            </div>)
    } else {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <hr style={{ flex: 1, height: '1px', margin: 0 }} />
                <p style={{ paddingBottom: '3px', color: '#a3a3a3', fontSize: '12px', margin: '3px 3px' }}>Former Entries</p>
                <hr style={{ flex: 1, height: '1px', margin: 0 }} />
            </div>)
    }


}
