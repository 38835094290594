import React, { useState, useEffect } from 'react'
import './styling/MediaFileTree.css'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { getFQCMedia } from '../api/fqc/MediaAPI';
import { getSQCMedia } from '../api/sqc/MediaAPI';
import { Grid } from 'react-loader-spinner';

export default function MediaFileTreeContainer({ qcDashboardActive }) {

    const [mediaLoading, setmediaLoading] = useState(false);
    const [selectedFolderObjArr, setselectedFolderObjArr] = useState([]);
    const [mediaObjectsToDisplay, setmediaObjectsToDisplay] = useState([]);

    useEffect(() => {
        getMediaFromDB("")
    }, []);

    async function getMediaFromDB(queryId) {
        setmediaLoading(true)
        let queryParams = '' //root folder id
        if (queryId !== "") {
            queryParams = '?parent=' + queryId //root folder id
        }
        let mediaRes = null
        if (qcDashboardActive === "sqc") {
            mediaRes = await getSQCMedia(queryParams)
        } else {
            mediaRes = await getFQCMedia(queryParams)
        }
        setmediaObjectsToDisplay(mediaRes)
        setmediaLoading(false)
    }

    function folderSelected(mediaObj) {
        getMediaFromDB(mediaObj._id)
        setselectedFolderObjArr(prev => [...prev, mediaObj])
    }

    function backPressed() {
        if (selectedFolderObjArr.length > 0 && selectedFolderObjArr[selectedFolderObjArr.length - 1].parent !== null) {
            getMediaFromDB(selectedFolderObjArr[selectedFolderObjArr.length - 1].parent)
        } else {
            getMediaFromDB("")
        }
        setselectedFolderObjArr(prev => prev.slice(0, -1))
    }

    function folderDirSelected(folderIndex) {
        const folderObj = selectedFolderObjArr[folderIndex]
        getMediaFromDB(folderObj._id)
        setselectedFolderObjArr(prev => prev.slice(0, folderIndex + 1))
    }

    return (
        <div className='file-tree-container' >
            <div className='file-tree-header'>
                <ArrowCircleLeftOutlinedIcon onClick={() => backPressed()} className='back-button' />
                {selectedFolderObjArr.map((folderObj, index) => {
                    return (
                        <div key={index} className='folder-header-label'>
                            <KeyboardArrowRightIcon />
                            <h4 onClick={() => folderDirSelected(index)}>{folderObj.name}</h4>
                        </div>
                    )
                })}
            </div>
            <div className='file-tree-body' style={{ justifyContent: mediaLoading ? "center" : 'flex-start' }}>
                {mediaLoading ?
                    <Grid
                        visible={true}
                        height="40"
                        width="40"
                        color="var(--primary-color-eb)"
                        ariaLabel="grid-loading"
                        radius="12.5"
                        wrapperStyle={{ marginTop: '60px' }}
                        wrapperClass="grid-wrapper"
                    /> :
                    <>
                        {mediaObjectsToDisplay?.length > 0 ?
                            mediaObjectsToDisplay.map((mediaObj, index) => {
                                return (
                                    mediaObj.type == 0 ?
                                        <div key={index} className='folder-icon-container'>
                                            {/* folder */}
                                            <svg onClick={() => folderSelected(mediaObj)} className='folder-icon' xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 24 24" fill="none">
                                                <path d="M3 8.2C3 7.07989 3 6.51984 3.21799 6.09202C3.40973 5.71569
                                                 3.71569 5.40973 4.09202 5.21799C4.51984 5 5.0799 5 6.2 5H9.67452C10.1637
                                                  5 10.4083 5 10.6385 5.05526C10.8425 5.10425 11.0376 5.18506 11.2166 
                                                  5.29472C11.4184 5.4184 11.5914 5.59135 11.9373 5.93726L12.0627 
                                                  6.06274C12.4086 6.40865 12.5816 6.5816 12.7834 6.70528C12.9624 
                                                  6.81494 13.1575 6.89575 13.3615 6.94474C13.5917 7 13.8363 7 14.3255 
                                                  7H17.8C18.9201 7 19.4802 7 19.908 7.21799C20.2843 7.40973 20.5903 7.71569 
                                                  20.782 8.09202C21 8.51984 21 9.0799 21 10.2V15.8C21 16.9201 21 17.4802 
                                                  20.782 17.908C20.5903 18.2843 20.2843 18.5903 19.908 18.782C19.4802
                                                   19 18.9201 19 17.8 19H6.2C5.07989 19 4.51984 19 4.09202 18.782C3.71569 
                                                   18.5903 3.40973 18.2843 3.21799 17.908C3 17.4802 3 16.9201 3 15.8V8.2Z" stroke={mediaObj.name === "Stand-alone" ? "#6d9bf7" : "#f1c231"} fill={mediaObj.name === "Stand-alone" ? '#99bafd' : '#fde599'} strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <p >{mediaObj.name}</p>
                                        </div>
                                        :
                                        (
                                            mediaObj.ext === "jpeg" || mediaObj.ext === "png" ?
                                                <div key={index} className='folder-icon-container'>
                                                    {/* image file */}
                                                    <div className='img-preview-container'>
                                                        <img onDoubleClick={() => window.open(mediaObj.originalUrl)} src={mediaObj.compressedUrl}></img>
                                                    </div>
                                                    <p >{mediaObj.name}</p>
                                                </div>
                                                :
                                                <div key={index} className='folder-icon-container'>
                                                    {/* checksheet file */}
                                                    <svg className='pdf-icon' onDoubleClick={() => window.open(mediaObj.originalUrl)}
                                                        xmlns="http://www.w3.org/2000/svg" width="100px" height="82px" viewBox="0 0 24 24" fill="none">
                                                        <path d="M15 19L17 21L21 17M13 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569
                                                         3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V17.8C5 18.9201
                                                          5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984
                                                           21 7.0799 21 8.2 21H11.5M13 3L19 9M13 3V7.4C13 7.96005 13 8.24008 13.109 
                                                           8.45399C13.2049 8.64215 13.3578 8.79513 13.546 8.89101C13.7599 9 14.0399 9
                                                            14.6 9H19M19 9V13.4M9 17H11.5M9 13H15M9 9H10" stroke="#153154eb" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                    <p >{mediaObj.name}</p>
                                                </div>
                                        )
                                )
                            })
                            :
                            <p className='no-data-text'>-- Folder Empty --</p>
                        }
                    </>
                }
            </div>
        </div>
    )
}
