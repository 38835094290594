import React, { useState, useEffect } from 'react'
import './styling/Dashboard.css'
import './styling/DashboardAnimation.css'
//Main component imports
import TopToolbar from '../layouts/TopToolbar'
import SideMenu from '../layouts/SideMenu'
import OverviewContainer from '../layouts/OverviewContainer'
import PullupDetailsContainer from '../layouts/PullupDetailsContainer'
//Dialog imports (modals+alert)
import JobAddEditModal from '../modals/JobAddEditModal'
import JobAddEditSubModal from '../modals/JobAddEditSubModal'
import CheckSheetAddEditModal from '../modals/CheckSheetAddEditModal'
import ResourceAddEditModal from '../modals/ResourceAddEditModal'
import AddressIssuesModal from '../modals/AddressIssuesModal'
import CustomerAddEditModal from '../modals/CustomerAddEditModal'
import CustomConfirmModal from '../modals/CustomConfirmModal'
import CustomAlert from '../modals/CustomAlert'
import SendEmailModal from '../modals/SendEmailModal'
//API imports
import { getAllJobs, getJobById, addJob, updateJob, deleteJob, addressIssue, finishRework, getJobMetaData, updateJobMetaData, uploadJobSpecSheet, getJobCounts } from '../api/sqc/JobsAPI'
import { getAllChecksheets, addChecksheet, updateChecksheet, deleteChecksheet } from '../api/sqc/ChecksheetAPI'
import { getAllResources, addResource, updateResource, deleteResource, getResourceById } from '../api/sqc/ResourceAPI'
import { getAllCustomers, addCustomer, updateCustomer, deleteCustomer, getCustomerById } from '../api/sqc/CustomerAPI'
import { downloadFileFrom, getIssueMedia } from '../api/sqc/FilesAPI'

function SQCDashboard({ logout, message, qcDashboardActive, changeActiveDashboard }) {
    //search and filter
    const [searchTerm, setsearchTerm] = useState("")
    //selection vars
    const [currentMenuOption, setcurrentMenuOption] = useState(0)
    // const [selectedListData, setselectedListData] = useState([]) //list selected (0:Active Jobs, 1:Reworks, 2:Completed Jobs, 3:Check Sheets, 4:Resources, 5: Customers)
    const [selectedListItemData, setselectedListItemData] = useState({}) //list item selected {}
    //Dialogs
    const [showJobModal, setshowJobModal] = useState(false)
    const [showJobSubModal, setshowJobSubModal] = useState(false)
    const [showCheckSheetModal, setshowCheckSheetModal] = useState(false)
    const [showResourceModal, setshowResourceModal] = useState(false)
    const [showAddressIssuesModal, setshowAddressIssuesModal] = useState(false)
    const [showCustomerAddEditModal, setshowCustomerAddEditModal] = useState(false)
    const [showSendEmailModal, setshowSendEmailModal] = useState(false)
    //-->modal data
    const [specsheetFormData, setspecsheetFormData] = useState(null);
    const [currentModalData, setcurrentModalData] = useState({}) //save input data when >1 modals 
    const [modalInEditMode, setmodalInEditMode] = useState(false)
    //-->Alert/Confirm 
    const [showCustomConfirmModal, setshowCustomConfirmModal] = useState(false)
    const [showCustomAlert, setshowCustomAlert] = useState(false)
    const [alertProps, setalertProps] = useState({ alertType: 0, message: "" })
    //DB data
    const [jobMetaData, setjobMetaData] = useState({ newCompleted: [], newReworks: [] })//for new(recent) completed/rework   {newCompleted:[],newRework:[]}
    const [activeJobs, setactiveJobs] = useState([])
    const [reworkJobs, setreworkJobs] = useState([])
    const [completedJobs, setcompletedJobs] = useState([])
    const [checksheets, setchecksheets] = useState([])
    const [resources, setresources] = useState([])
    const [customers, setcustomers] = useState([])
    //-->data counts
    const [dbDataCounts, setdbDataCounts] = useState({ 0: 0, 1: 0, 2: 0 }) //for sidemenu display {activeJobs, reworks, completedJobs}
    //selected record refresh trigger
    const [refreshSelectedListItem, setrefreshSelectedListItem] = useState(false)//Trigger to refresh current selected record
    //loading spinners 
    const [topSpinnerIsLoading, settopSpinnerIsLoading] = useState(false)
    const [pageDataLoading, setpageDataLoading] = useState(false);
    const [recordDetailsLoading, setrecordDetailsLoading] = useState(false);
    const [checksheetsLoading, setchecksheetsLoading] = useState(false);
    const [resourcesLoading, setresourcesLoading] = useState(false);
    const [customersLoading, setcustomersLoading] = useState(false);
    //For menu index pointer
    const dataLists = [activeJobs, reworkJobs, completedJobs, checksheets, resources, customers]

    useEffect(() => { //Get initial DB data
        getMetaDataDB()
        readDataDB(0, true)//get active jobs
        readDataDB(3, false)//checksheets
        readDataDB(4, false)//resources
        readDataDB(5, false)//customer
    }, [])

    //-->WEB SOCKETS
    useEffect(() => {//Web socket messages trigger
        if ((message === undefined) || (message.type === "")) return
        if (message.type === "newRework") { //-1 job on active / +1 job on rework
            getMetaDataDB()//update counts + newReworks
            readDataDB(0, (currentMenuOption === 0)) //load updated Active Jobs (,true if list displayed)
            readDataDB(1, (currentMenuOption === 1)) //load updated Rework Jobs (,true if list displayed)
        }
        if (message.type === "newCompleted") {//-1 job on active / +1 job on completed
            getMetaDataDB()//update counts + newCompleted
            readDataDB(0, (currentMenuOption === 0)) //load updated Active Jobs (,true if list displayed)
            readDataDB(2, (currentMenuOption === 2)) //load updated Completed Jobs (,true if list displayed)
        }
        if (message.type === "updateJob") {
            if ((message?.data === undefined) || (message.data === "")) return
            realTimeJobProgress(message.data)
        }
    }, [message])

    async function realTimeJobProgress(jobId) { //Triggered by web sockets
        const updatedJob = await getJobById(jobId) //get updated job
        const copyActiveJobs = JSON.parse(JSON.stringify(activeJobs))
        const index = copyActiveJobs.findIndex(obj => obj._id === jobId)
        copyActiveJobs[index] = updatedJob
        setactiveJobs(copyActiveJobs)
        if (selectedListItemData._id === jobId) { //if job was selected-> refreshSelection
            setrefreshSelectedListItem(true)
        }
    }
    //<--WEB SOCKETS

    useEffect(() => { //Triggered by setrefreshSelectedListItem( true )
        if (refreshSelectedListItem) {
            const index = activeJobs.findIndex(obj => obj._id === selectedListItemData._id)
            setSelectListItem(activeJobs[index], true)
            setrefreshSelectedListItem(false)
        }
    }, [refreshSelectedListItem])

    //GET list data from DB - API calls 
    async function readDataDB(listNum, setDisplayList) { //get list data from DB
        if (setDisplayList) setpageDataLoading(true)
        switch (listNum) {
            case 0:
                const allActiveJobs = await getAllJobs("?state=active")
                setactiveJobs(allActiveJobs)
                break;
            case 1:
                const allReworkJobs = await getAllJobs("?state=rework")
                setreworkJobs(allReworkJobs)
                break;
            case 2:
                const allCompletedJobs = await getAllJobs("?state=complete")
                setcompletedJobs(allCompletedJobs)
                break;
            case 3:
                if (!setDisplayList) setchecksheetsLoading(true)//loader for AddJobModal
                const allChecksheets = await getAllChecksheets()
                if (allChecksheets) setchecksheets(allChecksheets)
                if (!setDisplayList) setchecksheetsLoading(false)
                break;
            case 4:
                if (!setDisplayList) setresourcesLoading(true)//loader for AddJobModal
                const allResources = await getAllResources()
                if (allResources) setresources(allResources)
                if (!setDisplayList) setresourcesLoading(false)
                break;
            case 5:
                if (!setDisplayList) setcustomersLoading(true)//loader for AddJobModal
                const allCustomers = await getAllCustomers()
                if (allCustomers) setcustomers(allCustomers)
                if (!setDisplayList) setcustomersLoading(false)
                break;
            default:
                break;
        }
        if (setDisplayList) setpageDataLoading(false)
    }

    async function getMetaDataDB() {
        const metaData = await getJobMetaData()
        // console.log(JSON.stringify(metaData))
        const jobCounts = await getJobCounts()
        const newMetadata = { newCompleted: metaData.metadata.newCompleted, newReworks: metaData.metadata.newReworks }
        const countMetaData = { 0: jobCounts.numActive, 1: jobCounts.numRework, 2: jobCounts.numCompleted }
        setjobMetaData(newMetadata)
        setdbDataCounts({ ...countMetaData })
    }

    //-->Selection functions
    function selectMenuOption(index) {//select list to display from side menu
        setSelectListItem(null)//clear selected item
        // setpageDataLoading(true)
        readDataDB(index, true)
        setcurrentMenuOption(index)
        setsearchTerm("")
    }
    function setSelectListItem(props, refresh) {
        if (refresh) {
            setselectedListItemData(props)
            getSelectedListItemData(props)
            return
        }
        if (props === null) { //clear
            setselectedListItemData({})
            return
        }
        if ((props._id === selectedListItemData._id) && currentMenuOption < 3) { //deselect if already selected and not grid
            setselectedListItemData({})
        } else { //else select
            setselectedListItemData(props)
            getSelectedListItemData(props)
        }
    }
    //Load customer + resource data for Job PullUp-container
    async function getSelectedListItemData(props) {
        if (currentMenuOption > 2) return //if not job-> return
        setrecordDetailsLoading(true)
        let updatedProps = { ...props }
        try {
            //get customer data
            if (props.customer._id) {
                const customerResponse = await getCustomerById(props.customer._id)
                if (customerResponse) {
                    updatedProps.contact = customerResponse.contactPeople[0]
                }
            }
            //Get resources data
            //-->sales rep
            if (props.salesRep._id) {
                const salesRepResponse = await getResourceById(props.salesRep._id)
                if (salesRepResponse) {
                    updatedProps.salesRep = salesRepResponse
                }
            }
            //-->Lined up by
            let linedUpResponse = {}
            if (props.linedUpBy._id) {
                linedUpResponse = await getResourceById(props.linedUpBy._id)
                if (linedUpResponse) {
                    updatedProps.linedUpBy = linedUpResponse
                }
            }
            //-->Inspected By
            if (props.inspectedBy._id) {
                if (props.linedUpBy._id === props.inspectedBy._id) { //If Lined up By === Inpected By
                    updatedProps.inspectedBy = linedUpResponse
                } else {
                    const inspectedResponse = await getResourceById(props.inspectedBy._id)
                    if (inspectedResponse) {
                        updatedProps.inspectedBy = inspectedResponse
                    }
                }
            }
        } catch (error) {
            console.log("An error occured: " + error)
        }

        setselectedListItemData(updatedProps)
        setrecordDetailsLoading(false)
    }
    //<--Selection functions

    //-->CRUD functions (+submits)
    async function modalOnSubmit(modalData, shouldCallAPI, formFormat) { //submit CREATE / UPDATE record
        const sideMenuIndex = currentMenuOption //Save to prevent change during async operation
        let updatedModalData = ""
        let formData = null
        if (formFormat === 1) {
            updatedModalData = modalData
            if (updatedModalData.specsheet !== null) {
                formData = new FormData()
                formData.append("specSheet", updatedModalData.specsheet)
                delete updatedModalData.specsheet
                setspecsheetFormData(formData)
            }
        } else if (formFormat === 2) { //adding units to job
            updatedModalData = currentModalData
            // updatedModalData = { ...updatedModalData, "units": JSON.stringify(modalData.units) }
            updatedModalData = { ...updatedModalData, "units": modalData.units }

        } else {
            updatedModalData = JSON.parse(JSON.stringify(currentModalData))
            updatedModalData = { ...updatedModalData, ...modalData }
        }
        if (shouldCallAPI) {
            //call api
            let apiData = {}
            settopSpinnerIsLoading(true)
            if (modalInEditMode) {
                apiData = await updateDataDB(updatedModalData, sideMenuIndex)
            } else {
                apiData = await createDataDB(updatedModalData, sideMenuIndex)
            }
            settopSpinnerIsLoading(false)
            if (apiData.success) { //update display data
                setcurrentModalData({}) //clear persistent data
                if (sideMenuIndex === 0 && specsheetFormData !== null) {//active job added-> upload specsheet
                    await uploadJobSpecSheet(apiData.result._id, specsheetFormData)
                    setspecsheetFormData(null)
                }
                if (!modalInEditMode && sideMenuIndex < 3) { //new job record added-> update data counts
                    getMetaDataDB()
                }
                await readDataDB(sideMenuIndex, true)
                if (modalInEditMode && sideMenuIndex < 3) {
                    setrefreshSelectedListItem(true)
                }
                toggleAlert(true, 2, apiData.message)
            } else {
                toggleAlert(true, 0, apiData.message)
                return apiData
            }
        } else {
            setcurrentModalData(updatedModalData)
        }
    }
    async function submitDelete() { //submit DELETE record
        const sideMenuIndex = currentMenuOption //Save to prevent change during async operation
        settopSpinnerIsLoading(true)
        const apiData = await deleteDataDB(sideMenuIndex)
        settopSpinnerIsLoading(false)
        if (apiData.success) { //update display data
            if (sideMenuIndex < 3) { //new job record added-> update data counts
                getMetaDataDB()
            }
            readDataDB(sideMenuIndex, true)
            toggleAlert(true, 2, apiData.message)
        } else {
            toggleAlert(true, 0, "Data error occurred, please try again")
        }
    }

    async function createDataDB(updatedModalData, sideMenuIndex) {//create record
        switch (sideMenuIndex) {
            case 0:
                return addJob(updatedModalData)

            case 1:
                return false

            case 2:
                return false

            case 3:
                return addChecksheet(updatedModalData)

            case 4:
                return addResource(updatedModalData)

            case 5:
                return addCustomer(updatedModalData)

            default:
                break;
        }
        // setcurrentModalData({}) //clear saved modal data (redundant)
    }
    async function updateDataDB(updatedModalData, sideMenuIndex) {
        setmodalInEditMode(false)//clear edit mode
        const selectedItemID = selectedListItemData._id
        switch (sideMenuIndex) {
            case 0:
                return updateJob(selectedItemID, updatedModalData)

            case 1:
                return false

            case 2:
                return false

            case 3:
                return updateChecksheet(selectedItemID, updatedModalData)

            case 4:
                return updateResource(selectedItemID, updatedModalData)

            case 5:
                return updateCustomer(selectedItemID, updatedModalData)

            default:
                break;
        }
    }
    async function deleteDataDB(sideMenuIndex) {//delete record
        const selectedItemID = selectedListItemData._id
        switch (sideMenuIndex) {
            case 0:
                return deleteJob(selectedItemID)

            case 1:
                return false

            case 2:
                return false

            case 3:
                return deleteChecksheet(selectedItemID)

            case 4:
                return deleteResource(selectedItemID)

            case 5:
                return deleteCustomer(selectedItemID)

            default:
                break;
        }
    }
    //<--CRUD functions (+submits)

    //-->Toggle dialogs / alerts functions
    function toggleModal(shouldOpen, modalID) { //open/close for all modals
        switch (modalID) {
            case 0: //JobModal
                setshowJobModal(shouldOpen)
                shouldOpen === false && setcurrentModalData({}) //clear saved modal data (sub modal)
                break;
            case 1://JobSubModal
                setshowJobSubModal(shouldOpen)
                break;
            case 2: //none
                break;
            case 3: //ChecksheetModal
                setshowCheckSheetModal(shouldOpen)
                break;
            case 4://ResourceModal
                setshowResourceModal(shouldOpen)
                break;
            case 5://CustomerAddEditModal
                setshowCustomerAddEditModal(shouldOpen)
                break;
            case 6://AddressIssuesModal
                setshowAddressIssuesModal(shouldOpen)
                break;
            case 7://CustomConfirmModal
                setshowCustomConfirmModal(shouldOpen)
                break;
            case 8://CustomConfirmModal
                setshowSendEmailModal(shouldOpen)
                break;
            default:
                break;
        }
        if ((shouldOpen === false) && (modalID !== 1) && (modalInEditMode)) setmodalInEditMode(false)//clear edit mode
    }

    function toggleAlert(show, alertType, message) {
        //Type: {0: red, 1: blue, 2: green}
        setshowCustomAlert(show)
        if (show) {
            setalertProps({ alertType, message })
        }
    }

    function editButtonClicked() {
        setmodalInEditMode(true)
        toggleModal(true, currentMenuOption)
    }
    //<--Toggle dialogs / alerts functions

    async function processAddressedIssues(issueData, isLastIssue) {
        const selectedJobId = selectedListItemData._id
        const unitId = issueData.unitId
        const issueId = issueData._id
        const issueProps = { correction: issueData.correction }
        if (isLastIssue) {
            await addressIssue(selectedJobId, unitId, issueId, issueProps)
            const apiData = await finishRework(selectedJobId)
            if (apiData.success) { //update display data
                getMetaDataDB()
                readDataDB(0, false) //-->update active jobs
                readDataDB(1, true) //-->update rework jobs
                toggleAlert(true, 2, apiData.message)
            } else {
                toggleAlert(true, 0, "Data error occurred")
            }
        } else {
            addressIssue(selectedJobId, unitId, issueId, issueProps)
        }
    }

    async function startFileDownload(url) {//For all file downloads
        settopSpinnerIsLoading(true)
        await downloadFileFrom(url)
        settopSpinnerIsLoading(false)
    }

    async function getMediaForDisplay(issueData) {//On addressIssues modal
        const selectedJobId = selectedListItemData._id
        const unitId = issueData.unitId
        const issueId = issueData._id
        const media = await getIssueMedia(selectedJobId, unitId, issueId)
        return media
    }

    async function dismissRecentRecords() {
        const copyJobMetaData = JSON.parse(JSON.stringify(jobMetaData))
        let alertMessage = ""
        switch (currentMenuOption) {
            case 1: //rework jobs
                copyJobMetaData.newReworks = []
                alertMessage = "New rework jobs dismissed"
                break;
            case 2: //completed jobs
                copyJobMetaData.newCompleted = []
                alertMessage = "Newly completed jobs dismissed"
                break;
            default:
                break;
        }
        const updatedData = { metadata: { ...copyJobMetaData } }
        const apiData = await updateJobMetaData(updatedData)
        if (apiData.success) { //update display data
            getMetaDataDB()
            readDataDB(currentMenuOption, true)
            toggleAlert(true, 2, alertMessage)
        } else {
            toggleAlert(true, 0, "An error occurred while dismissing recent records")
        }
    }


    return (
        <div >
            {/* <TopToolbar
                logout={logout}
                changeActiveDashboard={changeActiveDashboard}
                topSpinnerIsLoading={topSpinnerIsLoading}
                qcDashboardActive={qcDashboardActive} /> */}
            <div className={'dashboard-body'}>
                <SideMenu
                    currentMenuOption={currentMenuOption}
                    selectMenuOption={selectMenuOption}
                    dbDataCounts={dbDataCounts}
                    qcDashboardActive={qcDashboardActive}
                    topSpinnerIsLoading={topSpinnerIsLoading} />
                <OverviewContainer
                    qcDashboardActive={qcDashboardActive}
                    pageDataLoading={pageDataLoading}
                    currentMenuOption={currentMenuOption}
                    setSelectListItem={setSelectListItem}
                    selectedListItemData={selectedListItemData}
                    selectedListData={dataLists[currentMenuOption]}
                    searchTerm={searchTerm}
                    setsearchTerm={setsearchTerm}
                    toggleModal={toggleModal}
                    toggleAlert={toggleAlert}
                    selectMenuOption={selectMenuOption}
                    readDataDB={readDataDB}
                    editButtonClicked={editButtonClicked}
                    startFileDownload={startFileDownload}
                    jobMetaData={jobMetaData}
                    dismissRecentRecords={dismissRecentRecords}
                    logout={logout}
                    changeActiveDashboard={changeActiveDashboard}
                    topSpinnerIsLoading={topSpinnerIsLoading}
                />
            </div>
            {/* onlu show pullup container on jobs screens */}
            {currentMenuOption <= 2 &&
                <PullupDetailsContainer
                    recordDetailsLoading={recordDetailsLoading}
                    currentMenuOption={currentMenuOption}
                    selectedListItemData={selectedListItemData}
                    startFileDownload={startFileDownload} />}
            {/* modals */}
            <JobAddEditModal
                show={showJobModal}
                modalInEditMode={modalInEditMode}
                toggleModal={toggleModal}
                modalOnSubmit={modalOnSubmit}
                customers={customers}
                resourcesLoading={resourcesLoading}
                customersLoading={customersLoading}
                resources={resources}
                selectedListItemData={selectedListItemData}
                toggleAlert={toggleAlert} />
            <JobAddEditSubModal
                show={showJobSubModal}
                showParent={showJobModal}
                modalInEditMode={modalInEditMode}
                toggleModal={toggleModal}
                modalOnSubmit={modalOnSubmit}
                checksheets={checksheets}
                checksheetsLoading={checksheetsLoading}
                selectedListItemData={selectedListItemData}
                toggleAlert={toggleAlert} />
            <CheckSheetAddEditModal
                show={showCheckSheetModal}
                modalInEditMode={modalInEditMode}
                toggleModal={toggleModal}
                modalOnSubmit={modalOnSubmit}
                selectedListItemData={selectedListItemData}
                toggleAlert={toggleAlert} />
            <ResourceAddEditModal
                forFQC={false}
                show={showResourceModal}
                modalInEditMode={modalInEditMode}
                toggleModal={toggleModal}
                modalOnSubmit={modalOnSubmit}
                selectedListItemData={selectedListItemData}
                toggleAlert={toggleAlert} />
            <CustomerAddEditModal
                show={showCustomerAddEditModal}
                modalInEditMode={modalInEditMode}
                toggleModal={toggleModal}
                modalOnSubmit={modalOnSubmit}
                selectedListItemData={selectedListItemData}
                toggleAlert={toggleAlert} />
            <AddressIssuesModal
                show={showAddressIssuesModal}
                toggleModal={toggleModal}
                selectedListItemData={selectedListItemData}
                processAddressedIssues={processAddressedIssues}
                getMediaForDisplay={getMediaForDisplay} />
            {/* dialogs */}
            <CustomConfirmModal
                show={showCustomConfirmModal}
                toggleModal={toggleModal}
                submitDelete={submitDelete} />
            <CustomAlert
                show={showCustomAlert}
                props={alertProps}
                toggle={toggleAlert} />
            <SendEmailModal
                show={showSendEmailModal}
                toggleModal={toggleModal}
                rowData={selectedListItemData}
                toggleAlert={toggleAlert}
                settopSpinnerIsLoading={settopSpinnerIsLoading} />

        </div>
    )
}

export default SQCDashboard